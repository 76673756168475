import {
  Component,
  OnInit,
  ViewChild,
  HostListener,
  OnDestroy,
} from "@angular/core";
import { CPPlaylistCategoriesService } from "../playlist-categories/cp_playlist-categories.service";
import { CPPlaylistsService } from "../services/cp_playlists.service";
import { CommonService } from "../services/common.service";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { UserService } from "../services/user.service";
import { PlayerComponentComponent } from "../player-component/player-component.component";
import { TracksService } from "../services/tracks.service";
import { IMyDpOptions } from "mydatepicker";
import { ScheduleService } from "../services/schedule.service";
import { AlertComponent } from "../alert/alert.component";
import { ConfirmComponent } from "../confirm/confirm.component";
import { Globals } from "../globals";
import { HttpHeaders, HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { KeyValue } from "@angular/common";
import { DatePipe } from "@angular/common";
import { ProfileStatementService } from "../services/profile_statements.service";
import { PlaylistCategoriesService } from "../playlist-categories/playlist-categories.service";
import { PlaylistsService } from "../services/playlists.service";
import { SmartFilterService } from "../services/smart-filter.service";
import { DistributionGroupService } from "../services/distribution_group.service";

@Component({
  selector: "app-client-playlists",
  templateUrl: "./client-playlists.component.html",
  styleUrls: ["./client-playlists.component.scss"],
  providers: [
    CPPlaylistsService,
    CPPlaylistCategoriesService,
    CommonService,
    UserService,
    TracksService,
    ScheduleService,
    ProfileStatementService,
    PlaylistCategoriesService,
    PlaylistsService,
    SmartFilterService,
    DistributionGroupService,
  ],
})
export class ClientPlaylistsComponent implements OnInit, OnDestroy {
  reverseKeyOrder = (
    a: KeyValue<number, string>,
    b: KeyValue<number, string>
  ): number => {
    if (a.key > b.key) {
      return -1;
    } else if (b.key > a.key) {
      return 1;
    } else {
      return 0;
    }
  };
  current_user = null; // information of logged user
  usersList: any = Array(); //List of Clients
  usersListRaw: any = Array();
  Country_code: any = Array();
  /*
   Selected Client information
   {
    id - Client id,
    name - Client name
   }
   */
  checkedUserId = {
    id: null,
    name: "",
  };
  /*
   Client information in edit popup
   {
    id: null,
    name - Client name,
    address - address,
    address_2 - address,
    address_3 - address,
    address_4 - address,
    city - city,
    county - county,
    postcode - post code,
    country - country,
    type - user type. Only 'Client',
    active - client activity
    }
   */
  userFields = {
    id: null,
    name: "",
    address: "",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    /*password: '',
    repeat_password: '',*/
    type: "Client",
    active: 1,
  };
  passdatatochild = {
    user: "",
    site: "",
    channel: "",
  };
  userPopupOpened = false; //Open popup for Client edit
  fileUploadPopupOpened = false;
  PDFPopupOpened = false;

  sitesList: any = null; //List of sites
  sitePopupOpened = false; //Open popup for Sites edit
  /*
  Site information in edit popup
  {
    id - site id,
    name - site name,
    address - address,
    address_2 - address,
    address_3 - address,
    address_4 - address,
    city - city,
    county - county,
    postcode - post code,
    country - country,
    visible - site activity,
    user - client id
  }
   */
  siteFields = {
    id: null,
    name: "",
    address: "",
    address_2: "",
    address_3: "",
    address_4: "",
    city: "",
    county: "",
    postcode: "",
    country: "",
    visible: 1,
    user: null,
  };
  /*
   Selected Site information
   {
    id - Site id,
    name - Site name
   }
   */
  checkedSiteId = {
    id: null,
    name: "",
  };

  chanelsList: any = null; //List of Channels
  chanelPopupOpened = false; //Open popup for channels edit
  /*
  Channel information in edit popup
  {
    id - channel id,
    name - channel name,
    visible - channel activity,
    site - site id,
    user - client id
    sitesList - list of sites for select
  }
   */
  chanelFields = {
    id: null,
    name: "",
    visible: 1,
    site: null,
    user: null,
    sitesList: [],
  };
  /*
  Selected Channel information
  {
    id - Channel id,
    name - Channel name
  }
  */
  checkedChanelId = {
    id: null,
    name: "",
  };
  archivePlaylist:any;
  playlists; //List of playlists
  /*
  Playlilst information in edit popup
  {
    id - playlist id,
    name - playlist name,
    status - playlist activity,
    channel - selected channel for playlist,
    site - selected site for playlist,
    description - playlist description,
    user - Client id,
    sitesList - sites list for select,
    channelsList - channels list for select
    countrycode-country code 
    countrycodeList-list of country with code
  }
   */
  playlistFields = {
    id: null,
    name: "",
    status: 1,
    chanel: null,
    site: null,
    description: "",
    user: null,
    sitesList: [],
    channelsList: [],
    profileStatementID: null,
    profileStatementList: [],
    countrycode: null,
    countrycodeList: [],
  };
  playlistPopupOpened = false; //Open popup for playlists edit
  playlistStatusText = ["LIVE", "EDITING", "SUSPENDED", "DISTRIBUTED"]; //Text for playlist status
  playlistSuspendingText = ["Suspend", "Unsuspend", "Unsuspend", "Unsuspend"]; //Text for playlist suspending
  statusShowFilter = ["All", "LIVE", "EDITING", "SUSPENDED"]; //Filter for playlist status

  isFocused = false;
  searchStr = ""; //Value of search input
  entitySearchStr = "";

  searchedPlaylists: any = []; //Array of search results
  searchPopupOpened = false; //Open search results popup

  selectedFoundation = null; //Foundation playlist id
  /*
   Popups list. Params for opening/closing popups
   */
  popups = {
    restoredPlaylistPopupOpened: false,
    restoredDatesPopupOpened: false,
    restoredChanelsPopupOpened: false,
    restoredTracksPopupOpened: false,
    schedulePopupOpened: false,
    recurrencePopupOpened: false,
    masteringPopupOpened: false,
    createSchedulePopupOpened: false,
    profileStatementOpened: false,
    profileCreatePopupOpened: false,
    profileHistoryopened: false,
    dgOpened: false,
    dgChannelsOpened: false,
    unlockOpened: false,
    schUnlockOpened: false,
    dgSyncOpened: false,
    openOverlaySchedulePopup:false,
    
  };

  /*
   Player information
   {
     isPlaying(string) - player status. List of possible values ['stop', 'play','pause'](default 'stop'),
     trackIndex(integer) - index of playling track in playlist(default null),
     trackId(integer) - playling track id(default null),
     filename(string) - file name of playing track(default null),
     artist(string) - playling track artist(default ''),
     album(string) - playling track album(default ''),
     storage(string) - storage of playling track(default ''),
     tracks - list of tracks in selected playlist
     token(string) - access token for logged user(default '')
     playlistID(number) - playlist id
   }
   */
  playerInfo = {
    isPlaying: "stop",
    trackIndex: null,
    trackId: null,
    filename: null,
    album: "",
    artist: "",
    storage: "",
    tracks: [],
    token: "",
    playlistID: null,
  };
  @ViewChild(PlayerComponentComponent, { static: true })
  player: PlayerComponentComponent; //Component of player
  @ViewChild(AlertComponent, { static: true }) alert: AlertComponent; //Component of alert popup
  @ViewChild("confirm") confirm: ConfirmComponent; //Component of confirm popup
  @ViewChild("confirmAll") confirmAll: ConfirmComponent; //Component of confirm popup
  // @ViewChild(ConfirmComponent, { static: true }) confirm: ConfirmComponent; //Component of confirm popup
  restoredPlaylistTracks: any = []; //Array of restored tracks in playlist
  historyDates: any = []; //Array of dates restore playlist
  restoredChanels: any = []; //Array of channels restored playlists
  mysqlRestoredDate = null; //Date in mysql format
  /**
   Show actions for selected client/site/channel
   {
    number - number of type action,
    id - client/site/channel id
  };
   */
  isShowActions = {
    number: null,
    id: null,
  };
  tabs = {
    selected: 1,
  };
  siteTabs = {
    selected: 1,
  }; //Selected tab
  timeArr = []; //Array of time for start schedule
  finishTimeArr = []; //Array of time for finish schedule
  time = {
    h: 6,
    m: 0,
  }; //Selected time for schedule h-hours, m-minutes
  /*
   Schedule information in edit popup
   {
    userId: - Client id,
    sitesList - sites list for select,
    siteId - selected site id,
    channelsList - channels list for select,
    channelId - selected channel id,
    playlistList - playlists for select,
    playlistId - selected playlist id,
    name - schedule name,
    begin_date - schedule start date,
    finish_date - schedule end date,
    begin_time - schedule start time,
    finish_time - schedule end time,
    bgColor - color for background in schedule table,
    txtColor - color for text in schedule table,
    recurrence_fl - value for schedule recurrence,
    random - value for schedule random,
    mastering_fl - value for schedule mastering,
    clash_distance - value for schedule clash distance,
    duration - schedule duration,
    duration_unit - schedule duration time unit,
    artistsNumber - count of artists in schedule,
    recurrence: {
      radio_value - Value for recurence time unit
      daily: {
        days - count of days
      },
      weekly: {
        week - count of weeks
        days - array of days
      },
      monthly: {
        month - count of monthes
        monthSelection - value for selection type,
        days - count of days,
        number - value for recurrence select,
        type - days of week
      },
      yearly: {
        year - count of years,
        monthes - list of monthes,
        monthSelection - value for selection type,
        number- value for recurrence select,
        type - days of week
      }
    },//Recurrence settings
    mastering: {
      m_switch - value of mastering type,
      days - count of days,
      months - count of monthes,
      months_2 - count of monthes,
      every_week_day - value for recurrence select,
      recurrence_dow - days of week
    }//Mastering settings
   }
   */
  schedule = {
    userId: null,
    sitesList: [],
    siteId: null,
    channelsList: [],
    channelId: null,
    playlistList: [],
    playlistId: null,
    name: "",
    begin_date: {
      year: "",
      month: "",
      day: "",
    },
    finish_date: {
      year: "",
      month: "",
      day: "",
    },
    begin_time: {
      hours: "00",
      minutes: "00",
    },
    //'00:00',
    finish_time: {
      hours: "00",
      minutes: "00",
    },
    //'00:00',
    bgColor: "#FFFFFF",
    txtColor: "#FF0000",
    recurrence_fl: "0",
    random: "0",
    mastering_fl: "0",
    clash_distance: "0",
    duration: "0",
    override_duration: {
      hours: "00",
      minutes: "00",
    },
    duration_unit: "mins",
    artistsNumber: 0,
    recurrence: {
      radio_value: "1",
      daily: {
        days: 1,
      },
      weekly: {
        week: 1,
        days: [],
      },
      monthly: {
        month: 1,
        monthSelection: "1",
        days: [],
        number: 1,
        type: "SU",
      },
      yearly: {
        year: 1,
        monthes: [],
        monthSelection: false,
        number: 1,
        type: "SU",
      },
    },
    mastering: {
      m_switch: "0",
      days: 1,
      months: 1,
      months_2: 1,
      every_week_day: 1,
      recurrence_dow: "MO",
    },
    onDemand: false,
  };
  /*
    Schedule information in small edit popup
    {
      id - schedule id,
      name - schedule name,
      beginDate - schedule start date,
      endDate - schedule end date,
      beginTime - schedule start time,
      endTime - schedule end time
    }
   */
  editSchedule = {
    id: null,
    name: "",
    beginDate: null,
    endDate: null,
    beginTime: null,
    endTime: null,
  };
  /*
    Information in schedule recurrence edit popup
    {
      radio_value - Value for recurence time unit
      daily: {
        days - count of days
      },
      weekly: {
        week - count of weeks
        days - array of days
      },
      monthly: {
        month - count of monthes
        monthSelection - value for selection type,
        days - count of days,
        number - value for recurrence select,
        type - days of week
      },
      yearly: {
        year - count of years,
        monthes - list of monthes,
        monthSelection - value for selection type,
        number- value for recurrence select,
        type - days of week
      }
    }
   */
  recurrence = {
    radio_value: "1",
    daily: {
      days: 1,
    },
    weekly: {
      week: 1,
      days: [],
    },
    monthly: {
      month: 1,
      monthSelection: "1",
      days: [],
      number: 1,
      type: "SU",
    },
    yearly: {
      year: 1,
      monthes: [],
      monthSelection: false,
      number: 1,
      type: "SU",
    },
  };
  /*
    Information in mastering edit popup
    {
      m_switch - value of mastering type,
      days - count of days,
      months - count of monthes,
      months_2 - count of monthes,
      every_week_day - value for recurrence select,
      recurrence_dow - days of week
    }
   */
  mastering = {
    m_switch: "0",
    days: 1,
    months: 1,
    months_2: 1,
    every_week_day: 1,
    recurrence_dow: "MO",
  };
  scheduleValidation = {
    userId: true,
    siteId: true,
    channelId: true,
    playlistId: true,
    name: true,
    begin_date: true,
    finish_date: true,
    begin_time: true,
    finish_time: true,
    bgColor: true,
    txtColor: true,
    recurrence_fl: true,
    random: true,
    mastering: true,
    clash_distance: true,
    duration: true,
  }; //Field for schedule validation
  daysNamesShort = ["SU", "MO", "TU", "WE", "TH", "FR", "SA"]; //Days array
  dayMapping = {
    "SU": "SUN",
    "MO": "MON",
    "TU": "TUE",
    "WE": "WED",
    "TH": "THU",
    "FR": "FRI",
    "SA": "SAT"
  };
  schedulesList: any = []; //Schedules list
  public myDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
    editableDateField: false,
    inline: false,
    height: "20px",
  }; //params for Date picker module
  /**
   Value for confirmation popup
   {
     id(integer) - ID of element for action(default null),
     type(integer) - type of function for action(default 1)
   }
   **/
  confirmValues = {
    id: null,
    type: 0,
  };
  // Checking of inputs focus
  colorPalette = [
    ["#ffffff", "#005555"],
    ["#ffffff", "#1460aa"],
    ["#ffffff", "#d25852"],
    ["#ffffff", "#2e343b"],
    ["#ffffff", "#5a440d"],
    ["#ffffff", "#d43900"],
    ["#ffffff", "#d50000"],
    ["#ff0000", "#baf73c"],
    ["#ff0000", "#0000b5"],
    ["#ff0000", "#39134c"],
    ["#ff0000", "#e8e8e8"],
    ["#ff0000", "#c9f227"],
    ["#ff0000", "#551700"],
    ["#ff0000", "#320a0a"],
    ["#00ff00", "#008000"],
    ["#00ff00", "#34495e"],
    ["#00ff00", "#db0a5b"],
    ["#00ff00", "#050709"],
    ["#00ff00", "#483c0c"],
    ["#00ff00", "#80503d"],
    ["#00ff00", "#cf000f"],
    ["#0000ff", "#3cf73c"],
    ["#0000ff", "#81cfe0"],
    ["#0000ff", "#fc6399"],
    ["#0000ff", "#bdc3c7"],
    ["#0000ff", "#aaa789"],
    ["#0000ff", "#f89406"],
    ["#0000ff", "#e7903c"],
    ["#000000", "#4ecdc4"],
    ["#000000", "#59abe3"],
    ["#000000", "#e000e0"],
    ["#000000", "#696969"],
    ["#000000", "#f27927"],
    ["#000000", "#eb974e"],
    ["#000000", "#e00000"],
    ["#ff00ff", "#152a23"],
    ["#ff00ff", "#004055"],
    ["#ff00ff", "#360036"],
    ["#ff00ff", "#fefefe"],
    ["#ff00ff", "#1d1905"],
    ["#ff00ff", "#552f00"],
    ["#ff00ff", "#5d1212"],
    ["#ffff00", "#007a4b"],
    ["#ffff00", "#527ac2"],
    ["#ffff00", "#9400d3"],
    ["#ffff00", "#1c2833"],
    ["#ffff00", "#b8860b"],
    ["#ffff00", "#aa2e00"],
    ["#ffff00", "#d24d57"],
    ["#00ffff", "#315131"],
    ["#0000ff", "#3455db"],
    ["#0000ff", "#886288"],
    ["#0000ff", "#4f5a65"],
    ["#0000ff", "#483c0c"],
    ["#0000ff", "#66380f"],
    ["#0000ff", "#803224"],
  ]; //Color palette for random background and text color for schedule
  org_begin_hours = [
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
  ];
  org_begin_minutes = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
  ];
  org_finish_hours = [
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
  ];
  org_finish_minutes = [
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",
    "29",
    "30",
    "31",
    "32",
    "33",
    "34",
    "35",
    "36",
    "37",
    "38",
    "39",
    "40",
    "41",
    "42",
    "43",
    "44",
    "45",
    "46",
    "47",
    "48",
    "49",
    "50",
    "51",
    "52",
    "53",
    "54",
    "55",
    "56",
    "57",
    "58",
    "59",
  ];
  begin_hours = this.org_begin_hours.slice();
  begin_minutes = this.org_begin_minutes.slice();
  finish_hours = this.org_finish_hours.slice();
  finish_minutes = this.org_finish_minutes.slice();
  begin_hours_fr = this.org_begin_hours.slice();
  begin_minutes_fr = this.org_begin_minutes.slice();
  finish_hours_fr = this.org_finish_hours.slice();
  finish_minutes_fr = this.org_finish_minutes.slice();

  public siteMenuClick = false;
  public filterDatePickerOptions: IMyDpOptions = {
    dateFormat: "dd/mm/yyyy",
    editableDateField: false,
    inline: false,
    height: "20px",
    openSelectorOnInputClick: true,
    width: "130px",
  };
  public filterFromDate = "";
  public filterToDate = "";
  public sortFilterValue = null;
  public api_logs: any;
  public api_logs_temp: any;
  public isLoad: boolean = false;
  public isLoadHawk: boolean = false;
  public isCancelHawk: boolean = false;
  public isLoadSHawk: boolean = false;
  public isLoadCHawk: boolean = false;
  public isLoader: boolean = false;
  public buildSuccess: boolean = false;
  public buildFailed: boolean = false;
  public progressValue = 0;
  public order = "DESC";
  public orderBy = "created_at";
  public systemStatus = {
    status: "",
    called_in: "",
    next_expected_call: "",
  };
  public isLoadStatus: boolean = false;
  tooltipOptions = {
    placement: "right",
    "show-delay": 800,
    // 'theme':'light'
    //'width':'150'
  };
  tooltipOptionsForDot={
    placement: "right",
    "show-delay": 800,
    'theme':'light'
    //'width':'150'
  }
  changingStatus = false;
  sync_profile_logs = [];
  spl_key = "";
  spl_detail = [];
  spl_inner_key = "";

  countrycodeList = [
    { name: "General Mediterranean", code: "MED" },
    { name: "Nordic/Scandinavian", code: "NSC" },
    { name: "Central Europe including Germany and Benelux", code: "CEU" },
    { name: "USA - Miami (including Latin influences)", code: "USM" },
    { name: "Caribbean", code: "CAR" },
    { name: "Spanish Speaking Latin America", code: "SLA" },
    { name: "Hawaii", code: "HAW" },
    { name: "------------" },
    { name: "Afghanistan", code: "AFG" },
    { name: "Albania", code: "ALB" },
    { name: "Algeria", code: "DZA" },
    { name: "American Samoa", code: "ASM" },
    { name: "AndorrA", code: "AND" },
    { name: "Angola", code: "AGO" },
    { name: "Anguilla", code: "AIA" },
    { name: "Antarctica", code: "ATA" },
    { name: "Antigua and Barbuda", code: "ATG" },
    { name: "Argentina", code: "ARG" },
    { name: "Armenia", code: "ARM" },
    { name: "Aruba", code: "ABW" },
    { name: "Australia", code: "AUS" },
    { name: "Austria", code: "AUT" },
    { name: "Azerbaijan", code: "AZE" },
    { name: "Bahamas(the)", code: "BHS" },
    { name: "Bahrain", code: "BHR" },
    { name: "Bangladesh", code: "BGD" },
    { name: "Barbados", code: "BRB" },
    { name: "Belarus", code: "BLR" },
    { name: "Belgium", code: "BEL" },
    { name: "Belize", code: "BLZ" },
    { name: "Benin", code: "BEN" },
    { name: "Bermuda", code: "BMU" },
    { name: "Bhutan", code: "BTN" },
    { name: "Bolivia", code: "BOL" },
    { name: "Bonaire, Sint Eustatius and Saba", code: "BES" },
    { name: "Bosnia and Herzegovina", code: "BIH" },
    { name: "Botswana", code: "BWA" },
    { name: "Bouvet Island", code: "BVT" },
    { name: "Brazil", code: "BRA" },
    { name: "British Indian Ocean Territory", code: "IOT" },
    { name: "Brunei Darussalam", code: "BRN" },
    { name: "Bulgaria", code: "BGR" },
    { name: "Burkina Faso", code: "BFA" },
    { name: "Burundi", code: "BDI" },
    { name: "Cabo Verde", code: "CPV" },
    { name: "Cambodia", code: "KHM" },
    { name: "Cameroon", code: "CMR" },
    { name: "Canada", code: "CAN" },
    // {"name": "Cape Verde", "code": "CV"},
    { name: "Cayman Islands", code: "CYM" },
    { name: "Central African Republic", code: "CAF" },
    { name: "Chad", code: "TCD" },
    { name: "Chile", code: "CHL" },
    { name: "China", code: "CHN" },
    { name: "Christmas Island", code: "CXR" },
    { name: "Cocos (Keeling) Islands", code: "CCK" },
    { name: "Colombia", code: "COL" },
    { name: "Comoros", code: "COM" },
    { name: "Congo", code: "COG" },
    { name: "Congo, The Democratic Republic of the", code: "COD" },
    { name: "Cook Islands", code: "COK" },
    { name: "Costa Rica", code: "CRI" },
    // {"name": "Cote D"Ivoire", "code": "CI"},
    { name: "Croatia", code: "HRV" },
    { name: "Cuba", code: "CUB" },
    { name: "Curaçao", code: "CUW" },
    { name: "Cyprus", code: "CYP" },
    { name: "Czechia", code: "CZE" },
    { name: "Côte d'Ivoire", code: "CIV" },
    // {"name": "Czech Republic", "code": "CZ"},
    { name: "Denmark", code: "DNK" },
    { name: "Djibouti", code: "DJI" },
    { name: "Dominica", code: "DMA" },
    { name: "Dominican Republic", code: "DOM" },
    { name: "Ecuador", code: "ECU" },
    { name: "Egypt", code: "EGY" },
    { name: "El Salvador", code: "SLV" },
    { name: "Equatorial Guinea", code: "GNQ" },
    { name: "Eritrea", code: "ERI" },
    { name: "Estonia", code: "EST" },
    { name: "Eswatini", code: "SWZ" },
    { name: "Ethiopia", code: "ETH" },
    { name: "Falkland Islands (Malvinas)", code: "FLK" },
    { name: "Faroe Islands", code: "FRO" },
    { name: "Fiji", code: "FJI" },
    { name: "Finland", code: "FIN" },
    { name: "France", code: "FRA" },
    { name: "French Guiana", code: "GUF" },
    { name: "French Polynesia", code: "PYF" },
    { name: "French Southern Territories", code: "ATF" },
    { name: "Gabon", code: "GAB" },
    { name: "Gambia", code: "GMB" },
    { name: "Georgia", code: "GEO" },
    { name: "Germany", code: "DEU" },
    { name: "Ghana", code: "GHA" },
    { name: "Gibraltar", code: "GIB" },
    { name: "Greece", code: "GRC" },
    { name: "Greenland", code: "GRL" },
    { name: "Grenada", code: "GRD" },
    { name: "Guadeloupe", code: "GLP" },
    { name: "Guam", code: "GUM" },
    { name: "Guatemala", code: "GTM" },
    { name: "Guernsey", code: "GGY" },
    { name: "Guinea", code: "GIN" },
    { name: "Guinea-Bissau", code: "GNB" },
    { name: "Guyana", code: "GUY" },
    { name: "Haiti", code: "HTI" },
    { name: "Heard Island and Mcdonald Islands", code: "HMD" },
    { name: "Holy See (Vatican City State)", code: "VAT" },
    { name: "Honduras", code: "HND" },
    { name: "Hong Kong", code: "HKG" },
    { name: "Hungary", code: "HUN" },
    { name: "Iceland", code: "ISL" },
    { name: "India", code: "IND" },
    { name: "Indonesia", code: "IDN" },
    { name: "Iran, Islamic Republic Of", code: "IRN" },
    { name: "Iraq", code: "IRQ" },
    { name: "Ireland", code: "IRL" },
    { name: "Isle of Man", code: "IMN" },
    { name: "Israel", code: "ISR" },
    { name: "Italy", code: "ITA" },
    { name: "Jamaica", code: "JAM" },
    { name: "Japan", code: "JPN" },
    { name: "Jersey", code: "JEY" },
    { name: "Jordan", code: "JOR" },
    { name: "Kazakhstan", code: "KAZ" },
    { name: "Kenya", code: "KEN" },
    { name: "Kiribati", code: "KIR" },
    { name: "Korea, Democratic People'S Republic of", code: "PRK" },
    { name: "Korea, Republic of", code: "KOR" },
    { name: "Kuwait", code: "KWT" },
    { name: "Kyrgyzstan", code: "KGZ" },
    { name: "Lao People'S Democratic Republic", code: "LAO" },
    { name: "Latvia", code: "LVA" },
    { name: "Lebanon", code: "LBN" },
    { name: "Lesotho", code: "LSO" },
    { name: "Liberia", code: "LBR" },
    { name: "Libya", code: "LBY" },
    // {"name": "Libyan Arab Jamahiriya", "code": "LY"},
    { name: "Liechtenstein", code: "LIE" },
    { name: "Lithuania", code: "LTU" },
    { name: "Luxembourg", code: "LUX" },
    { name: "Macao", code: "MAC" },
    // {"name": "Macedonia, The Former Yugoslav Republic of", "code": "MK"},
    { name: "Madagascar", code: "MDG" },
    { name: "Malawi", code: "MWI" },
    { name: "Malaysia", code: "MYS" },
    { name: "Maldives", code: "MDV" },
    { name: "Mali", code: "MLI" },
    { name: "Malta", code: "MLT" },
    { name: "Marshall Islands", code: "MHL" },
    { name: "Martinique", code: "MTQ" },
    { name: "Mauritania", code: "MRT" },
    { name: "Mauritius", code: "MUS" },
    { name: "Mayotte", code: "MYT" },
    { name: "Mexico", code: "MEX" },
    { name: "Micronesia, Federated States of", code: "FSM" },
    { name: "Moldova, Republic of", code: "MDA" },
    { name: "Monaco", code: "MCO" },
    { name: "Mongolia", code: "MNG" },
    { name: "Montenegro", code: "MNE" },
    { name: "Montserrat", code: "MSR" },
    { name: "Morocco", code: "MAR" },
    { name: "Mozambique", code: "MOZ" },
    { name: "Myanmar", code: "MMR" },
    { name: "Namibia", code: "NAM" },
    { name: "Nauru", code: "NRU" },
    { name: "Nepal", code: "NPL" },
    { name: "Netherlands", code: "NLD" },
    // {"name": "Netherlands Antilles", "code": "AN"},
    { name: "New Caledonia", code: "NCL" },
    { name: "New Zealand", code: "NZL" },
    { name: "Nicaragua", code: "NIC" },
    { name: "Niger", code: "NER" },
    { name: "Nigeria", code: "NGA" },
    { name: "Niue", code: "NIU" },
    { name: "Norfolk Island", code: "NFK" },
    { name: "Northern Mariana Islands", code: "MNP" },
    { name: "Norway", code: "NOR" },
    { name: "Oman", code: "OMN" },
    { name: "Pakistan", code: "PAK" },
    { name: "Palau", code: "PLW" },
    { name: "Palestinian Territory, Occupied", code: "PSE" },
    { name: "Panama", code: "PAN" },
    { name: "Papua New Guinea", code: "PNG" },
    { name: "Paraguay", code: "PRY" },
    { name: "Peru", code: "PER" },
    { name: "Philippines", code: "PHL" },
    { name: "Pitcairn", code: "PCN" },
    { name: "Poland", code: "POL" },
    { name: "Portugal", code: "PRT" },
    { name: "Puerto Rico", code: "PRI" },
    { name: "Qatar", code: "QAT" },
    // {"name": "Reunion", "code": "RE"},
    { name: "Republic of North Macedonia", code: "MKD" },
    { name: "Romania", code: "ROU" },
    { name: "Russian Federation", code: "RUs" },
    { name: "RWANDA", code: "RWA" },
    { name: "Réunion", code: "REU" },
    { name: "Saint Barthélemy", code: "BLM" },
    { name: "Saint Helena , Ascension and Tristan da Cunha", code: "SHN" },
    { name: "Saint Kitts and Nevis", code: "KNA" },
    { name: "Saint Lucia", code: "LCA" },
    { name: "Saint Martin (French part)", code: "MAF" },
    { name: "Saint Pierre and Miquelon", code: "SPM" },
    { name: "Saint Vincent and the Grenadines", code: "VCT" },
    { name: "Samoa", code: "WSM" },
    { name: "San Marino", code: "SMR" },
    { name: "Sao Tome and Principe", code: "STP" },
    { name: "Saudi Arabia", code: "SAU" },
    { name: "Senegal", code: "SEN" },
    { name: "Serbia", code: "SRB" },
    { name: "Seychelles", code: "SYC" },
    { name: "Sierra Leone", code: "SLC" },
    { name: "Singapore", code: "SGP" },
    { name: "Sint Maarten (Dutch part)", code: "SXM" },
    { name: "Slovakia", code: "SVK" },
    { name: "Slovenia", code: "SVN" },
    { name: "Solomon Islands", code: "SLB" },
    { name: "Somalia", code: "SOM" },
    { name: "South Africa", code: "ZAF" },
    { name: "South Georgia and the South Sandwich Islands", code: "SGS" },
    { name: "Spain", code: "ESP" },
    { name: "Sri Lanka", code: "LKA" },
    { name: "Sudan", code: "SDN" },
    { name: "Suriname", code: "SUR" },
    { name: "Svalbard and Jan Mayen", code: "SJM" },
    // {"name": "Swaziland", "code": "SZ"},
    { name: "Sweden", code: "SWE" },
    { name: "Switzerland", code: "CHE" },
    { name: "Syrian Arab Republic", code: "SYR" },
    { name: "Taiwan, Province of China", code: "TWN" },
    { name: "Tajikistan", code: "TJK" },
    { name: "Tanzania, United Republic of", code: "TZA" },
    { name: "Thailand", code: "THA" },
    { name: "Timor-Leste", code: "TLS" },
    { name: "Togo", code: "TGO" },
    { name: "Tokelau", code: "TKL" },
    { name: "Tonga", code: "TON" },
    { name: "Trinidad and Tobago", code: "TTO" },
    { name: "Tunisia", code: "TUN" },
    { name: "Turkey", code: "TUR" },
    { name: "Turkmenistan", code: "TKM" },
    { name: "Turks and Caicos Islands", code: "TCA" },
    { name: "Tuvalu", code: "TUV" },
    { name: "Uganda", code: "UGA" },
    { name: "Ukraine", code: "UKR" },
    { name: "United Arab Emirates", code: "ARE" },
    {
      name: "United Kingdom of Great Britain and Northern Ireland ",
      code: "GBR",
    },
    { name: "United States", code: "US" },
    { name: "United States Minor Outlying Islands", code: "UMI" },
    { name: "Uruguay", code: "URY" },
    { name: "Uzbekistan", code: "UZB" },
    { name: "Vanuatu", code: "VUT" },
    { name: "Venezuela", code: "VEN" },
    { name: "Viet Nam", code: "VNM" },
    { name: "Virgin Islands, British", code: "VGB" },
    { name: "Virgin Islands, U.S.", code: "VIR" },
    { name: "Wallis and Futuna", code: "WLF" },
    { name: "Western Sahara", code: "ESH" },
    { name: "Yemen", code: "YEM" },
    { name: "Zambia", code: "ZMB" },
    { name: "Zimbabwe", code: "ZWE" },
    { name: "Åland Islands", code: "ALA" },
  ];

  public year_fr = this.getAllNumbersBetween(
    new Date().getFullYear(),
    new Date().getFullYear() + 30
  );
  public month_fr = this.getAllNumbersBetween(1, 12);
  // public day_fr = this.getAllNumbersBetween(1,31);
  public frequency_fr = [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
  ].slice();
  getAllNumbersBetween(x, y) {
    var numbers = [];
    for (var i = x; i <= y; i++) {
      var j = (i < 10 ? "0" : "") + i;
      numbers.push(j);
    }
    return numbers;
  }

  public day_fr(year, month) {
    let dayCount = 31;
    if (year != "YYYY" && month != "MM") {
      dayCount = this.getDaysInMonth(year, month);
    }
    return Array(dayCount)
      .fill(0)
      .map((i, idx) => {
        idx = idx + 1;
        return idx < 10 ? "0" + idx : idx;
      });
  }

  public getDaysInMonth(year: any, month: any) {
    return 32 - new Date(year, month - 1, 32).getDate();
  }

  profileStatements = [
    {
      id: null,
      fp_id: null,
      cover: "1",
      ratio: "",
      note: "",
      smartFiltersPS: [
        {
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ["", ""],
          type: "str",
          isHidden: false,
        },
      ],
      availableTracks: 0,
      futureCoverage: 0,
      trackIDs: [],
      length_sec: 0,
    },
  ];
  coverOptions = [
    1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10,
  ];
  ratioOptions = [
    5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95,
    100,
  ];
  profileStatementFields = {
    id: null,
    name: "",
    visible: 1,
  };
  playlist_duration_ps = 0;
  percentage_to_update = 0;
  psSelects = [
    {
      catId: null,
      subcatId: null,
      fp_id: null,
      fp_arr: [],
      dropOpened: false,
      title: "Select Foundation playlist...",
    },
  ];
  fpCategories = [];
  profileStatementList: any = [];
  defaultSmartFiltersPS: any = [];
  mcTagsList = [];
  dgs = [];
  dgFormFields = {
    dg_id: null,
    profile_id: null,
    master_id: null,
  };
  dgChannelsFormFields = {
    dg_id: null,
    profile_id: null,
    channel_ids: [],
  };
  dgCopyResults = [];
  dgChannelsCopyResults = [];
  buildResults = [];
  hawkResults = [];
  unlockProfileID = 0;
  unlockScheduleID = 0;
  dgMasterID = 0;
  auto_update_logs = [];
  aul_key = "";
  hasConflicts = false;
  dropdownSettings = {
    singleSelection: false,
    idField: "item_id",
    textField: "item_text",
    selectAllText: "Select All",
    unSelectAllText: "UnSelect All",
    itemsShowLimit: 3,
    allowSearchFilter: true,
  };
  currentIndex = 0;
  playlistStatusheaderFilter = this.statusShowFilter[this.currentIndex];
  isChecked: boolean = false;
  remove_Playlist=false;
  seletedProfileStatementName="";

  scheduleValidationoverlay = {
    name: true,
    begin_date: true,
    finish_date: true,
    begin_time: true,
    finish_time: true,
    bgColor: true,
    txtColor: true,
    recurrence_fl: true,
    random: true,
    mastering: true,
    clash_distance: true,
    duration: true
  };//Field for schedule validation

  // CONSTRUCTOR START
  constructor(
    private usersService: UserService,
    private commonService: CommonService,
    private chanelsService: CPPlaylistCategoriesService,
    private playlistServ: CPPlaylistsService,
    private router: Router,
    private route: ActivatedRoute,
    private tracksService: TracksService,
    private scheduleService: ScheduleService,
    private globals: Globals,
    private http: HttpClient,
    private datePipe: DatePipe,
    private profile_statementService: ProfileStatementService,
    private fp_categoriesService: PlaylistCategoriesService,
    private fpService: PlaylistsService,
    private smartService: SmartFilterService,
    private dgService: DistributionGroupService
  ) {}

  ngOnInit() {
    this.commonService.getCurrentLoggedUser().subscribe((current_user) => {
      this.current_user = current_user;
      if (localStorage.getItem("cp_es_keyword") != null) {
        this.entitySearchStr = localStorage.getItem("cp_es_keyword");
        this.entitySearch(window.event);
      }
      this.updateClientsList();
      this.setCheckedElements();
      this.setTime();
      this.setRecurrenceDaysArr();
      this.setRecurrenceMonthlyDaysArr();
      this.setRecurrenceMonthesArr();
    });

    this.smartService.getFilters("").subscribe((filters) => {
      this.defaultSmartFiltersPS = filters;
    });
    this.getMCTags();
    this.getDgs();
  }

  ngOnDestroy() {
    if (this.playerInfo.isPlaying == "play") {
      this.player.pause();
    }
    if (this.polling) {
      clearInterval(this.polling);
    }
  }

  getMCTags() {
    this.tracksService.getMCTags().subscribe((tags: any) => {
      if (tags) {
        for (let key in tags) {
          if (tags.hasOwnProperty(key)) {
            this.mcTagsList.push({
              item_id: tags[key].name,
              item_text: tags[key].name,
            });
          }
        }
      }
    });
  }

  /**
   Get list of Clients
   */
  updateClientsList() {
    this.chanelsService.getClientsList().subscribe((clients) => {
      this.usersList = clients;
      this.usersListRaw = clients;

      if (this.checkedUserId.id && this.checkedUserId.name == "") {
        let user = this.usersList.find((x) => x.id == this.checkedUserId.id);
        this.checkedUserId.name = user.first_name;
      }
      this.isLoad = false;
    });
  }

  /**
   Set checked client,site,channel after back from edit playlist page
   */
  setCheckedElements() {
    this.route.queryParams.subscribe((params) => {
      if (params["uid"] != "" && params["uid"] != null) {
        this.checkedUserId.id = params["uid"];
        // this.scrollToElementUserList(this.checkedUserId.id);
        this.getSites(this.checkedUserId.id, "", 0);
      } else {
        this.checkedUserId.id = null;
      }
      if (params["pcat"] != "" && params["pcat"] != null) {
        this.checkedSiteId.id = params["pcat"];
        this.showChanels("", this.checkedSiteId.id, "", 0);
      } else {
        this.checkedSiteId.id = null;
        this.sitesList = null;
      }
      if (params["cat"] != "" && params["cat"] != null) {
        this.checkedChanelId.id = params["cat"];
        this.showPlaylists("", this.checkedChanelId.id, "", 0);
      } else {
        this.checkedChanelId.id = null;
        this.playlists = null;
      }
      if (params["tab"] == "2") {
        this.tabs.selected = 2;
      }
    });
  }

  /**
   Actions after breadcrumbs click
   * @param num - number of back action
   * @returns {boolean}
   */
  resetCheckedElements(num) {
    if (num < 4) {
      this.checkedChanelId.id = null;
    }
    if (num < 3) {
      this.checkedSiteId.id = null;
    }
    if (num < 2) {
      this.checkedUserId.id = null;
    }
    return false;
  }

  /**
   Open edit Client popup
   * @param e - event
   * @param uid - user id
   * @returns {boolean}
   */
  openUserPopup(e, uid) {
    return;
    // return;  Disable create client option
    // this.popupClose(e);
    // if (uid != null) {
    //   this.chanelsService.getClientById(uid).subscribe((userInfo: any) => {
    //     this.userFields.id = userInfo.id;
    //     this.userFields.name = userInfo.first_name;
    //     this.userFields.address = userInfo.address;
    //     this.userFields.address_2 = userInfo.address_2;
    //     this.userFields.address_3 = userInfo.address_3;
    //     this.userFields.address_4 = userInfo.address_4;
    //     this.userFields.city = userInfo.city;
    //     this.userFields.county = userInfo.county;
    //     this.userFields.postcode = userInfo.postcode;
    //     this.userFields.country = userInfo.country;
    //     this.userFields.type = userInfo.type;
    //     this.userFields.active = userInfo.active;
    //   });
    // } else {
    //   this.userFields.id = null;
    // }
    // this.focusInput();
    // return false;
  }
  site_id: any;
  channel_id: any;
  documentList: any;
  download: any;
  type: any;
  openFileUploadPopup(sid) {
    this.fileUploadPopupOpened = !this.fileUploadPopupOpened;
    this.type = "site";
    this.site_id = sid;
    this.playlistServ.getClientDocuments(sid).subscribe((documents) => {
      this.documentList = documents;
    });
  }

  openFileUploadPopupChannel(cid) {
    this.fileUploadPopupOpened = !this.fileUploadPopupOpened;
    this.type = "channel";
    this.channel_id = cid;
    this.playlistServ.getClientDocumentsChannel(cid).subscribe((documents) => {
      this.documentList = documents;
    });
  }

  currentEntityType() {
    return this.type;
  }

  PassSiteId() {
    return this.site_id;
  }
  PassChannelId() {
    return this.channel_id;
  }

  document: any;
  path: any;
  documentName: any;
  temp: any;
  storegePath: string = "storage/document/";
  originalpath: any;
  file: any;

  // openPDFPopup(e, id) {
  //   this.popupClose(e);
  //   this.playlistServ.getClientDocumentView(id).subscribe(view => {
  //     this.temp = view;
  //     this.originalpath = this.storegePath + this.temp.path + this.temp.document_name;
  //     this.document = this.originalpath;
  //   });
  //   this.PDFPopupOpened = !this.PDFPopupOpened;

  // }
  // ClosdePDFPopup(e) {
  //   this.PDFPopupOpened = false;
  // }

  DownloadPDF(e, id) {
    this.playlistServ.getClientDocumentView(id).subscribe((view) => {
      this.temp = view;
      this.originalpath =
        this.storegePath + this.temp.path + this.temp.document_name;
      let headers = new HttpHeaders();
      if ("pdf" == this.temp.document_type) {
        // if pdf
        headers = headers.set("Accept", "application/pdf");
        return this.http
          .get(environment.dev_url_prefix + "/" + this.originalpath, {
            headers: headers,
            responseType: "blob",
          })
          .subscribe((response) => {
            const blob = new Blob([response], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          });
      }
      // docx
      if ("docx" == this.temp.document_type) {
        headers = headers.set("Accept", "application/msword");
        return this.http
          .get(environment.dev_url_prefix + "/" + this.originalpath, {
            headers: headers,
            responseType: "blob",
          })
          .subscribe((response) => {
            const blob = new Blob([response], { type: "application/msword" });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          });
      }
      // csv
      if ("csv" == this.temp.document_type) {
        headers = headers.set("Accept", "text/csv");
        return this.http
          .get(environment.dev_url_prefix + "/" + this.originalpath, {
            headers: headers,
            responseType: "blob",
          })
          .subscribe((response) => {
            const blob = new Blob([response], { type: "text/csv" });
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = this.temp.document_name;
            anchor.href = url;
            anchor.click();
          });
      }
      // xls
      if ("bin" == this.temp.document_type) {
        headers = headers.set("Accept", "application/vnd.ms-excel");
        return this.http
          .get(environment.dev_url_prefix + "/" + this.originalpath, {
            headers: headers,
            responseType: "blob",
          })
          .subscribe((response) => {
            const blob = new Blob([response], {
              type: "application/vnd.ms-excel",
            });
            const url = window.URL.createObjectURL(blob);
            var anchor = document.createElement("a");
            anchor.download = this.temp.document_name;
            anchor.href = url;
            anchor.click();
          });
      }
    });
  }

  deleteDocument(sid) {
    this.playlistServ.deleteDocument(sid).subscribe((sch: any) => {
      if (sch.error != undefined) {
        this.alert.alertOpen(sch.error, 1);
      } else {
        this.alert.alertOpen("Docuement removed", 0);
        this.openFileUploadPopup(this.site_id);
      }
    });
    return false;
  }

  /**
   Close all popups
   * @param e - event
   * @returns {boolean}
   */
  popupClose(e) {
    this.userPopupOpened = false;
    this.fileUploadPopupOpened = false;
    this.PDFPopupOpened = false;
    this.sitePopupOpened = false;
    this.chanelPopupOpened = false;
    this.playlistPopupOpened = false;
    this.searchPopupOpened = false;
    this.popups.restoredPlaylistPopupOpened = false;
    this.popups.restoredDatesPopupOpened = false;
    this.popups.restoredChanelsPopupOpened = false;
    this.popups.restoredTracksPopupOpened = false;
    this.popups.schedulePopupOpened = false;
    this.popups.recurrencePopupOpened = false;
    this.popups.masteringPopupOpened = false;
    this.popups.createSchedulePopupOpened = false;
    this.popups.openOverlaySchedulePopup = false;
    this.popups.profileStatementOpened = false;
    this.popups.profileCreatePopupOpened = false;
    this.popups.profileHistoryopened = false;
    this.popups.dgOpened = false;
    this.popups.dgChannelsOpened = false;
    this.popups.unlockOpened = false;
    this.popups.schUnlockOpened = false;
    this.popups.dgSyncOpened = false;
    this.blurInput();
    this.playlistandsch=false;
    return false;
  }

  /**
   Update Client information
   * @param e - event
   * @param {NgForm} form - form
   */
  formUserUpdate(e, form: NgForm) {
    e.preventDefault();
    var str = Object.keys(form.value)
      .map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == "null" || val == null) {
          val = "";
        }
        return encodeURIComponent(key) + "=" + val;
      })
      .join("&");
    if (this.userFields.name != "") {
      if (this.userFields.id == null) {
        this.chanelsService.createNewClient(str).subscribe((client: any) => {
          if (client.error != undefined) {
            this.alert.alertOpen(client.error, 1);
          } else {
            this.updateClientsList();
            this.alert.alertOpen("New customer created", 0);
            this.popupClose(e);
          }
        });
      } else {
        this.chanelsService
          .editClient(this.userFields.id, str)
          .subscribe((client) => {
            this.updateClientsList();
            this.alert.alertOpen("Client Info Updated", 0);
            this.popupClose(e);
          });
      }
    } else {
      this.alert.alertOpen(
        "Please fill First name, Last name and Email fields",
        1
      );
    }
  }

  /**
   Delete Client
   * @param uid - Client id
   */
  deleteUser(uid) {
    this.chanelsService.deleteClient(uid).subscribe((user) => {
      this.updateClientsList();
      this.checkedUserId.id = null;
      this.checkedSiteId.id = null;
      this.checkedChanelId.id = null;
      let currentUser = this.usersList.find((x) => x.id == uid);
      this.alert.alertOpen(
        "Client " + currentUser.company_name + " deleted successfully!",
        0
      );
    });
  }

  /**
   Get List of sites
   * @param uid - user id
   * @param name - user name
   * @param num - number of action
   */
  getSites(uid, name, num) {
    this.checkedSiteId.id = null;
    this.checkedChanelId.id = null;
    this.isShowActions.number = 1;
    this.clearChanelsData();
    this.clearPlaylistsData();
    if (num == 1 && this.checkedUserId.id == uid) {
      this.checkedUserId.id = null;
      this.checkedUserId.name = "";
    } else {
      this.checkedUserId.id = uid;
      this.passdatatochild.user = uid;
      this.checkedUserId.name = name;
      this.chanelsService.getSitesByUser(uid).subscribe((sites: any) => {
        if (this.entitySearchStr != "") {
          let searchString = this.entitySearchStr.toLowerCase();
          let filtered = [];
          // If search string is entity ID
          if (!isNaN(parseFloat(this.entitySearchStr))) {
            filtered = sites.filter(function search(row) {
              return Object.keys(row).some((key) => {
                if (key == "entity_id" && typeof row[key] === "number") {
                  return row[key] === parseFloat(searchString);
                } else if (row[key] && typeof row[key] === "object") {
                  return search(row[key]);
                }
                return false;
              });
            });
            // Filter channels exactly with entity ID
            for (let row of filtered) {
              const element = row.children;
              let filt = element.filter(
                (x) => x.entity_id === parseFloat(searchString)
              );
              if (filt.length > 0) {
                row.children = filt;
              }
            }
          } else {
            filtered = sites.filter(function search(row) {
              return Object.keys(row).some((key) => {
                if (key == "name" && typeof row[key] === "string") {
                  return row[key].toLowerCase().indexOf(searchString) >= 0;
                } else if (row[key] && typeof row[key] === "object") {
                  return search(row[key]);
                }
                return false;
              });
            });
          }
          this.sitesList = filtered;
        } else {
          this.sitesList = sites;
        }

        let site = this.sitesList.find((x) => x.id == this.checkedSiteId.id);
        if (this.checkedSiteId.id && this.checkedSiteId.name == "") {
          this.checkedSiteId.name = site.name;
        }

        if (this.checkedChanelId.id && this.checkedChanelId.name == "") {
          let channel = site.children.find(
            (x) => x.id == this.checkedChanelId.id
          );
          this.checkedChanelId.name = channel.name;
        }

        // console.log(sitesList.sort((a, b) => (a.name > b.name) ? 1 : -1));
        for (let row of this.sitesList) {
          const element = row;
          let parent_entity = this.getParentEntityIDForSite(element.client_id);
          if (parent_entity) {
            element.parent_entity_id = parent_entity.entity_id;
            element.parent_entity_name = parent_entity.entity_name;
          }
          element.total_tracks = element.children.reduce(function (prev, cur) {
            return prev + cur.countDetails.clip_count;
          }, 0);
        }
      });
    }
  }

  /**
   Open Sites edit popup
   * @param e - event
   * @param sid - site id
   * @returns {boolean}
   */
  openSitePopup(e, sid) {
    return; // Disable create site option
    // this.popupClose(e);
    // this.siteFields.id = sid;
    // if (sid == null) {
    //   if (this.checkedUserId.id != null)
    //     this.siteFields.user = this.checkedUserId.id;
    //   else this.siteFields.user = null;
    // } else {
    //   this.chanelsService.getPlaylistsCategories(sid).subscribe((site: any) => {
    //     this.siteFields.name = site.name;
    //     this.siteFields.user = site.user_id;
    //     this.siteFields.address = site.address_1;
    //     this.siteFields.address_2 = site.address_2;
    //     this.siteFields.address_3 = site.address_3;
    //     this.siteFields.address_4 = site.address_4;
    //     this.siteFields.city = site.city;
    //     this.siteFields.county = site.county;
    //     this.siteFields.postcode = site.postcode;
    //     this.siteFields.country = site.country;
    //   });
    // }
    // this.focusInput();
    // this.sitePopupOpened = !this.sitePopupOpened;
    // return false;
  }

  /**
   Site information update
   * @param e - event
   * @param {NgForm} form - form
   */
  formSiteUpdate(e, form: NgForm) {
    var str = Object.keys(form.value)
      .map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == "null" || val == null) {
          val = "";
        }
        return encodeURIComponent(key) + "=" + val;
      })
      .join("&");
    if (this.siteFields.user != null) {
      if (this.siteFields.id == null) {
        this.chanelsService.createCategory(str, "").subscribe((site) => {
          this.getSites(this.checkedUserId.id, "", 0);
          this.alert.alertOpen("Site added", 0);
        });
      } else {
        this.chanelsService
          .updatePlaylistCategory(this.siteFields.id, str)
          .subscribe((site) => {
            this.getSites(this.checkedUserId.id, "", 0);
            this.alert.alertOpen("Site updated", 0);
          });
      }
      this.popupClose(e);
    } else {
      this.alert.alertOpen("Please select Client", 1);
    }
  }

  /**
   Delete site/channel
   * @param id - site/channel id
   * @param num - type of action 1-delete site, 2-delete channel
   */
  deleteSite(id, num) {
    this.chanelsService.deleteCategory(id).subscribe((siteDel) => {
      this.getSites(this.checkedUserId.id, "", 0);
      if (num == 1) this.alert.alertOpen("Site deleted", 0);
      else if (num == 2) this.alert.alertOpen("Channel deleted", 0);
    });
  }

  /**
   Show channels from selected site
   * @param e - event
   * @param sid - site id
   * @param name - site name
   * @param num - number of action type
   */
  showChanels(e, sid, name, num) {
    this.isShowActions.number = 2;
    this.checkedChanelId.id = null;
    this.clearPlaylistsData();
    if (num == 1 && this.checkedSiteId.id == sid) {
      this.checkedSiteId.id = null;
      this.checkedSiteId.name = "";
    } else {
      this.checkedSiteId.id = sid;
      this.passdatatochild.site = sid;
      this.checkedSiteId.name = name;
    }
    if (this.checkedUserId.id == null) {
      let site = this.entitySearchResult.find(
        (x) => x.id == this.checkedSiteId.id
      );
      let user = this.usersList.find((x) => x.id == site.client_id);
      this.checkedUserId.id = user.id;
      this.checkedUserId.name = user.first_name;
    }
    this.resetLogForm();
    this.getSystemStatusDB();
    this.remove_Playlist=false;
  }

  getSystemStatusDB() {
    if (this.checkedSiteId.id == "" || this.checkedSiteId.id == null)
      return false;

    this.systemStatus.status = "";
    this.systemStatus.called_in = "";
    this.systemStatus.next_expected_call = "";

    this.playlistServ
      .getSystemStatusDB(this.checkedSiteId.id)
      .subscribe((res: any) => {
        if (res["status"] == 200 && res["result"]) {
          this.systemStatus.status = res["result"].system_status;
          this.systemStatus.called_in = res["result"].called_in;
          this.systemStatus.next_expected_call =
            res["result"].next_expected_call;
        } else {
          //  this.alert.alertOpen(res['error'],1)
        }
      });
  }

  getSystemStatus() {
    if (this.checkedSiteId.id == "" || this.checkedSiteId.id == null)
      return false;
    this.isLoadStatus = true;
    this.playlistServ
      .getSystemStatus(this.checkedSiteId.id, this.checkedChanelId.id)
      .subscribe((res: any) => {
        this.isLoadStatus = false;
        if (res["status"] == 200 && res["result"]) {
          this.systemStatus.status = res["result"].status;
          this.systemStatus.called_in = res["result"].called_in;
          this.systemStatus.next_expected_call =
            res["result"].next_expected_call;
        } else {
          this.alert.alertOpen(res["error"], 1);
        }
      });
  }

  /**
   Open popup for channels edit
   * @param e - event
   * @param cid - channel id
   * @returns {boolean}
   */
  openChanelsPopup(e, cid) {
    return; // Disable create channel option
    // this.popupClose(e);
    // this.chanelFields.id = cid;
    // if (this.chanelFields.id == null) {
    //   if (this.checkedUserId.id != null) {
    //     this.chanelFields.user = this.checkedUserId.id;
    //     this.updateSites(this.chanelFields.user, 1);
    //   } else {
    //     this.chanelFields.user = null;
    //     this.chanelFields.sitesList = [];
    //   }
    //   if (this.checkedSiteId.id != null)
    //     this.chanelFields.site = this.checkedSiteId.id;
    //   else this.chanelFields.site = null;
    // } else {
    //   this.chanelsService
    //     .getPlaylistsCategories(this.chanelFields.id)
    //     .subscribe((chanel: any) => {
    //       this.chanelFields.name = chanel.name;
    //       this.chanelFields.site = chanel.parent_id;
    //       this.chanelFields.user = chanel.client_id;
    //       this.updateSites(this.chanelFields.user, 1);
    //     });
    // }
    // this.focusInput();
    // this.chanelPopupOpened = !this.chanelPopupOpened;
    // return false;
  }

  /**
   Actions when changed user in select
   * @param e - event
   * @param num - type of action in popup
   */
  userChange(e, num) {
    if (e.target.value == null) {
      if (num == 1) {
        this.chanelFields.sitesList = [];
      } else if (num == 2) {
        this.playlistFields.sitesList = [];
      } else if (num == 3) {
        this.schedule.sitesList = [];
      }
    } else {
      this.updateSites(e.target.value, num);
    }
    this.schedule.siteId = null;
    this.schedule.channelId = null;
    this.schedule.playlistId = null;
  }

  /**
   Get sites list
   * @param uid - user id
   * @param num - number of edit popup
   */
  updateSites(uid, num) {
    this.chanelsService.getSitesByUser(uid).subscribe((sites: any) => {
      if (num == 1) {
        this.chanelFields.sitesList = sites;
      } else if (num == 2) {
        this.playlistFields.sitesList = sites;
      } else if (num == 3) {
        // this.schedule.siteId=null;
        this.schedule.sitesList = sites;
      }
    });
  }

  /**
   Update channel
   * @param e - event
   * @param {NgForm} form - form
   */
  formChanelUpdate(e, form: NgForm) {
    var parentID = form.value.parent_id;
    if (this.chanelFields.id == null) {
      delete form.value.parent_id;
    }
    var str = Object.keys(form.value)
      .map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == "null" || val == null) {
          val = "";
        }
        return encodeURIComponent(key) + "=" + val;
      })
      .join("&");
    if (this.chanelFields.user != null && this.chanelFields.site != null) {
      if (this.chanelFields.id == null) {
        this.chanelsService
          .createCategory(str, parentID)
          .subscribe((chanel) => {
            this.getSites(this.checkedUserId.id, "", 0);
            this.alert.alertOpen("Channel added", 0);
          });
      } else {
        this.chanelsService
          .updatePlaylistCategory(this.chanelFields.id, str)
          .subscribe((chanel) => {
            this.getSites(this.checkedUserId.id, "", 0);
            this.alert.alertOpen("Channel updated", 0);
          });
      }
      this.popupClose(e);
    } else {
      this.alert.alertOpen("Please select Client and Site", 1);
    }
  }

  /**
   Show table of playlists
   * @param e - event
   * @param cid - channel id
   * @param name - channel name
   * @param num - type of action
   */
  
  showPlaylists(e, cid, name, num) {
    this.isShowActions.number = 3;
    this.remove_Playlist=true;
    if (num == 1 && this.checkedChanelId.id == cid) {
      this.checkedChanelId.id = null;
      this.checkedChanelId.name = "";
      this.playlists = [];
      this.schedulesList = [];
    } else {
      this.playlists = [];
      this.schedulesList = [];
      this.passdatatochild.channel = cid;
      this.checkedChanelId.id = cid;
      this.checkedChanelId.name = name;
      this.updatePlaylists(0);
      this.updateSchedules(cid);
      this.updateProfileStatementList(cid);
      this.archivePlaylists(cid);
    }
    this.resetLogForm();
  }

  /**
   Open popup for edit playlist
   * @param e - event
   * @param pid - playlist id
   * @returns {boolean}
   */
  openPlaylistPopup(e, pid) {
    this.playlistFields.countrycode = this.countrycodeList.find(
      (o) => o.code == "GBR"
    )?.code; // by default selected
    this.popupClose(e);

    this.playlistFields.id = pid;
    this.searchPopupOpened = false;
    if (this.playlistFields.id == null) {
      if (this.checkedUserId.id != null) {
        this.playlistFields.user = this.checkedUserId.id;
        this.updateSites(this.playlistFields.user, 2);
      } else {
        this.playlistFields.user = null;
        this.playlistFields.sitesList = [];
      }
      if (this.checkedSiteId.id != null) {
        this.playlistFields.site = this.checkedSiteId.id;
        this.updateChanels(this.playlistFields.site, 1);
      } else {
        this.playlistFields.site = null;
        this.playlistFields.channelsList = [];
      }

      if (this.checkedChanelId.id != null) {
        this.playlistFields.chanel = this.checkedChanelId.id;
        this.updateProfileStatements(this.playlistFields.chanel);
      } else this.playlistFields.chanel = null;
    } else {
      this.playlistServ.getPlaylistById(pid).subscribe((playlist: any) => {
        this.playlistFields.id = playlist.id;
        this.playlistFields.chanel = playlist.cp_category_id;
        this.playlistFields.profileStatementID = playlist.psr_id;
        this.updateProfileStatements(this.playlistFields.chanel);
        this.playlistFields.user = playlist.client_id;
        this.updateSites(playlist.client_id, 2);
        this.chanelsService
          .getPlaylistsCategories(this.playlistFields.chanel)
          .subscribe((chanel: any) => {
            this.playlistFields.site = chanel.parent_id;
            this.updateChanels(this.playlistFields.site, 1);
          });
        this.playlistFields.name = playlist.name;
        this.playlistFields.countrycode = this.countrycodeList.find(
          (o) => o.code == playlist.country_code
        )?.code;

        this.playlistFields.description = playlist.description;
      });
    }
    this.focusInput();
    this.playlistPopupOpened = !this.playlistPopupOpened;
    return false;
  }

  /**
   Actions when changed site in select
   * @param e - event
   * @param num - type of action
   */
  categoryChange(e, num) {
    if (e.target.value == null || e.target.value == "null") {
      if (num == 1) {
        this.playlistFields.channelsList = [];
        this.playlistFields.chanel = null;
      } else {
        this.schedule.channelsList = [];
        this.schedule.channelId = null;
      }
    } else {
      this.updateChanels(e.target.value, num);
    }
    this.schedule.channelId = null;
    this.schedule.playlistId = null;
  }

  /**
   Get channels list
   * @param sid - site id
   * @param num - type of action
   */
  updateChanels(sid, num) {
    this.chanelsService
      .getPlaylistsCategories(sid)
      .subscribe((chanels: any) => {
        if (num == 1) {
          this.playlistFields.channelsList = chanels.children;
        } else {
          // this.schedule.channelId=null;
          this.schedule.channelsList = chanels.children;
        }
      });
  }

  subCategoryChange(e) {
    if (e.target.value == null || e.target.value == "null") {
      this.playlistFields.profileStatementList = [];
      this.playlistFields.profileStatementID = null;
    } else {
      this.updateProfileStatements(e.target.value);
    }
  }

  updateProfileStatements(cid) {
    this.profile_statementService
      .getProfileStatementList(cid)
      .subscribe((res: any) => {
        if (res.error != undefined) {
          this.playlistFields.profileStatementList = [];
          this.playlistFields.profileStatementID = null;
        } else {
          this.playlistFields.profileStatementList = res;
        }
      });
  }

  /**
   Update playlist information
   * @param e - event
   * @param {NgForm} form - form
   * @returns {boolean}
   */
  playlistid="";
  playlistUpdate(e, form: NgForm) {
    if (form.value.chanel_id != null) {
      this.playlistFields.chanel = form.value.chanel_id;
    } else {
      this.playlistFields.chanel = null;
      this.alert.alertOpen("Please select Site and Channel", 1);
      return false;
    }
   

    var str = Object.keys(form.value)
      .map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == "null" || val == null) {
          val = "";
        }
        return encodeURIComponent(key) + "=" + val;
      })
      .join("&");
    if (
      this.playlistFields.chanel != null &&
      this.playlistFields.user != null
    ) {
      if (this.playlistFields.id == null) {
        this.playlistServ
          .createPlaylist(this.playlistFields.chanel, str)
          .subscribe((playlists: any) => {
            if (playlists.error == undefined) {
              this.alert.alertOpen("Playlist added", 0);
              this.playlistid=playlists.id;
              this.updatePlaylists(0);
            } else {
              this.alert.alertOpen(playlists.error, 1);
            }
          });
      } else {
        this.playlistServ
          .updatePlaylist(
            this.playlistFields.chanel,
            this.playlistFields.id,
            str
          )
          .subscribe((playlists: any) => {
            if (playlists.error == undefined) {
              this.alert.alertOpen("Playlist updated", 0);
              this.updatePlaylists(0);
            } else {
              this.alert.alertOpen(playlists.error, 1);
            }
          });
      }
    } else {
      this.alert.alertOpen("Please select Client and Channel", 1);
    }
  }

  /**
   Actions when changed channel in select
   * @param $event - event
   * @param num - type of action
   */
  channelChange($event, num) {
    this.updatePlaylists(num);
    this.schedule.playlistId = null;
  }

  /**
   Get playlists
   * @param num - type of action
   */
  updatePlaylists(num) {
    if (num == 1) {
      if (this.schedule.channelId != null) {
        // this.schedule.playlistId=null;
        this.playlistServ
          .getPlaylists(this.schedule.channelId)
          .subscribe((playlists: any) => {
            this.schedule.playlistList = playlists;
            this.overlayschedule.playlistList=playlists;
          });
      }
    } else {
      if (this.checkedChanelId.id != null) {
        this.playlistServ
          .getPlaylists(this.checkedChanelId.id)
          .subscribe((playlists) => {
            this.playlists = playlists;
          });
      }
    }
  }

  /**
   Set status for playlist
   * @param e - event
   * @param pid - playlist id
   * @param sid - site id
   */
  setStatus(e, pid, sid) {
    e.preventDefault();

    if (this.changingStatus) {
      this.alert.alertOpen(
        "The previous status change request is not yet completed.",
        1
      );
      return false;
    }

    this.changingStatus = true;
    this.playlistServ.setStatus(pid, sid).subscribe((status) => {
      if (this.searchPopupOpened) {
        this.search(e);
      }
      this.updatePlaylists(0);
      this.alert.alertOpen("Status changed successfully!", 0);
      this.changingStatus = false;
    });
  }

  statusChange(e, pid) {
    e.preventDefault();
    if (this.changingStatus) {
      this.alert.alertOpen(
        "Please wait! The previous status change request is in progress.",
        1
      );
      return false;
    }

    this.isLoad = true;
    this.changingStatus = true;
    this.playlistServ.setStatus(pid, e.target.value).subscribe((status) => {
      if (this.searchPopupOpened) {
        this.search(e);
      }
      this.updatePlaylists(0);
      this.archivePlaylists( this.checkedChanelId.id );
      this.updateSchedules(this.checkedChanelId.id);
      this.alert.alertOpen(
        "Status successfully changed to " +
          this.playlistStatusText[e.target.value] +
          "!",
        0
      );
      this.changingStatus = false;
      this.isLoad = false;
    });
  }

  setHidden(e, pid) {
    this.playlistServ.setHidden(pid, e).subscribe((status: any) => {
      if (status.error != undefined) {
        this.alert.alertOpen(status.error, 1);
      } else {
        if (e) e = "YES";
        else e = "NO";
        this.alert.alertOpen("Hidden value changed to " + e + " !", 0);
      }
    });
  }

  /**
   Clear array of playlists
   */
  clearPlaylistsData() {
    this.playlists = null;
  }

  /**
   Clear array of channels
   */
  clearChanelsData() {
    this.checkedChanelId.id = null;
    this.chanelsList = null;
  }

  /**
   Cliek link to edit client playlist
   * @param id - playlist id
   */
  manageClick(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        playlistID: id,
        pcat: this.checkedSiteId.id,
        uid: this.checkedUserId.id,
      },
    };
    this.router.navigate(["manage-client-playlists"], navigationExtras);
  }

  manageClickPopup(id, client_id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        playlistID: id,
        pcat: this.checkedSiteId.id,
        uid: client_id,
      },
    };
    this.router.navigate(["manage-client-playlists"], navigationExtras);
  }
  /**
   Cliek link to edit foundation playlist
   * @param id - playlist id
   */
  manageFPClick(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        playlistID: id,
      },
    };
    this.router.navigate(["manage-playlists"], navigationExtras);
  }
  /**
   Cliek link to edit schedule
   * @param id - schedule id
   */
  manageScheduleClick(id) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        scheduleID: id,
        cid: this.checkedChanelId.id,
        uid: this.checkedUserId.id,
      },
    };
    this.router.navigate(["manage-schedule"], navigationExtras);
  }

  /**
   Delete playlist
   * @param id - playlist id
   */

  deletePlaylist(id) {
    this.playlistServ.deletePlaylist(id).subscribe((playlistDel) => {
      this.alert.alertOpen("Playlist deleted", 0);
      if (this.searchPopupOpened) {
        this.search(window.event);
      }
      this.updatePlaylists(0);
    });
  }
  /**
   Get search results
   @param e - event
   */
  search(e) {
    this.clearEntitySearch(e);
    e.preventDefault();
    if (this.searchStr != "") {
      if (this.searchStr.length < 6) {
        this.alert.alertOpen("Please enter minimum 6 characters to search", 1);
        return false;
      }
      this.playlistServ
        .searchPlaylists(this.searchStr)
        .subscribe((playlists: Array<any>) => {
          if (playlists != null) {
            console.log("playlist", playlists);
            const newPlaylists = playlists.filter((object) => {
              return object.cp_category.parent_id !== 1;
            });
            this.searchedPlaylists = newPlaylists;
          } else this.searchedPlaylists = [];
          this.searchPopupOpened = true;
        });
    }
    console.log(this.searchedPlaylists);
  }
  /**
   Crear search results
   @param e - event
   * @returns {boolean}
   */
  clearSearch(e) {
    e.preventDefault();
    this.searchStr = "";
  }

  entitySearchResult = [];
  entitySearch(e) {
    localStorage.setItem("cp_es_keyword", this.entitySearchStr.toLowerCase());
    this.sitesList = [];
    this.checkedUserId.id = null;
    this.checkedSiteId.id = null;
    this.checkedChanelId.id = null;
    this.checkedUserId.name = "";
    this.checkedSiteId.name = "";
    this.checkedChanelId.name = "";
    e.preventDefault();
    if (this.entitySearchStr != "") {
      this.isLoad = true;

      this.chanelsService
        .getSiteSearch(this.entitySearchStr.toLowerCase())
        .subscribe((sites: any) => {
          // if(!isNaN(parseFloat(this.entitySearchStr))){
          //   let users = [];
          //   for (let index = 0; index < sites.length; index++) {
          //     let indexExists = users.findIndex(x => x.id == sites[index].client_id);
          //     if (indexExists === -1){
          //       let user = this.usersListRaw.find(x => x.id == sites[index].client_id);
          //       if(user) {
          //         users.push(user);
          //       }
          //     }
          //   }
          //   this.usersList = users;
          // }
          // else{
          if (sites == undefined || sites.length == 0) {
            this.alert.alertOpen("No results", 1, 15);
            this.clearEntitySearch(e);
          }
          let data = [];
          for (var row in sites) {
            let children = [];
            for (var rowInner in sites[row]["children"]) {
              children.push(sites[row]["children"][rowInner]);
            }
            sites[row]["children"] = children;
            data.push(sites[row]);
          }
          this.entitySearchResult = data;
          // }
          // this.usersList = sites;
          this.sitesList = sites;
          this.passdatatochild.user = this.sitesList[0].client_id;
          this.isLoad = false;
        });
    }
  }
  /**
   Crear search results
   @param e - event
   * @returns {boolean}
   */
  clearEntitySearch(e) {
    this.isLoad = true;
    e.preventDefault();
    this.entitySearchStr = "";
    this.entitySearchResult = [];
    this.checkedUserId.id = null;
    this.checkedSiteId.id = null;
    this.checkedChanelId.id = null;
    this.checkedUserId.name = "";
    this.checkedSiteId.name = "";
    this.checkedChanelId.name = "";
    this.updateClientsList();
    localStorage.removeItem("cp_es_keyword");
  }

  // checkEntityFilterSite(site){
  //   let searchString = this.entitySearchStr.toLowerCase();
  //   if(this.entitySearchStr == ''
  //     || site.name.toLowerCase().indexOf(searchString) >= 0
  //     || site.children.some(cat => cat.name.toLowerCase().indexOf(searchString) >= 0))
  //     return false;
  //   return true;
  // }

  // checkEntityFilterChannel(channel){
  //   let searchString = this.entitySearchStr.toLowerCase();
  //   if(this.entitySearchStr == ''
  //     || channel.name.toLowerCase().indexOf(searchString) >= 0)
  //     return false;
  //   return true;
  // }

  /**
   Start playing tracks from playlist
   * @param e - event
   * @param pid - playlist id
   * @returns {boolean}
   */
  startPlaylist(e, pid) {
    if (pid != null) {
      if (pid != this.playerInfo.playlistID) {
        this.playlistServ
          .getPlaylistTracks(pid, "ASC", "add_date")
          .subscribe((tracks: any) => {
            this.playerInfo.tracks = tracks;
            this.playerInfo.playlistID = pid;
            this.playerInfo.trackIndex = 0;
            if (this.playerInfo.tracks.length > 0) {
              this.playerInfo.filename =
                this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
              this.playerInfo.trackId =
                this.playerInfo.tracks[this.playerInfo.trackIndex].id;
              this.playerInfo.album =
                this.playerInfo.tracks[this.playerInfo.trackIndex].album;
              this.playerInfo.artist =
                this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
              this.playerInfo.storage =
                this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
              this.playerInfo.token = this.globals.access_token;
              this.player.start(this.playerInfo.trackId, true);
            }
          });
      } else {
        if (this.playerInfo.isPlaying == "play") {
          this.player.pause();
        } else {
          this.player.start(this.playerInfo.trackId, true);
        }
      }
    }
    return false;
  }

  /**
   Chegking tracks playing
   * @param e - event
   */
  checkPlaying(e) {
    this.playerInfo.isPlaying = e;
  }

  /**
   If track playing play next track in playlist
   * @param e - event
   */
  nextTrack(e) {
    if (this.playerInfo.isPlaying == "play") {
      if (this.playerInfo.tracks.length > this.playerInfo.trackIndex + 1) {
        this.playerInfo.trackIndex = this.playerInfo.trackIndex + 1;
        this.playerInfo.trackId =
          this.playerInfo.tracks[this.playerInfo.trackIndex].id;
        this.playerInfo.filename =
          this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
        this.playerInfo.album =
          this.playerInfo.tracks[this.playerInfo.trackIndex].album;
        this.playerInfo.artist =
          this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
        this.playerInfo.storage =
          this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
        this.playerInfo.token = this.globals.access_token;
        this.player.start(this.playerInfo.trackId, true);
      }
    }
  }
  /**
   If track playing play previous track in playlist
   * @param e - event
   */
  prevTrack(e) {
    if (this.playerInfo.isPlaying == "play") {
      if (this.playerInfo.trackIndex > 0) {
        this.playerInfo.trackIndex = this.playerInfo.trackIndex - 1;
        this.playerInfo.trackId =
          this.playerInfo.tracks[this.playerInfo.trackIndex].id;
        this.playerInfo.filename =
          this.playerInfo.tracks[this.playerInfo.trackIndex].file_name;
        this.playerInfo.album =
          this.playerInfo.tracks[this.playerInfo.trackIndex].album;
        this.playerInfo.artist =
          this.playerInfo.tracks[this.playerInfo.trackIndex].artist;
        this.playerInfo.storage =
          this.playerInfo.tracks[this.playerInfo.trackIndex].storage;
        this.playerInfo.token = this.globals.access_token;
        this.player.start(this.playerInfo.trackId, true);
      }
    }
  }

  /**
   Open popup with restored playlist
   * @param e - event
   * @param pid - playlist id
   * @param date - date of restore
   * @returns {boolean}
   */
  openPlaylistRestorePopup(e, pid, date) {
    this.mysqlRestoredDate = date;
    this.popupClose(e);
    this.playlistServ
      .getTracksWithDeleted(pid, date)
      .subscribe((tracks: any) => {
        if (tracks.result == undefined && tracks.error == undefined) {
          this.restoredPlaylistTracks = tracks;
        } else if (tracks.result != undefined) {
          this.restoredPlaylistTracks = [];
          this.alert.alertOpen(tracks.result, 0);
        } else {
          this.restoredPlaylistTracks = [];
          this.alert.alertOpen(tracks.error, 1);
        }
        this.popups.restoredPlaylistPopupOpened = true;
      });
    return false;
  }

  /**
   Open dates restored channels
   * @param e - event
   * @param cid - channel id
   * @returns {boolean}
   */
  openRestoredDatesPopup(e, cid) {
    this.popupClose(e);
    this.playlistServ.getModifyDates(cid).subscribe((dates: any) => {
      if (dates.result != "There are no records for this category") {
        this.historyDates = dates;
        this.popups.restoredDatesPopupOpened = true;
      } else {
        this.alert.alertOpen("There are no records for this category", 0);
      }
    });
    return false;
  }

  /**
   Open popup with restored channels
   * @param e - event
   * @param cid - channel id
   * @param date - date of restore
   * @returns {boolean}
   */
  openChanelsRestorePopup(e, cid, date) {
    this.popupClose(e);
    this.playlistServ
      .getModifyPlaylists(cid, date)
      .subscribe((playlists: any) => {
        this.mysqlRestoredDate = date;
        if (playlists.result == undefined) {
          this.restoredChanels = playlists;
        } else {
          this.alert.alertOpen(playlists.result, 1);
        }
        this.popups.restoredChanelsPopupOpened = true;
      });
    return false;
  }

  /**
   Open popup with restored tracks
   * @param e - event
   * @param tid - track id
   * @returns {boolean}
   */
  openTrackRestorePopup(e, tid) {
    this.popupClose(e);
    this.tracksService.getModifyTracks(tid, this.mysqlRestoredDate).subscribe();
    this.popups.restoredTracksPopupOpened = true;
    return false;
  }

  /**
   Show linked founation playlists
   * @param pid - playlist id
   */
  showFoundation(pid) {
    if (this.selectedFoundation == pid) {
      this.selectedFoundation = null;
    } else {
      this.selectedFoundation = pid;
    }
  }
  showActions(id, num) {
    return false;
  }

  /**
   Validation of password
   * @param str - password string
   * @returns {boolean}
   */
  validatePassword(str) {
    if (str == "") {
      return true;
    }
    var spec = /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
    if (str.length < 8 && str.length > 16) {
      return false;
    }
    if (!str.match(/[A-z]/) || !str.match(/[A-Z]/) || !str.match(/[0-9]/)) {
      return false;
    }
    if (!spec.test(str)) {
      return false;
    }
    return true;
  }

  /**
   Validation of email
   * @param email - email string
   * @returns {boolean}
   */
  validateEmail(email) {
    var pattern = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    return pattern.test(email);
  }

  /**
   Checking press keys in keyboard
   * @param event - event
   */
  keyPress(event: any) {
    const pattern = /[0-9\ ]/;
    let inputChar = String.fromCharCode(event.charCode);

    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  /**
   Tab selection
   * @param tabId - tab index
   */
  selectTab(tabId) {
    this.resetLogForm();
    this.tabs.selected = tabId;
    if(tabId==3){ 
      this.showOdbApiLog(this.checkedSiteId.id,this.checkedChanelId.id)
      this.getSystemStatus();
    }
  }
  selectSiteTab(tabId) {
    this.resetLogForm();
    this.siteTabs.selected = tabId;
  }
  /**
   Click outside element
   * @param $event
   */
  @HostListener("document:click", ["$event"]) clickedOutside($event) {
    this.isShowActions.number = 4;
  }
  /**
   Click inside element
   * @param e - event
   */
  clickedInside(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  /**
   Parse time for selects
   */
  setTime() {
    var isSet = true;
    var index = 0;

    while (isSet) {
      if (this.time.h < 10) {
        this.timeArr[index] = "0" + this.time.h.toString() + ":";
      } else {
        this.timeArr[index] = this.time.h.toString() + ":";
      }
      if (this.time.m == 0) {
        this.timeArr[index] += "00";
      } else {
        this.timeArr[index] += this.time.m;
      }
      if (this.time.m == 45) {
        this.time.h += 1;
        this.time.m = 0;
      } else {
        this.time.m += 15;
      }
      if (this.time.h == 18) {
        this.timeArr[index + 1] = this.time.h.toString() + ":";
        this.timeArr[index + 1] += "00";
        this.time.h = 0;
        isSet = false;
      }
      index += 1;
    }
    this.finishTimeArr = this.timeArr;
  }

  /**
   Fill array of days for recurrence
   */
  setRecurrenceDaysArr() {
    for (var i = 0; i < 7; i++) {
      this.schedule.recurrence.weekly.days[i] = false;
    }
  }

  /**
   Set days for calendar
   */
  setRecurrenceMonthlyDaysArr() {
    for (var i = 0; i < 31; i++) {
      var o = {
        num: null,
        value: null,
      };
      o.num = i + 1;
      o.value = false;
      this.schedule.recurrence.monthly.days[i] = o;
    }
  }

  /**
   Fill array of monthes for recurrence
   */
  setRecurrenceMonthesArr() {
    for (var i = 0; i < 12; i++) {
      this.schedule.recurrence.yearly.monthes[i] = false;
    }
  }

  /**
   Change recurrence
   */
  changeRecurrence() {
    if (this.schedule.recurrence_fl == "1") {
      this.recurrence = Object.assign({}, this.schedule.recurrence);
      this.popups.recurrencePopupOpened = true;
    }
  }

  /**
   Change mastering
   */
  changeMastering() {
    if (this.schedule.mastering_fl == "1") {
      this.mastering = Object.assign({}, this.schedule.mastering);
      this.popups.masteringPopupOpened = true;
    }
  }

  /**
   Open popup for edit schedule information
   * @param e - event
   * @returns {boolean}
   */
  openSchedulePopup(e,num) {
    this.popupClose(e);
    this.focusInput();
    this.resetSchedule();
    const currentDate = new Date();
    this.schedule.onDemand = false;
    this.schedule.random="1";
    this.schedule.begin_date.year=currentDate.getFullYear().toString();
    this.schedule.begin_date.month=(currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based;
    this.schedule.begin_date.day= currentDate.getDate().toString().padStart(2, '0');
    this.schedule.finish_date.year="2121";
    this.schedule.finish_date.month="12";
    this.schedule.finish_date.day="31";
    this.schedule.recurrence_fl="1"
    if(num==1){
      this.schedule.name=this.playlistFields.name;
      this.schedule.playlistId=this.playlistid;
      
    }
    if(num==2){
      this.schedule.name=this.playlistFields.name +" INTERSUB";
      this.schedule.playlistId=this.playlistid;
      this.schedule.onDemand = true;
      this.schedule.begin_date.year="1899";
      this.schedule.begin_date.month="01" 
      this.schedule.begin_date.day= "01";
      this.schedule.finish_date.year="2121";
      this.schedule.finish_date.month="12";
      this.schedule.finish_date.day="31";
      this.schedule.recurrence_fl="1"
    }
    // this.schedule.random="1";
    // this.schedule.begin_date.year=currentDate.getFullYear().toString();
    // this.schedule.begin_date.month=(currentDate.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-based;
    // this.schedule.begin_date.day= currentDate.getDate().toString().padStart(2, '0');
    // this.schedule.finish_date.year="2121";
    // this.schedule.finish_date.month="12";
    // this.schedule.finish_date.day="31";
    // this.schedule.recurrence_fl="1"
    this.popups.schedulePopupOpened = !this.popups.schedulePopupOpened;
    var colorPos = this.getRandomColor(this.colorPalette);

    if (this.checkedUserId.id != null) {
      this.schedule.userId = this.checkedUserId.id;
      this.updateSites(this.schedule.userId, 3);
    } else {
      this.schedule.userId = null;
      this.schedule.sitesList = [];
    }
    if (this.checkedSiteId.id != null) {
      this.schedule.siteId = this.checkedSiteId.id;
      this.updateChanels(this.schedule.siteId, 2);
    } else {
      this.schedule.siteId = null;
      this.schedule.channelsList = [];
    }
    if (this.checkedChanelId.id != null) {
      this.schedule.channelId = this.checkedChanelId.id;
      this.updatePlaylists(1);
    }else {
      this.schedule.channelId = null;
      this.schedule.playlistList = [];
    }
    if(this.playlistFields.profileStatementID){
      this.profileStatementUpdate(this.playlistid);
    }
    return false;
  }

    resetSchedule() {
    this.schedule = {
      userId: null,
      sitesList: [],
      siteId: null,
      channelsList: [],
      channelId: null,
      playlistList: [],
      playlistId: null,
      name: "",
      begin_date: {
        year: "",
        month: "",
        day: "",
      },
      finish_date: {
        year: "",
        month: "",
        day: "",
      },
      begin_time: {
        hours: "00",
        minutes: "00",
      },
      //'00:00',
      finish_time: {
        hours: "00",
        minutes: "00",
      },
      //'00:00',
      bgColor: "#FFFFFF",
      txtColor: "#FF0000",
      recurrence_fl: "0",
      random: "0",
      mastering_fl: "0",
      clash_distance: "0",
      duration: "0",
      override_duration: {
        hours: "00",
        minutes: "00",
      },
      duration_unit: "mins",
      artistsNumber: 0,
      recurrence: {
        radio_value: "1",
        daily: {
          days: 1,
        },
        weekly: {
          week: 1,
          days: [],
        },
        monthly: {
          month: 1,
          monthSelection: "1",
          days: [],
          number: 1,
          type: "SU",
        },
        yearly: {
          year: 1,
          monthes: [],
          monthSelection: false,
          number: 1,
          type: "SU",
        },
      },
      mastering: {
        m_switch: "0",
        days: 1,
        months: 1,
        months_2: 1,
        every_week_day: 1,
        recurrence_dow: "MO",
      },
      onDemand: false,
    };
  }
  /**
   Get color from array
   * @param colors - array with colors
   */
  getRandomColor(colors) {
    var item = Math.floor(Math.random() * colors.length);
    var el = Math.floor(Math.random() * 2);
    this.schedule.bgColor = colors[item][el];
    if (el == 0) this.schedule.txtColor = colors[item][1];
    else this.schedule.txtColor = colors[item][0];
  }

  /**
   Checking and confirmation schedule to indefinite date
   */
  checkSchedule() {
    this.scheduleValidation.begin_time = true;
    this.scheduleValidation.finish_time = true;
    this.scheduleValidation.duration = true;
    if (
      this.schedule.finish_date.year == undefined ||
      this.schedule.finish_date.year == "" ||
      this.schedule.finish_date.year == null
    ) {
      this.openConfirmPopup(null, 5);
    } else {
      this.createSchedule();
    }
  }

  /**
   Creating schedule
   */

   caseOnlyifOverlayProfile=false;
   scheduleInfo:any = null;//Schedule information
  createSchedule() {
    if (this.scheduleValidate(this.schedule)) {
      var str = this.createScheduleStr(this.schedule);
      this.scheduleService
        .createSchedule(this.schedule.channelId, str)
        .subscribe((sch: any) => {
          if (sch.message != undefined && sch.message != "") {
            let message = sch.message.replace(/;/g, " <br /> ");
            this.alert.alertOpen(message, 2);
          }
          if (sch.error != undefined) {
            this.alert.alertOpen(sch.error, 1);
          } else {
            this.updateSchedules(this.checkedChanelId.id);
            this.alert.alertOpen("New schedule created", 0);
            this.popupClose(window.event);
          }
          if(this.caseOnlyifOverlayProfile){
            this.scheduleService.getScheduleInfo(sch.schedule.id).subscribe(schedule => {
              this.scheduleInfo = schedule[0];
              this.setScheduleInfo(this.scheduleInfo);
              this.addFrequencyRange();
              this.addFrequencyRange();
              this.popups.openOverlaySchedulePopup=!this.popups.openOverlaySchedulePopup
            });
            }
        });
    } else {
      this.alert.alertOpen("Please fill all required fields", 1);
    }
  }
  overlayschedule = {
    id: null,
    playlistList: [],
    playlistId: null,
    name: '',
    begin_date: {
      year:'',
      month:'',
      day:''
    },
    finish_date: {
      year:'',
      month:'',
      day:''
    },
    begin_time: {
      hours: '00',
      minutes:'00',
    },
    //'00:00',
    finish_time: {
      hours: '00',
      minutes:'00',
    },
    //'00:00',
    bgColor: '#FFFFFF',
    txtColor: '#FF0000',
    recurrence_fl: '0',
    random: '0',
    mastering_fl: '0',
    clash_distance: '',
    duration: '',
    override_duration: {
      hours: '00',
      minutes:'00',
    },
    duration_unit: 'mins',
    artistsNumber: 0,
    recurrence: {
      radio_value: '1',
      daily:{
        days: 1
      },
      weekly:{
        week: 1,
        days: []
      },
      monthly:{
        month: 1,
        monthSelection: '1',
        days:[],
        number: 1,
        type: 'SU'
      },
      yearly:{
        year:1,
        monthes:[],
        monthSelection: false,
        number: 1,
        type: 'SU'
      }
    },
    mastering: {
      m_switch: '0',
      days: '1',
      months: '1',
      months_2: '1',
      every_week_day: '1',
      recurrence_dow: "MO"
    },
    onDemand: false,
    onOverlay: false,
    frequency_range:[{
      id:null,
      startDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      endDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      startTime:{
        hours: '00',
        minutes:'00',
      },
      endTime:{
        hours: '00',
        minutes:'00',
      },
      frequency:null
    }]
  };


    /**
   Parse dates
   * @param days - dates string
   * @param arr - dates array
   * @returns {any}
   */
   parseDays(days, arr) {
    var dArr = days.split(',');

    for (let row of dArr) {
      var d = parseInt(row);
      arr[d - 1].value = true;
      arr[d - 1].value = true;
    }
    return arr;
  }

  /**
   Parse monthes
   * @param monthes - monthes string
   * @param arr - monthes array
   * @returns {any}
   */
  parseMonthes( monthes, arr ){
    var mArr = monthes.split(',');
    for (let row of mArr) {
      var m = parseInt(row);
      arr[ m - 1 ] = true;
    }
    return arr;
  }
  
  setScheduleInfo(info){
    this.updatePlaylists(1);
    this.overlayschedule.id = info.id;
    this.overlayschedule.playlistId = this.playlistid;
    var modifiedName = info.name.replace("INTERSUB", "").trim();
    // Assign the modified name to this.overlayschedule.name
    this.overlayschedule.name = modifiedName + " OVERLAY";


    let begin_date_array_date = info.begin_date.split(" ");
    let begin_date_array = begin_date_array_date[0].split("-");
    let finish_date_array_date = info.finish_date.split(" ");
    let finish_date_array = finish_date_array_date[0].split("-");
    let begin_time_array = begin_date_array_date[1].split(":");
    let finish_time_array = finish_date_array_date[1].split(":");

    this.overlayschedule.begin_date.year = begin_date_array[0];
    this.overlayschedule.begin_date.month = begin_date_array[1];
    this.overlayschedule.begin_date.day = begin_date_array[2];
    this.overlayschedule.finish_date.year = finish_date_array[0];
    this.overlayschedule.finish_date.month = finish_date_array[1];
    this.overlayschedule.finish_date.day = finish_date_array[2];
    this.overlayschedule.begin_time.hours = "06";
    this.overlayschedule.begin_time.minutes = "00";
    this.overlayschedule.finish_time.hours = "05";
    this.overlayschedule.finish_time.minutes = "59";

    
    this.overlayschedule.bgColor = '#' + info.background_color;
    this.overlayschedule.txtColor = '#' + info.text_color;
    this.overlayschedule.recurrence_fl = info.recurrence_flag.toString();
    this.overlayschedule.random = info.rnd_order.toString();
    this.overlayschedule.mastering_fl = info.mastering.toString();
    this.overlayschedule.clash_distance = info.clash_distance;
    this.overlayschedule.duration = info.override_duration;
    this.overlayschedule.duration_unit = info.override_duration_unit;
    
    if(info.override_duration_unit && info.override_duration_unit.length == 4){
      this.overlayschedule.override_duration.hours = info.override_duration_unit.substr(0,2);
      this.overlayschedule.override_duration.minutes = info.override_duration_unit.substr(2,2);
    }
    if(this.overlayschedule.recurrence_fl == '1'){
      if( info.recurrence_type == 'daily' ){
        this.overlayschedule.recurrence.radio_value = '1';
        this.overlayschedule.recurrence.daily.days = info.recurrence_every;
      }
      else if( info.recurrence_type == 'weekly' ){
        this.overlayschedule.recurrence.radio_value = '2';
        this.overlayschedule.recurrence.weekly.week = info.recurrence_every;
        
        if(!info.recurrence_dow)
          info.recurrence_dow = 'null';
        this.overlayschedule.recurrence.weekly.days = info.recurrence_dow;  
      }
      else if( info.recurrence_type == 'monthly' ){
        this.overlayschedule.recurrence.radio_value = '3';
        this.overlayschedule.recurrence.monthly.month = info.recurrence_every;
        var days = info.recurrence_days;
        this.setRecurrenceMonthlyDaysArr();
        if(days != null){
          this.overlayschedule.recurrence.monthly.monthSelection = '1';
          this.overlayschedule.recurrence.monthly.days = this.parseDays( days, this.overlayschedule.recurrence.monthly.days );
        }
        else if( info.recurrence_additional_rule_1 != null && info.recurrence_additional_rule_2 != null ){
          this.overlayschedule.recurrence.monthly.monthSelection = '2';
          this.overlayschedule.recurrence.monthly.number = info.recurrence_additional_rule_1;
          this.overlayschedule.recurrence.monthly.type = info.recurrence_additional_rule_2;
        }
      }
      else if( info.recurrence_type == 'yearly' ){
        this.overlayschedule.recurrence.radio_value = '4';
        this.overlayschedule.recurrence.yearly.year = info.recurrence_every;
        var monthes = info.month_s;
        this.overlayschedule.recurrence.yearly.monthes = this.parseMonthes( monthes, this.overlayschedule.recurrence.yearly.monthes );
        if( info.recurrence_additional_rule_1 != null && info.recurrence_additional_rule_2 != null ){
          this.overlayschedule.recurrence.yearly.monthSelection = true;
          this.overlayschedule.recurrence.yearly.number = info.recurrence_additional_rule_1;
          this.overlayschedule.recurrence.yearly.type = info.recurrence_additional_rule_2;
        }
        else{
          this.overlayschedule.recurrence.yearly.monthSelection = false;
        }
      }
    }
    if(this.overlayschedule.mastering_fl == '1'){
      this.overlayschedule.mastering.m_switch = info.mastering_switch.toString();
      if( this.overlayschedule.mastering.m_switch == '0' ){
        this.overlayschedule.mastering.days = info.mastering_days;
        this.overlayschedule.mastering.months = info.mastering_months;
      }
      else if(info.mastering_switch == '1'){
        this.overlayschedule.mastering.every_week_day = info.mastering_every_week_day;
        this.overlayschedule.mastering.recurrence_dow = info.mastering_recurrence_dow;
        this.overlayschedule.mastering.months_2 = info.mastering_months;
      }
    }
    
    let fr = [{
      id:null,
      startDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      endDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      startTime:{
        hours: '00',
        minutes:'00',
      },
      endTime:{
        hours: '00',
        minutes:'00',
      },
      frequency:null
    }];
    this.overlayschedule.onOverlay = true;
    if(info.frequency_ranges.length > 0){
      fr = [];
    }
   
    for (let index = 0; index < info.frequency_ranges.length; index++) {
      const element = info.frequency_ranges[index];
      
      let startdate_array_date = element.startdate.split(" ");
      let startdate_array = startdate_array_date[0].split("-");
      let enddate_array_date = element.enddate.split(" ");
      let enddate_array = enddate_array_date[0].split("-");
      let starttime_array = startdate_array_date[1].split(":");
      let endtime_array = enddate_array_date[1].split(":");
      
      fr.push({
        id: element.id,
        startDate:{
          year: startdate_array[0],
          month: startdate_array[1],
          day: startdate_array[2],
        },
        endDate:{
          year: enddate_array[0],
          month: enddate_array[1],
          day: enddate_array[2],
        },
        // endDate.year: this.setDate(element.enddate),
        startTime:{
          hours: starttime_array[0],
          minutes: starttime_array[1],
        },
        endTime:{
          hours: endtime_array[0],
          minutes: endtime_array[1],
        },
        frequency:element.frequency,
        
      });
      if(!this.year_fr.includes(startdate_array[0]) && startdate_array[0] !== "YYYY"){
        this.year_fr.unshift(startdate_array[0]);
      }
      if(!this.year_fr.includes(enddate_array[0]) && enddate_array[0] !== "YYYY"){
        this.year_fr.unshift(enddate_array[0]);
      }
    }
    this.overlayschedule.frequency_range = fr;
  }

 

  addFrequencyRange(){
    var a = {
      id: null,
      startDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      endDate:{
        year:'YYYY',
        month:'MM',
        day:'DD'
      },
      startTime:{
        hours: '00',
        minutes:'00',
      },
      endTime:{
        hours: '00',
        minutes:'00', 
      },
      frequency:null
    }
    this.overlayschedule.frequency_range.push(a);

    return false;
  }
  removeFrequencyRange(index,frid,splice){
    if(frid != null){
      this.scheduleService.deleteFR(frid).subscribe((ps:any) => {
        if(ps.error != undefined){
          this.alert.alertOpen(ps.error,1);
        }
        else{
          if(splice){
            this.overlayschedule.frequency_range.splice( index, 1 );
          }
        }
      });
    }
    else{
      if(splice){
        this.overlayschedule.frequency_range.splice( index, 1 );
      }
    }
    return false;
  }


  /**
   Create string for query for schedule creating/editing
   * @param sch - schedule object
   * @returns {string}
   */
  createScheduleStr(sch) {
    var str =
      "name=" +
      sch.name +
      "&cp_playlist_id=" +
      sch.playlistId +
      // + '&begin_date=' + this.parseDate(sch.begin_date.formatted)
      "&begin_date=" +
      sch.begin_date.year +
      "-" +
      sch.begin_date.month +
      "-" +
      sch.begin_date.day;
    //+ '&finish_date=' + this.parseDate(sch.finish_date.formatted)
    if (
      sch.finish_date.year == "" ||
      sch.finish_date.year == null ||
      sch.finish_date.year == undefined
    )
      str += "&finish_date=" + this.parseDate(sch.finish_date.year);
    else {
      str +=
        "&finish_date=" +
        sch.finish_date.year +
        "-" +
        sch.finish_date.month +
        "-" +
        sch.finish_date.day;
    }
    str +=
      "&begin_time=" +
      sch.begin_time.hours +
      ":" +
      sch.begin_time.minutes +
      "&finish_time=" +
      sch.finish_time.hours +
      ":" +
      sch.finish_time.minutes;
    if (sch.recurrence == "0") {
      str += "&recurrence_flag=" + sch.recurrence_fl;
      str += "&recurrence_every=1&recurrence_type=monthly";
    } else {
      str += "&recurrence_flag=" + sch.recurrence_fl;
      if (sch.recurrence.radio_value == "1") {
        str +=
          "&recurrence_type=daily&recurrence_every=" +
          sch.recurrence.daily.days;
      } else if (sch.recurrence.radio_value == "2") {
        str +=
          "&recurrence_type=weekly&recurrence_every=" +
          sch.recurrence.weekly.week;
        if (sch.recurrence.weekly.days != "null")
          str += "&recurrence_dow=" + sch.recurrence.weekly.days;
      } else if (sch.recurrence.radio_value == "3") {
        str +=
          "&recurrence_type=monthly&recurrence_every=" +
          sch.recurrence.monthly.month;
        if (sch.recurrence.monthly.monthSelection == "1") {
          str += "&recurrence_days=";
          var daySet = false;
          for (var i = 0; i < sch.recurrence.monthly.days.length; i++) {
            if (sch.recurrence.monthly.days[i].value) {
              if (daySet) str += ",";
              str += sch.recurrence.monthly.days[i].num;
              daySet = true;
            }
          }
        } else if (sch.recurrence.monthly.monthSelection == "2") {
          str +=
            "&recurrence_additional_rule_1=" +
            sch.recurrence.monthly.number +
            "&recurrence_additional_rule_2=" +
            sch.recurrence.monthly.type;
        }
      } else if (sch.recurrence.radio_value == "4") {
        str +=
          "&recurrence_type=yearly&recurrence_every=" +
          sch.recurrence.yearly.year;
        str += "&month_s=";
        var monthSet = false;
        for (var j = 0; j < sch.recurrence.yearly.monthes.length; j++) {
          if (sch.recurrence.yearly.monthes[j]) {
            if (monthSet) str += ",";
            str += j + 1;
            monthSet = true;
          }
        }
        if (sch.recurrence.yearly.monthSelection) {
          str +=
            "&recurrence_additional_rule_1=" +
            sch.recurrence.yearly.number +
            "&recurrence_additional_rule_2=" +
            sch.recurrence.yearly.type;
        }
      }
    }
    str += "&mastering=" + sch.mastering_fl;
    if (sch.mastering_fl == "1") {
      str += "&mastering_switch=" + sch.mastering.m_switch;
      if (sch.mastering.m_switch == "0") {
        str +=
          "&mastering_days=" +
          sch.mastering.days +
          "&mastering_months=" +
          sch.mastering.months;
      } else {
        str +=
          "&mastering_every_week_day=" +
          sch.mastering.every_week_day +
          "&mastering_recurrence_dow=" +
          sch.mastering.recurrence_dow +
          "&mastering_months=" +
          sch.mastering.months_2;
      }
    }
    str +=
      "&rnd_order=" +
      sch.random +
      "&clash_distance=" +
      sch.clash_distance +
      "&override_duration=" +
      sch.duration +
      "&override_duration_unit=" +
      sch.override_duration.hours +
      sch.override_duration.minutes +
      "&background_color=" +
      sch.bgColor +
      "&text_color=" +
      sch.txtColor;

    return str;
  }

  /**
   Parsing date for schedule string
   * @param date - date string
   * @returns {string}
   */
  parseDate(date) {
    if (date == "" || date == undefined || date == null) {
      return "indefinite";
    } else {
      var dateArr = date.split("/");
      return dateArr[2] + "-" + dateArr[1] + "-" + dateArr[0];
    }
  }

  /**
   Close recurrence popup
   * @param num - number of action
   * @returns {boolean}
   */
  recurrencePopupClose(num) {
    if (num == 0) {
      this.schedule.recurrence = this.recurrence;
    }
    this.popups.recurrencePopupOpened = false;
    return false;
  }
  /**
   Close mastering popup
   * @param num - number of action
   * @returns {boolean}
   */
  masteringPopupClose(num) {
    if (num == 0) {
      this.schedule.mastering = this.mastering;
    }
    this.popups.masteringPopupOpened = false;
    return false;
  }

  /**
   Get schedules list
   * @param cid - channel id
   */
  updateSchedules(cid) {
    this.schedulesList = [];
    this.scheduleService.getSchedulesList(cid).subscribe((sch: any) => {
      if (sch.result == undefined) this.schedulesList = sch;
    });
  }

  /**
   Delete schedule
   * @param sid - schedule id
   * @returns {boolean}
   */
  deleteSchedule(sid) {
    this.scheduleService.deleteSchedule(sid).subscribe((sch: any) => {
      if (sch.error != undefined) {
        this.alert.alertOpen(sch.error, 1);
      } else {
        this.alert.alertOpen("Schedule removed", 0);
        this.updateSchedules(this.checkedChanelId.id);
      }
    });
    return false;
  }

  /**
   Validate schedule before saving
   * @param sch - schedule id
   * @returns {boolean}
   */
  scheduleValidate(sch) {
    var isTrue = true;
    if (!this.checkValidate(sch.name)) {
      isTrue = false;
      this.scheduleValidation.name = false;
    } else {
      this.scheduleValidation.name = true;
    }
    if (!this.checkValidate(sch.userId)) {
      isTrue = false;
      this.scheduleValidation.userId = false;
    } else {
      this.scheduleValidation.userId = true;
    }
    if (!this.checkValidate(sch.siteId)) {
      isTrue = false;
      this.scheduleValidation.siteId = false;
    } else {
      this.scheduleValidation.siteId = true;
    }
    if (!this.checkValidate(sch.channelId)) {
      isTrue = false;
      this.scheduleValidation.channelId = false;
    } else {
      this.scheduleValidation.channelId = true;
    }
    if (!this.checkValidate(sch.playlistId)) {
      isTrue = false;
      this.scheduleValidation.playlistId = false;
    } else {
      this.scheduleValidation.playlistId = true;
    }
    if (!this.checkValidate(sch.begin_date.year)) {
      isTrue = false;
      this.scheduleValidation.begin_date = false;
    } else {
      this.scheduleValidation.begin_date = true;
    }
    if (!this.checkValidate(sch.begin_time)) {
      isTrue = false;
      this.scheduleValidation.begin_time = false;
    } else {
      this.scheduleValidation.begin_time = true;
    }
    if (!this.checkValidate(sch.finish_time)) {
      isTrue = false;
      this.scheduleValidation.finish_time = false;
    } else {
      this.scheduleValidation.finish_time = true;
    }
    if (!this.checkValidate(sch.clash_distance)) {
      isTrue = false;
      this.scheduleValidation.clash_distance = false;
    } else {
      this.scheduleValidation.clash_distance = true;
    }
    if (!this.checkValidate(sch.bgColor)) {
      isTrue = false;
      this.scheduleValidation.bgColor = false;
    } else {
      this.scheduleValidation.bgColor = true;
    }
    if (!this.checkValidate(sch.txtColor)) {
      isTrue = false;
      this.scheduleValidation.txtColor = false;
    } else {
      this.scheduleValidation.txtColor = true;
    }
    if (isTrue) {
      return true;
    }
    return false;
  }

  /**
   Cheking validation for schedule
   * @param val - validation value
   * @returns {boolean}
   */
  checkValidate(val) {
    if (val == "" || val == null || val == undefined) {
      return false;
    }
    return true;
  }

  /**
   Checking schedule duration
   */
  checkDuration() {
    if (parseInt(this.schedule.duration) != 0) {
      if (isNaN(parseInt(this.schedule.duration))) {
        this.alert.alertOpen("Please enter number", 1);
        this.schedule.duration = "0";
      } else {
        this.scheduleValidation.duration = false;
      }
    } else {
      this.scheduleValidation.duration = true;
    }
  }

  /**
   Cheking schedule distance
   */
  checkDistance() {
    var dist = parseInt(this.schedule.clash_distance);
    if (isNaN(dist)) {
      this.alert.alertOpen("Please enter number", 1);
      this.schedule.clash_distance = "0";
    } else {
      if (dist > this.schedule.artistsNumber) {
        this.alert.alertOpen(
          "Total number of Artists present in playlist is only " +
            this.schedule.artistsNumber +
            ". Please give less than that count!",
          1
        );
        this.scheduleValidation.clash_distance = false;
      } else {
        this.scheduleValidation.clash_distance = true;
      }
    }
  }

  /**
   Check schedule finish time
   */
  changeFinishTime() {
    var begin_hour = this.schedule.begin_time.hours;
    var begin_minute = this.schedule.begin_time.minutes;
    if (
      this.schedule.begin_time.hours == "00" &&
      this.schedule.begin_time.minutes == "00" &&
      this.schedule.finish_time.hours == "00" &&
      this.schedule.finish_time.minutes == "00"
    ) {
      this.schedule.onDemand = true;
      return false;
    } else {
      this.schedule.onDemand = false;
    }
    this.finish_hours = this.org_finish_hours.slice();
    this.finish_minutes = this.org_finish_minutes.slice();
    // if(Number(begin_hour) < 6){ // Mani-Jan6,2020
    //   this.finish_hours = this.finish_hours.filter(item => (Number(item) < 6 && Number(item) >= Number(begin_hour)) );
    // }
    // if(Number(begin_hour) >= 6){ // Mani-Jan6,2020
    let index: number = this.org_finish_hours.indexOf(begin_hour);
    if (index !== -1) {
      if (begin_minute == "59") {
        index = index + 1;
      }
      this.finish_hours.splice(0, index);
    }
    // }
    if (
      this.schedule.finish_time.hours < this.finish_hours[0] &&
      !(
        this.schedule.finish_time.hours >= "00" &&
        this.schedule.finish_time.hours < "06"
      )
    ) {
      this.schedule.finish_time.hours = this.finish_hours[0];
    }
    if (
      this.schedule.finish_time.hours < this.finish_hours[0] &&
      this.schedule.begin_time.hours > "00" &&
      this.schedule.begin_time.hours < "06" &&
      this.schedule.finish_time.hours > "00" &&
      this.schedule.finish_time.hours < "06"
    ) {
      this.schedule.finish_time.hours = this.finish_hours[0];
    }
    var finish_hour = this.schedule.finish_time.hours;
    if (Number(begin_hour) == Number(finish_hour)) {
      this.finish_minutes = this.finish_minutes.filter(
        (item) => Number(item) > Number(begin_minute)
      );
      if (
        this.schedule.finish_time.minutes == "00" ||
        this.schedule.finish_time.minutes < this.finish_minutes[0]
      )
        this.schedule.finish_time.minutes = this.finish_minutes[0];
    } else {
      if (this.schedule.finish_time.minutes < this.finish_minutes[0])
        this.schedule.finish_time.minutes = this.finish_minutes[0];
    }
  }

  /**
   Get time index from array
   * @param time - time string
   * @returns {number}
   */
  getTimeIndex(time) {
    for (var i = 0; i < this.timeArr.length; i++) {
      if (this.timeArr[i] == time) {
        return i;
      }
    }
    return -1;
  }

  /**
   Get number of artists for schedule
   */
  getArtistsNumber() {
    this.playlistServ
      .getArtistsNumber(this.schedule.playlistId)
      .subscribe((num: any) => {
        this.schedule.artistsNumber = parseInt(num.artists);
      });
  }

  /**
   Open small popup for edit schedule information
   * @param sid - site id
   * @param name - schedule name
   * @returns {boolean}
   */
  openEditSchedulePopup(sid, name) {
    this.editSchedule.id = sid;
    this.editSchedule.name = name;
    this.focusInput();
    this.popups.createSchedulePopupOpened =
      !this.popups.createSchedulePopupOpened;
    return false;
  }

  /**
   Update small schedule information
   * @param e - event
   * @param {NgForm} form - form
   */
  updateSchedule(e, form: NgForm) {
    if (this.editSchedule.name != "") {
      var str = "name=" + this.editSchedule.name;
      this.scheduleService
        .updateScheduleName(this.editSchedule.id, str)
        .subscribe((sch: any) => {
          if (sch.error != undefined) {
            this.alert.alertOpen(sch.error, 1);
          } else {
            this.updateSchedules(this.checkedChanelId.id);
            this.alert.alertOpen("Schedule name updated", 0);
            this.popupClose(e);
          }
        });
    } else {
      this.alert.alertOpen("Please fill all required fields", 1);
    }
  }
  /**
   Confirm action in confirmation popup
   * @param e - event
   */
  isConfirm(e) {
    if (e) {
      if (this.confirmValues.type == 0) this.deleteUser(this.confirmValues.id);
      else if (this.confirmValues.type == 1)
        this.deleteSite(this.confirmValues.id, 1);
      else if (this.confirmValues.type == 2)
        this.deleteSite(this.confirmValues.id, 2);
      else if (this.confirmValues.type == 3)
        this.deletePlaylist(this.confirmValues.id);
      else if (this.confirmValues.type == 4)
        this.deleteSchedule(this.confirmValues.id);
      else if (this.confirmValues.type == 5) this.createSchedule();
      else if (this.confirmValues.type == 6)
        this.deleteDocument(this.confirmValues.id);
      else if (this.confirmValues.type == 7)
        this.deleteProfileRaw(this.confirmValues.id);
      else if (this.confirmValues.type == 8)
        this.syncToDg(this.confirmValues.id);
      else if (this.confirmValues.type == 9)
        this.reHawkSiteLevel(this.confirmValues.id);
      else if (this.confirmValues.type == 10)
        this.forceHawk(this.confirmValues.id);
      else if (this.confirmValues.type == 11)
        this.cancelHawk(this.confirmValues.id);
    }
  }
  /**
   Open confirmation popup
   * @param id - id of action
   * @param t - text for popup
   * @returns {boolean}
   */
  openConfirmPopup(id, t) {
    var text = "Do you want to delete this client?";
    if (t == 1) text = "Do you want to delete this site?";
    if (t == 2) text = "Do you want to delete this channel?";
    if (t == 3) text = "Do you want to delete this playlist?";
    if (t == 4) text = "Do you want to delete schedule?";
    if (t == 5)
      text = "Would you like to set this schedule for a indefinite date?";
    if (t == 6) text = "Do you want to delete Document?";
    if (t == 7) text = "Do you want to delete the profile statement?";
    if (t == 8) text = "Do you want to sync the changes to distribution group?";
    if (t == 9) text = "Do you want to hawk this site?";
    if (t == 10) text = "Do you want to mark this site as complete?";
    if (t == 11) text = "Do you want to cancel the hawk for this site?";

    this.confirmValues.id = id;
    this.confirmValues.type = t;
    this.confirm.confirmOpen(text);
    return false;
  }
  /**
   Set param if input focused
   */
  focusInput() {
    this.isFocused = true;
  }
  /**
   Set param if input blured
   */
  blurInput() {
    this.isFocused = false;
  }

  /**
   Send params for build job
   */
  buildClick() {
    this.tabs.selected = 3;
    var date = new Date();
    let key = this.datePipe.transform(date, "yyyy-MM-dd");
    this.api_logs = null;
    this.api_logs_temp = { data: [], successCount: [] };
    this.api_logs_temp.data[key] = [];
    this.api_logs_temp.successCount[key] = 0;

    let site = this.sitesList.find((x) => x.id == this.checkedSiteId.id);
    let channel = site.children.find((x) => x.id == this.checkedChanelId.id);
    this.api_logs_temp.data[key].push({
      buildid: "",
      channel_id: channel.id,
      channel_name: channel.name,
      clip_count: "",
      created_at: channel.created_at,
      created_date: key,
      failedAttempt: 0,
      hawk_id: null,
      hawk_status: "",
      hawk_status_updated_at: null,
      id: 0,
      profile_count: "",
      response: "",
      serial_number: null,
      shared_clips: null,
      site_id: site.id,
      site_name: site.name,
      updated_at: channel.updated_at,
      user_id: channel.user_id,
    });

    this.isLoader = true;
    this.progressBarDisplay(0, 10);

    if (this.checkedChanelId.id != null) {
      this.isLoader = true;
      this.playlistServ
        .buildPlaylist(this.checkedChanelId.id)
        .subscribe((buildinfo: any) => {
          this.progressBarDisplay(10, 30);
          this.isLoader = false;
          if (buildinfo.error == undefined) {
            this.progressBarDisplay(30, 70);
            this.getBuildReponse(buildinfo.data.odbapi_log_id);
            this.alert.alertOpen("Job created", 0);
          } else {
            this.alert.alertOpen(buildinfo.error, 1, 15);
          }
        });
    }
  }

  /**
   Send params for SITE LEVEL build job
   */
  siteBuildClick() {
    if (this.checkedSiteId.id != null) {
      var date = new Date();
      let key = this.datePipe.transform(date, "yyyy-MM-dd");
      this.api_logs = null;
      this.api_logs_temp = { data: [], successCount: [] };
      this.api_logs_temp.data[key] = [];
      this.api_logs_temp.successCount[key] = 0;

      let site = this.sitesList.find((x) => x.id == this.checkedSiteId.id);
      for (let index = 0; index < site.children.length; index++) {
        const element = site.children[index];
        this.api_logs_temp.data[key].push({
          buildid: "",
          channel_id: element.id,
          channel_name: element.name,
          clip_count: "",
          created_at: element.created_at,
          created_date: key,
          failedAttempt: 0,
          hawk_id: null,
          hawk_status: "",
          hawk_status_updated_at: null,
          id: 0,
          profile_count: "",
          response: "",
          serial_number: null,
          shared_clips: null,
          site_id: site.id,
          site_name: site.name,
          updated_at: element.updated_at,
          user_id: element.user_id,
        });
      }

      this.isLoader = true;
      this.progressBarDisplay(0, 10);

      this.playlistServ
        .siteBuildPlaylist(this.checkedSiteId.id)
        .subscribe((buildinfo: any) => {
          this.progressBarDisplay(10, 30);
          this.isLoader = false;

          let msg = "";
          for (var row in buildinfo) {
            if (buildinfo.hasOwnProperty(row)) {
              if (buildinfo[row].error) {
                msg =
                  msg +
                  "<span> Job creation error for <b>" +
                  row +
                  "</b>. Error: " +
                  buildinfo[row].error +
                  " </span><br>";
              } else {
                msg =
                  msg +
                  "<span> Job created for channel <b>" +
                  row +
                  "</b>. </span><br>";
                this.progressBarDisplay(30, 70);
                this.getBuildReponse(buildinfo[row].data.odbapi_log_id);
              }
            }
          }

          setTimeout(() => {
            this.alert.alertOpen(msg, 0);
          }, 3000);
        });
    }
  }

  progressBarDisplay(current_progress, maxValue) {
    var interval = setInterval(() => {
      current_progress += 1;
      this.progressValue = current_progress;

      if (current_progress >= maxValue) clearInterval(interval);
    }, 10);
  }

  polling: any;

  getBuildReponse(id) {
    var c = this;
    let polling = setInterval(
      (function x() {
        c.playlistServ.getBuildResponse(id).subscribe((res) => {
          if (res["statusCode"] == 200 && res["result"]) {
            if (
              res["result"]["response"] != null &&
              res["result"]["response"]["statusCode"] == 200
            ) {
              c.progressBarDisplay(70, 100);
              let msg = "Build Success";
              let type = 0; //success
              if (
                res["result"]["response"]["statusMessage"]["returnvalue"] !=
                undefined
              ) {
                let returnValue =
                  res["result"]["response"]["statusMessage"]["returnvalue"];
                if (
                  returnValue["NumberOfClips"] !=
                    c.ConvertToInt(res["result"]["clip_count"]) ||
                  returnValue["NumberOfProfiles"] !=
                    c.ConvertToInt(res["result"]["profile_count"])
                ) {
                  msg = "Build failed - Count mismatch";
                  type = 1; // error
                }
              }
              c.alert.alertOpen(msg, type);
            }
            if (
              res["result"]["response"] != null &&
              res["result"]["response"]["statusCode"] == "BUILD_FAILED"
            ) {
              c.alert.alertOpen("Build Failed", 1);
            }
            if (res["result"]["response"]) {
              //console.log('enter poilling',polling);
              clearInterval(polling);
              if (c.api_logs_temp != undefined) {
                let key = c.datePipe.transform(new Date(), "yyyy-MM-dd");
                c.api_logs_temp.data[key][0].buildid = res["buildid"];
                c.api_logs_temp.data[key][0].response =
                  res["result"]["response"];
              }
            }
          }
        });
        return x;
      })(),
      15000
    );
  }

  showSyncProfileLog(site_id, channel_id = null) {
    let fromDate = null;
    let toDate = null;
    if (this.sortFilterValue != "date_range") {
      this.filterFromDate = null;
      this.filterToDate = null;
    }

    if (this.filterFromDate && this.filterToDate) {
      if (this.filterFromDate["epoc"] > this.filterToDate["epoc"]) {
        this.alert.alertOpen("Date Range Missmatch!", 1);
        return false;
      }
      fromDate = this.filterFromDate["formatted"];
      toDate = this.filterToDate["formatted"];
    } else if (this.filterFromDate) {
      this.alert.alertOpen("Please select 'To Date'!", 1);
      return false;
    } else if (this.filterToDate) {
      this.alert.alertOpen("Please select 'From Date'!", 1);
      return false;
    } else if (this.sortFilterValue == "date_range") {
      this.alert.alertOpen("Please select 'Date Range'!", 1);
      return false;
    }

    let build_status = null;
    if (this.buildSuccess && this.buildFailed) {
      build_status = 3;
    } else if (this.buildSuccess) {
      build_status = 1;
    } else if (this.buildFailed) {
      build_status = 2;
    }
    this.sync_profile_logs = [];
    this.isLoad = true;

    let dateFilter = null;
    if (this.sortFilterValue != "date_range") {
      dateFilter = this.sortFilterValue;
    }

    this.playlistServ
      .getSyncProfileLogs(
        site_id,
        channel_id,
        dateFilter,
        fromDate,
        toDate,
        build_status
      )
      .subscribe((res: any) => {
        this.isLoad = false;
        if (res["statusCode"] == 200 && res["result"].length > 0) {
          let result = this.groupBy(res["result"], "created_date");

          for (var key in result) {
            if (result.hasOwnProperty(key)) {
              for (let index = 0; index < result[key].length; index++) {
                // Do nothing
              }
            }
          }

          this.sync_profile_logs = result;

          console.log("this.sync_profile_logs", this.sync_profile_logs);
        }
      });
  }

  showAutoUpdateLog(channel_id) {
    let fromDate = null;
    let toDate = null;
    if (this.sortFilterValue != "date_range") {
      this.filterFromDate = null;
      this.filterToDate = null;
    }

    if (this.filterFromDate && this.filterToDate) {
      if (this.filterFromDate["epoc"] > this.filterToDate["epoc"]) {
        this.alert.alertOpen("Date Range Missmatch!", 1);
        return false;
      }
      fromDate = this.filterFromDate["formatted"];
      toDate = this.filterToDate["formatted"];
    } else if (this.filterFromDate) {
      this.alert.alertOpen("Please select 'To Date'!", 1);
      return false;
    } else if (this.filterToDate) {
      this.alert.alertOpen("Please select 'From Date'!", 1);
      return false;
    } else if (this.sortFilterValue == "date_range") {
      this.alert.alertOpen("Please select 'Date Range'!", 1);
      return false;
    }

    this.auto_update_logs = [];
    this.isLoad = true;

    let dateFilter = null;
    if (this.sortFilterValue != "date_range") {
      dateFilter = this.sortFilterValue;
    }

    this.playlistServ
      .getAutoUpdateLogs(channel_id, dateFilter, fromDate, toDate)
      .subscribe((res: any) => {
        this.isLoad = false;
        if (res["statusCode"] == 200 && res["result"].length > 0) {
          let result = this.groupBy(res["result"], "created_date");

          for (var key in result) {
            if (result.hasOwnProperty(key)) {
              for (let index = 0; index < result[key].length; index++) {
                // Do nothing
              }
            }
          }

          this.auto_update_logs = result;

          console.log("this.auto_update_logs", this.auto_update_logs);
        }
      });
  }

  viewSplDetail(org_mcluid, dup_mcluid, id) {
    if (org_mcluid == "") {
      this.alert.alertOpen("Mcluid kept can not be empty!", 1);
      return false;
    }
    if (dup_mcluid == "") {
      this.alert.alertOpen("Mcluid deleted can not be empty!", 1);
      return false;
    }
    if (this.spl_inner_key == id) {
      this.spl_inner_key = "";
      return false;
    } else {
      this.spl_inner_key = id;
    }

    this.spl_detail = [];
    this.isLoad = true;
    this.playlistServ
      .getSplDetail(org_mcluid, dup_mcluid)
      .subscribe((res: any) => {
        this.isLoad = false;
        if (res["statusCode"] == 200) {
          if (res["result"].length > 0) {
            this.spl_detail = res["result"];
            console.log("this.spl_detail", this.spl_detail);
          } else {
            this.alert.alertOpen("No detail found!", 1);
          }
        }
      });
  }

  showOdbApiLog(site_id, channel_id = null) {
    let fromDate = null;
    let toDate = null;
    if (this.sortFilterValue != "date_range") {
      this.filterFromDate = null;
      this.filterToDate = null;
    }

    if (this.filterFromDate && this.filterToDate) {
      if (this.filterFromDate["epoc"] > this.filterToDate["epoc"]) {
        this.alert.alertOpen("Date Range Missmatch!", 1);
        return false;
      }
      fromDate = this.filterFromDate["formatted"];
      toDate = this.filterToDate["formatted"];
    } else if (this.filterFromDate) {
      this.alert.alertOpen("Please select 'To Date'!", 1);
      return false;
    } else if (this.filterToDate) {
      this.alert.alertOpen("Please select 'From Date'!", 1);
      return false;
    } else if (this.sortFilterValue == "date_range") {
      this.alert.alertOpen("Please select 'Date Range'!", 1);
      return false;
    }

    let build_status = null;
    if (this.buildSuccess && this.buildFailed) {
      build_status = 3;
    } else if (this.buildSuccess) {
      build_status = 1;
    } else if (this.buildFailed) {
      build_status = 2;
    }
    this.api_logs = null;
    this.api_logs_temp = null;
    this.isLoad = true;

    let dateFilter = null;
    if (this.sortFilterValue != "date_range") {
      dateFilter = this.sortFilterValue;
    }

    this.playlistServ
      .getOBDAPILogs(
        site_id,
        channel_id,
        dateFilter,
        fromDate,
        toDate,
        build_status
      )
      .subscribe((res) => {
        this.isLoad = false;
        if (res["statusCode"] == 200 && res["result"].length > 0) {
          let result = this.groupBy(res["result"], "created_date");

          let finalData = { data: [], successCount: [] };
          for (var key in result) {
            if (result.hasOwnProperty(key)) {
              let success = 0;
              for (let index = 0; index < result[key].length; index++) {
                const element = result[key][index];

                element.response = JSON.parse(element.response);
                element.hawk_status = this.getHawkStatusValue(
                  element.hawk_status
                );

                if (
                  element.response &&
                  element.response.statusCode &&
                  element.response.statusCode == 200
                ) {
                  success++;
                }
              }
              finalData.successCount[key] = success;
            }
          }
          finalData.data = result;
          this.api_logs = finalData;
        } else {
          this.api_logs = null;
        }
      });
  }

  groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

    resetLogForm() {
    this.api_logs = null;
    this.api_logs_temp = null;
    this.sortFilterValue = null;
    this.filterFromDate = null;
    this.filterToDate = null;
  }

  // syncOdbapiDB(){
  //   this.playlistServ.syncODBAPIDB().subscribe((res:any)=>{
  //     console.log('tes',res);
  //   });
  //   this.alert.alertOpen("Sync successfully initiated!",0)
  // }
  numberOnly(event): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  channelHawk() {
    this.isLoadCHawk = true;
    this.playlistServ
      .hawk(this.checkedSiteId.id, this.checkedChanelId.id, null)
      .subscribe((res) => {
        this.isLoadCHawk = false;
        if (res["statusCode"] == 200 && res["result"].length > 0) {
          this.alert.alertOpen(res["result"], 0);
        } else {
          this.alert.alertOpen(res["error"], 1);
        }
      });
  }

  reHawk(channel_id, build_id, key, inner_key) {
    this.isLoadHawk = true;
    this.playlistServ
      .hawk(this.checkedSiteId.id, channel_id, build_id)
      .subscribe((res) => {
        this.isLoadHawk = false;
        if (res["statusCode"] == 200 && res["result"].length > 0) {
          this.api_logs.data[key][inner_key].hawk_status =
            this.getHawkStatusValue(res["hawk_status"]);
          this.api_logs.data[key][inner_key].hawk_status_updated_at =
            res["hawk_status_updated_at"];
          this.api_logs.data[key][inner_key].serial_number =
            res["serial_number"];
          this.alert.alertOpen(res["result"], 0);
        } else {
          this.alert.alertOpen(res["error"], 1);
        }
      });
  }

  reHawkSiteLevel(site_id) {
    this.isLoadSHawk = true;
    this.playlistServ.hawkSiteLevel(site_id).subscribe((res) => {
      this.isLoadSHawk = false;

      let msg = "";

      for (var key in res) {
        if (res.hasOwnProperty(key)) {
          if (res[key].error) {
            msg =
              msg +
              "<span> Hawk error : <br> Error: " +
              res[key].error +
              " </span><br><br>";
            this.alert.alertOpen(msg, 1, 15);
          } else {
            msg =
              msg +
              "<span> Hawk created: <br> Result: " +
              res[key].result +
              " </span><br><br>";
            this.alert.alertOpen(msg, 0, 15);
          }
        }
      }
    });
  }

  forceHawk(site_id) {
    // build_id,serial_number,hawk_id,key, inner_key
    this.isLoadHawk = true;
    this.playlistServ.forceHawk(site_id).subscribe((res) => {
      this.isLoadHawk = false;
      if (res["statusCode"] == 200 && res["result"].length > 0) {
        this.alert.alertOpen(res["result"], 0);
      } else {
        this.alert.alertOpen(res["error"], 1);
      }
    });
  }

  cancelHawk(site_id) {
    this.isCancelHawk = true;
    this.playlistServ.cancelHawk(site_id).subscribe((res) => {
      this.isCancelHawk = false;
      if (res["statusCode"] == 200 && res["result"].length > 0) {
        this.alert.alertOpen(res["result"], 0);
      } else {
        this.alert.alertOpen(res["error"], 1);
      }
    });
  }

  getHawkStatusValue(hawk_status) {
    let st = "";
    switch (hawk_status) {
      case 0:
        st = "In progress";
        break;
      case 1:
        st = "Success";
        break;
      case -1:
        st = "Failed";
        break;
      default:
        st = "";
        break;
    }
    return st;
  }

  onDocumentUpdate(documents) {
    this.documentList = documents;
  }

  afterUpload() {
    this.showPlaylists("", this.checkedChanelId.id, "", 0);
  }

  getParentEntityIDForSite(client_id) {
    let user = this.usersListRaw.find((x) => x.id == client_id);
    if (user)
      return {
        entity_id: user.entity_id,
        entity_name: user.first_name,
      };
    return false;
  }

  getParentEntityNameForSite(client_id) {
    let user = this.usersListRaw.find((x) => x.id == client_id);
    if (user) return user.first_name;
    return false;
  }

  ConvertToInt(val) {
    return parseInt(val) || 0;
  }

  onDemandClick(e) {
    this.finish_minutes = ["00"];
    this.schedule.begin_time.hours = "00";
    this.schedule.begin_time.minutes = "00";
    this.schedule.finish_time.hours = "00";
    this.schedule.finish_time.minutes = "00";
    this.schedule.onDemand = e.target.checked;
  }

  showSpLog(key) {
    console.log("enter");
    if (this.spl_key == key) {
      this.spl_key = "";
    } else {
      this.spl_key = key;
    }
  }

  showAULog(key) {
    if (this.aul_key == key) {
      this.aul_key = "";
    } else {
      this.aul_key = key;
    }
  }

  profileUpdate(e, form: NgForm,num) {
   
    var str = Object.keys(form.value)
      .map(function (key) {
        var val = encodeURIComponent(form.value[key]);
        if (val == "null" || val == null) {
          val = "";
        }
        return encodeURIComponent(key) + "=" + val;
      })
      .join("&");
    if (this.profileStatementFields.name == "") {
      this.alert.alertOpen("Please enter the profile statement name", 1);
      return false;
    }

    this.profile_statementService
      .createNewProfileStatement(this.checkedChanelId.id, str)
      .subscribe((res: any) => {
        if (res.error != undefined) {
          this.alert.alertOpen(res.error, 1);
        } else {
          this.alert.alertOpen("Profile Statement Created", 0);
          this.playlistFields.profileStatementID=res.id;
          this.updateProfileStatementList(this.checkedChanelId.id);
          if(num!=1){
          this.openProfilePopup(res.id);
          }
        }
      });
   
    this.popupClose(e);
  }

  createNewPS() {
    this.focusInput();
    this.popups.profileCreatePopupOpened = true;
  }

 
  openProfilePopup(profile_statement_id) {
    const selectedProfileStatement = this.profileStatementList.find(obj => obj.id === profile_statement_id);
    this.seletedProfileStatementName = selectedProfileStatement.name;
    this.percentage_to_update = 0;
    this.playlist_duration_ps = 0;
    this.profileStatements = [
      {
        id: null,
        fp_id: null,
        cover: "1",
        ratio: "",
        note: "",
        smartFiltersPS: [
          {
            categorySelect: null,
            compare: [],
            compareSelect: null,
            field: null,
            arrFields: ["", ""],
            type: "str",
            isHidden: false,
          },
        ],
        availableTracks: 0,
        futureCoverage: 0,
        trackIDs: [],
        length_sec: 0,
      },
    ];

    this.profileStatementFields.id = profile_statement_id;
    this.focusInput();
    this.profile_statementService
      .getPSList(profile_statement_id)
      .subscribe((ps: any) => {
        if (ps.length > 0) {
          this.profileStatements = [];
          this.psSelects = [];
          this.percentage_to_update = ps[0].percentage_to_update;
          this.playlist_duration_ps = ps[0].playlist_duration;
        }
        let sf = [
          {
            categorySelect: null,
            compare: [],
            compareSelect: null,
            field: null,
            arrFields: ["", ""],
            type: "str",
            isHidden: false,
          },
        ];

        for (var i = 0; i < ps.length; i++) {
          // Smart filters add
          if (this.IsValidJSONString(ps[i].smart_filters)) {
            let smart_filters_parsed = JSON.parse(ps[i].smart_filters);
            if (smart_filters_parsed == null) {
              smart_filters_parsed = [];
            }
            if (smart_filters_parsed.length > 0) sf = [];
            for (var k = 0; k < smart_filters_parsed.length; k++) {
              sf.push({
                categorySelect: smart_filters_parsed[k].categorySelect,
                compare: smart_filters_parsed[k].compare,
                compareSelect: smart_filters_parsed[k].compareSelect,
                field: smart_filters_parsed[k].field,
                arrFields: smart_filters_parsed[k].arrFields,
                type: smart_filters_parsed[k].type,
                isHidden: smart_filters_parsed[k].isHidden,
              });
            }
          }

          var o = {
            id: ps[i].id,
            fp_id: ps[i].foundation_play_list_id,
            cover: ps[i].cover,
            ratio: ps[i].ratio,
            note: ps[i].note,
            smartFiltersPS: sf,
            availableTracks: ps[i].available_tracks,
            futureCoverage: ps[i].future_coverage,
            trackIDs: [],
            length_sec: 0,
          };
          this.profileStatements.push(o);
          var so = {
            catId: null,
            subcatId: null,
            fp_id: ps[i].foundation_play_list_id,
            fp_arr: [],
            dropOpened: false,
            title: "",
          };
          this.psSelects.push(so);
          this.setPSSelects(i);
        }
      });
    this.fp_categoriesService
      .getPlaylistsCategories("")
      .subscribe((cats: any) => {
        this.fpCategories = cats;
        this.popups.profileStatementOpened = true;
      });
    // }
    // });
    return false;
  }

  setPSSelects(index) {
    this.fpService
      .getPlaylistById(this.psSelects[index].fp_id)
      .subscribe((playlist: any) => {
        this.psSelects[index].title = playlist.name;
        this.psSelects[index].catId = playlist.parent_node.id;
        this.psSelects[index].subcatId = playlist.foundation_category_id;
        this.psSelectSubcategory(this.psSelects[index].subcatId, index);
      });
  }

  psSelectSubcategory(scid, index) {
    this.psSelects[index].subcatId = scid;
    this.fpService.getPlaylists(scid).subscribe((playlists: any) => {
      this.psSelects[index].fp_arr = playlists;
    });
  }

  IsValidJSONString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }

  getTotalCover() {
    var t = 0;
    for (var i = 0; i < this.profileStatements.length; i++) {
      var n = parseFloat(this.profileStatements[i].cover);
      if (!isNaN(n) && typeof n == "number") t += n;
    }
    return t;
  }

  getTotalRatio() {
    var t = 0;
    for (var i = 0; i < this.profileStatements.length; i++) {
      var n = parseInt(this.profileStatements[i].ratio);
      if (!isNaN(n) && typeof n == "number") t += n;
    }
    return t;
  }

  updateProfileStatementList(cid) {
    this.profileStatementList = [];
    this.profile_statementService
      .getProfileStatementList(cid)
      .subscribe((res: any) => {
        if (res.error != undefined) {
          // Do nothing
        } else {
          this.profileStatementList = res;
        }
      });
  }

  saveProfileStatement(e) {

    this.isLoad = true;
    var sum = this.profileStatements.reduce(
      (a, b) => a + (Number(b["ratio"]) || 0),
      0
    );
    if (sum > 100) {
      this.isLoad = false;
      this.alert.alertOpen("Sum of ratio exceeds 100%", 1);
      return false;
    }
    if (this.percentage_to_update == 0) {
      this.isLoad = false;
      this.alert.alertOpen(
        "Please enter percentage of playlist to update value",
        1
      );
      return false;
    }

    if (this.seletedProfileStatementName == "") {
      this.alert.alertOpen("Please enter the profile Statement name", 1);
      return false;
    }


    let totalMaxHours = 0;
    let totalLengthSec = 0;
    for (let index = 0; index < this.profileStatements.length; index++) {
      const element = this.profileStatements[index];
      if (element.trackIDs.length == 0) {
        this.isLoad = false;
        this.alert.alertOpen("Please load tracks from FP", 1);
        return false;
      }

      let maxHours =
        this.playlist_duration_ps *
        (this.percentage_to_update / 100) *
        Number(this.profileStatements[index].cover) *
        (Number(this.profileStatements[index].ratio) / 100);
      totalMaxHours += maxHours * 3600;
      totalLengthSec += this.profileStatements[index].length_sec;
    }
    console.log("totalMaxHours", Math.round(totalMaxHours));
    console.log("totalLengthSec", Math.round(totalLengthSec));
    if (Math.round(totalLengthSec) < Math.round(totalMaxHours)) {
      this.isLoad = false;
      this.alert.alertOpen("Not enough tracks. Please add more tracks!", 1);
      return false;
    }
   
    var ps_str = this.getPSString(this.profileStatements);
    this.profile_statementService
      .savePS(this.profileStatementFields.id, ps_str, null, false)
      .subscribe((ps: any) => {
        this.isLoad = false;
        if (ps.error != undefined) {
          this.alert.alertOpen(ps.error, 1);
        } else {
          this.updateProfileStatementListName(this.profileStatementFields.id);
          this.updateProfileStatementList(this.checkedChanelId.id)
          if(ps.result['duplicate_file_counter'] != undefined && ps.result['duplicate_file_counter'] > 0){
            let msg = ps.result['duplicate_file_counter'] + ' duplicate tracks in the selected profile statements. Due to the shortage of tracks, '+ ps.result['duplicate_file_counter'] + ' non-relavent tracks getting added from the selected FPs.';
              this.alert.alertOpen(msg,0,30);
          }
          this.alert.alertOpen("Profile statement saved", 0);

          this.popupClose(e);
        }
      });

  }

  getPSString(arr) {
    var str = "";
    for (var i = 0; i < arr.length; i++) {
      if (arr[i].fp_id != null) {
        if (i > 0) {
          str += "&";
        }
        str +=
          "fp_id[" +
          i +
          "]=" +
          arr[i].fp_id +
          "&cover[" +
          i +
          "]=" +
          arr[i].cover +
          "&rating[" +
          i +
          "]=" +
          arr[i].ratio +
          "&notes[" +
          i +
          "]=" +
          arr[i].note;
        if (arr[i].id != null) {
          str += "&id[" + i + "]=" + arr[i].id;
        } else {
          str += "&id[" + i + "]=undefined";
        }
        str += "&availableTracks[" + i + "]=" + arr[i].availableTracks;
        str += "&trackIDs[" + arr[i].fp_id + "]=" + arr[i].trackIDs;
        str += "&futureCoverage[" + i + "]=" + arr[i].futureCoverage;
        str +=
          "&smartFiltersPS[" + i + "]=" + JSON.stringify(arr[i].smartFiltersPS);
      }
    }
    str += "&percentageToUpdate=" + this.percentage_to_update;
    str += "&playlist_duration=" + this.playlist_duration_ps;
    return str;
  }

  psOpenDrop(index) {
    for (var i = 0; i < this.psSelects.length; i++) {
      if (index != i) this.psSelects[i].dropOpened = false;
    }
    this.psSelects[index].dropOpened = !this.psSelects[index].dropOpened;
  }
  psSelectPlaylist(pid, index, title) {
    this.psSelects[index].fp_id = pid;
    this.profileStatements[index].fp_id = pid;
    this.psSelects[index].title = title;
    this.psSelects[index].dropOpened = false;
    this.checkFPDublicates(index);
  }
  checkFPDublicates(index) {
    this.profileStatements[index].cover = "";
    this.profileStatements[index].ratio = "";
    this.profileStatements[index].note = "";
    if (this.profileStatements[index].id != null) {
      this.removeProfile(index, this.profileStatements[index].id, false);
    }
    this.profileStatements[index].id = null;
    for (var i = 0; i < this.profileStatements.length; i++) {
      if (
        index != i &&
        this.profileStatements[i].fp_id == this.profileStatements[index].fp_id
      ) {
        this.alert.alertOpen("You are selecting a duplicate playlist", 1);
        this.profileStatements[index].fp_id = null;
        this.psSelects[index].fp_id = null;
        this.psSelects[index].title = "Select Foundation playlist...";
        return false;
      }
    }
    return true;
  }
  removeProfile(index, pid, splice) {
    if (pid != null) {
      this.profile_statementService.deletePS(pid).subscribe((ps: any) => {
        if (ps.error != undefined) {
          this.alert.alertOpen(ps.error, 1);
        } else {
          if (splice) {
            this.splicePS(index);
          }
        }
      });
    } else {
      if (splice) {
        this.splicePS(index);
      }
    }
    return false;
  }
  splicePS(index) {
    this.profileStatements.splice(index, 1);
    this.psSelects.splice(index, 1);
  }
  psSelectCategory(cid, index) {
    this.psSelects[index].catId = cid;
    this.psSelects[index].fp_arr = [];
  }
  addFilterFieldPS(i) {
    var o = {
      categorySelect: null,
      compare: [],
      compareSelect: null,
      field: null,
      arrFields: ["", ""],
      type: "str",
      isHidden: false,
    };
    this.profileStatements[i].smartFiltersPS.push(o);
    console.log("this.profileStatements", this.profileStatements);
    return false;
  }
  removeFilterFieldPS(i, k) {
    var count = 1;
    this.profileStatements[i].smartFiltersPS.splice(k, count);
    if (this.profileStatements[i].smartFiltersPS.length < 1) {
      var o = {
        categorySelect: null,
        compare: [],
        compareSelect: null,
        field: null,
        arrFields: ["", ""],
        type: "str",
        isHidden: false,
      };
      this.profileStatements[i].smartFiltersPS[0] = o;
    }

    return false;
  }

  smartSearchPS(m, type = 0) {
    this.isLoad = true;
    var str = "";

    if (this.playlist_duration_ps == 0) {
      this.alert.alertOpen("Playlist duration can not be zero", 1);
      this.isLoad = false;
      return false;
    }
    if (this.profileStatements[m].fp_id == null) {
      this.alert.alertOpen("Please select foundation playlist lo continue", 1);
      this.isLoad = false;
      return false;
    }

    if (this.profileStatements[m].cover == "") {
      this.alert.alertOpen("Please select cover", 1);
      this.isLoad = false;
      return false;
    }
    if (this.profileStatements[m].ratio == "") {
      this.alert.alertOpen("Please select ratio", 1);
      this.isLoad = false;
      return false;
    }

    let add_to_cp_playlist = this.profileStatements[m].smartFiltersPS.find(
      (x) => x.categorySelect == "add_to_cp_playlist"
    );
    let cp_playlist_id = this.profileStatements[m].smartFiltersPS.find(
      (x) => x.categorySelect == "cp_playlist_id"
    );

    if (add_to_cp_playlist && !cp_playlist_id) {
      this.alert.alertOpen(
        "Please add the playlist name filter to proceed with this!",
        1
      );
      this.isLoad = false;
      return false;
    }
    // }

    localStorage.setItem("psSelect", JSON.stringify(this.profileStatements));

    this.smartService
      .getFilteredTracksCount(this.profileStatements[m])
      .subscribe((tracks: any) => {
        console.log("tracks", tracks);
        this.isLoad = false;
        if (tracks.result != "empty") {
          let maxHours =
            this.playlist_duration_ps *
            (this.percentage_to_update / 100) *
            Number(this.profileStatements[m].cover) *
            (Number(this.profileStatements[m].ratio) / 100);
          maxHours = Math.round(maxHours * 3600);
          console.log("maxHours", maxHours);

          let length_sec = 0;
          let trackCount = 0;
          let trackIDs = [];
          for (var i = 0; i < tracks.list.length; i++) {
            let el = tracks.list[i];
            length_sec += el.length_sec;
            trackCount++;
            trackIDs.push(el.id);
            if (length_sec > maxHours) break;
          }

          this.profileStatements[m].availableTracks = trackCount;
          this.profileStatements[m].trackIDs = trackIDs;
          this.profileStatements[m].futureCoverage = tracks.total - trackCount;
          this.profileStatements[m].length_sec = Math.round(length_sec);
        } else {
          this.profileStatements[m].availableTracks = 0;
          this.profileStatements[m].futureCoverage = 0;
          this.profileStatements[m].trackIDs = [];
          this.profileStatements[m].length_sec = 0;
        }

        if (type == 1) this.openConfirmPopup(0, 3);
        if (type == 2) this.openConfirmPopup(0, 6);
      });
  }

  changeSmartSelectPS(e, i, k) {
    for (var m = 0; m < this.defaultSmartFiltersPS.length; m++) {
      if (this.defaultSmartFiltersPS[m].field == e.target.value) {
        this.profileStatements[i].smartFiltersPS[k].compare =
          this.defaultSmartFiltersPS[m].сompare_str;
        this.profileStatements[i].smartFiltersPS[k].type =
          this.defaultSmartFiltersPS[m].type;
        /* Smart filter work - Start
        //console.log('e.target.value',e.target.value);
        // if(e.target.value == 'cp_playlist_id')
        //   this.addStaticFilterField('add_to_cp_playlist', index );
        // else
        //   this.removeStaticFilterField(index);
        Smart filter work - End */
      }
    }
  }

  addProfile() {
    var o = {
      id: null,
      fp_id: null,
      cover: "1",
      ratio: "",
      note: "",
      sCat: null,
      sSubcat: null,
      smartFiltersPS: [
        {
          categorySelect: null,
          compare: [],
          compareSelect: null,
          field: null,
          arrFields: ["", ""],
          type: "str",
          isHidden: false,
        },
      ],
      availableTracks: 0,
      futureCoverage: 0,
      trackIDs: [],
      length_sec: 0,
    };
    this.profileStatements.push(o);
    var so = {
      catId: null,
      subcatId: null,
      fp_id: null,
      fp_arr: [],
      dropOpened: false,
      title: "Select Foundation playlist...",
    };
    this.psSelects.push(so);
    return false;
  }

  profileStatus: any;
  profileActive = [];
  profileActiveStatus: any;
  profileDeleteStatus: any;
  profileModifiedStatus: any;
  openProfileHistoryPopup(e) {
    this.popupClose(e);

    this.profile_statementService
      .getPSHistory(this.profileStatementFields.id)
      .subscribe((history) => {
        this.profileStatus = history;
        this.profileActiveStatus = this.profileStatus.profileActive;
        this.profileDeleteStatus = this.profileStatus.profileDelete;
        this.profileModifiedStatus = this.profileStatus.profileModified;
        this.popups.profileHistoryopened = true;
      });
  }

  deleteProfileRaw(psrid) {
    if (psrid != null) {
      this.profile_statementService.deletePSR(psrid).subscribe((ps: any) => {
        if (ps.error != undefined) {
          this.alert.alertOpen(ps.error, 1);
        } else {
          this.updateProfileStatementList(this.checkedChanelId.id);
          this.alert.alertOpen("Profile statement deleted", 0);
        }
      });
    }
    return false;
  }

  getDgs() {
    this.dgService.getAllDGs().subscribe((dgs: any) => {
      this.dgs = dgs.list;
    });
  }

  openCopyDgPopup(e, profile_id) {
    this.dgCopyResults = [];
    this.buildResults = [];
    this.hawkResults = [];
    this.popupClose(e);
    this.dgFormFields.dg_id = null;
    this.dgFormFields.profile_id = profile_id;
    this.dgFormFields.master_id = null;
    this.showMastersSec = false;
    this.masters = [];
    this.popups.dgOpened = true;
    return false;
  }

  openCopyDgPopupChannels(e, profile_id) {
    this.dgCopyResults = [];
    this.buildResults = [];
    this.hawkResults = [];
    this.popupClose(e);
    this.dgChannelsFormFields.dg_id = null;
    this.dgChannelsFormFields.profile_id = profile_id;
    this.dgChannelsFormFields.channel_ids = [];
    this.dgChannels = [];
    this.popups.dgChannelsOpened = true;
    return false;
  }

  openUnlockPopup(e, profile_id, master_id) {
    this.unlockProfileID = profile_id;
    this.dgMasterID = master_id;

    this.popupClose(e);

    this.popups.unlockOpened = true;
    return false;
  }

  openSchUnlockPopup(e, schedule_id, master_id) {
    this.unlockScheduleID = schedule_id;
    this.dgMasterID = master_id;

    this.popupClose(e);

    this.popups.schUnlockOpened = true;
    return false;
  }

  copyDg(e, copyToChannel = false) {
    this.dgCopyResults = [];
    let conflict = [];
    if (copyToChannel) {
      if (this.dgChannelsFormFields.dg_id == null) {
        this.alert.alertOpen("Please select distribution group!", 1);
        return;
      }
      if (this.dgChannelsFormFields.channel_ids.length == 0) {
        this.alert.alertOpen("Please select channels to copy!", 1);
        return;
      }
    } else {
      if (this.dgFormFields.dg_id == null) {
        this.alert.alertOpen("Please select distribution group!", 1);
        return;
      }
      if (this.showMastersSec && this.dgFormFields.master_id == null) {
        this.alert.alertOpen("Please select a master profile to replace!", 1);
        return;
      }
    }
    this.hasConflicts = false;
    this.isLoad = true;
    let str = "";
    if (copyToChannel) {
      let channel_ids = this.dgChannelsFormFields.channel_ids.map((a) =>
        encodeURIComponent(a.item_id)
      );
      str += "dg_id=" + this.dgChannelsFormFields.dg_id;
      str += "&profile_id=" + this.dgChannelsFormFields.profile_id;
      str += "&channel_ids=" + channel_ids;
    } else {
      str += "dg_id=" + this.dgFormFields.dg_id;
      str += "&profile_id=" + this.dgFormFields.profile_id;
    }
    if (this.showMastersSec) {
      str += "&master_id=" + this.dgFormFields.master_id;
      this.dgService.replaceMaster(str).subscribe((res: any) => {
        this.isLoad = false;
        if (res.error != undefined) {
          this.alert.alertOpen(res.error, 1, 15);
        } else {
          for (let key in res.duplicate_error) {
            if (res.duplicate_error.hasOwnProperty(key)) {
              if (res.duplicate_error[key].error != undefined) {
                this.dgCopyResults.push({
                  channel: key,
                  message: "<br/>ERROR: <br/>" + res.duplicate_error[key].error,
                });
              }
              if (res.duplicate_error[key].keepandOverwrite != undefined) {
                this.dgCopyResults.push({
                  channel: key,
                  name: res.duplicate_error[key].schedule_conflict.name,
                  message: res.duplicate_error[key].keepandOverwrite,
                  begin_date:
                    res.duplicate_error[key].schedule_conflict.begin_date,
                  finish_date:
                    res.duplicate_error[key].schedule_conflict.finish_date,
                  master_begindate: res.duplicate_error[key].master_begindate,
                  master_finishdate: res.duplicate_error[key].master_finishdate,
                  child_schedule_id:
                    res.duplicate_error[key].schedule_conflict
                      .schedule_to_keep_or_overwrite,
                  master_schedule_id: res.duplicate_error[key].master_schedule,
                  profile_to_rename:
                    res.duplicate_error[key].schedule_conflict
                      .profile_to_rename,
                  keepOverwrite: true,
                  keepMessage: "",
                });
              }
            }
          }
          for (let key in res.returnMessage) {
            if (res.returnMessage.hasOwnProperty(key)) {
              if (res.returnMessage[key].error != undefined) {
                this.dgCopyResults.push({
                  channel: key,
                  message:
                    "Profile and Schedule copied to the channel. " +
                    "<br/>ERROR: <br/>" +
                    res.returnMessage[key].error,
                });
              } else if (
                res.returnMessage[key].schedule_conflict != undefined &&
                res.returnMessage[key].schedule_conflict
              ) {
                let conflict = [];
                for (let key1 in res.returnMessage[key].schedule_conflict) {
                  if (
                    res.returnMessage[key].schedule_conflict.hasOwnProperty(
                      key1
                    )
                  ) {
                    conflict.push({
                      schedule_id: key1,
                      schedule_details:
                        res.returnMessage[key].schedule_conflict[key1],
                      message: "",
                    });
                  }
                  this.hasConflicts = true;
                }
                console.log("conflict", conflict);
                this.dgCopyResults.push({
                  channel: key,
                  message: "Profile and Schedule copied to the channel.",
                  scheduleConflict: conflict,
                  scheduleConflictSourceID:
                    res.returnMessage[key].schedule_conflict_source_id,
                  master_begindate: res.returnMessage[key].master_begindate,
                  master_finishdate: res.returnMessage[key].master_finishdate,
                });
              } else {
                this.dgCopyResults.push({
                  channel: key,
                  message:
                    "The master profile replaced with the current selected profile.",
                });
              }
            }
          }
          this.updatePlaylists(0);
          this.updateSchedules(this.checkedChanelId.id);
        }
      });
    } else {
      this.dgService.copyDg(str).subscribe((res: any) => {
        this.isLoad = false;
        if (res.error != undefined) {
          this.alert.alertOpen(res.error, 1, 15);
        } else {
          for (let key in res.duplicate_error) {
            if (res.duplicate_error.hasOwnProperty(key)) {
              if (res.duplicate_error[key].error != undefined) {
                this.dgCopyResults.push({
                  channel: key,
                  message: "<br/>ERROR: <br/>" + res.duplicate_error[key].error,
                });
              }
              if (res.duplicate_error[key].keepandOverwrite != undefined) {
                this.dgCopyResults.push({
                  channel: key,
                  name: res.duplicate_error[key].schedule_conflict.name,
                  message: res.duplicate_error[key].keepandOverwrite,
                  begin_date:
                    res.duplicate_error[key].schedule_conflict.begin_date,
                  finish_date:
                    res.duplicate_error[key].schedule_conflict.finish_date,
                  master_begindate: res.duplicate_error[key].master_begindate,
                  master_finishdate: res.duplicate_error[key].master_finishdate,
                  child_schedule_id:
                    res.duplicate_error[key].schedule_conflict
                      .schedule_to_keep_or_overwrite,
                  master_schedule_id: res.duplicate_error[key].master_schedule,
                  profile_to_rename:
                    res.duplicate_error[key].schedule_conflict
                      .profile_to_rename,
                  keepOverwrite: true,
                  keepMessage: "",
                });
              }
            }
          }

          for (let key in res.returnMessage) {
            if (res.returnMessage.hasOwnProperty(key)) {
              if (res.returnMessage[key].error != undefined) {
                this.dgCopyResults.push({
                  channel: key,
                  message:
                    "Profile and Schedule was copied across the distribution group. " +
                    "<br/>ERROR: <br/>" +
                    res.returnMessage[key].error,
                });
              } else if (
                res.returnMessage[key].schedule_conflict != undefined &&
                res.returnMessage[key].schedule_conflict
              ) {
                let conflict = [];
                for (let key1 in res.returnMessage[key].schedule_conflict) {
                  if (
                    res.returnMessage[key].schedule_conflict.hasOwnProperty(
                      key1
                    )
                  ) {
                    conflict.push({
                      schedule_id: key1,
                      schedule_details:
                        res.returnMessage[key].schedule_conflict[key1],
                      message: "",
                    });
                  }
                  this.hasConflicts = true;
                }
                console.log("conflict", conflict);
                this.dgCopyResults.push({
                  channel: key,
                  message: "Data copied to the channel.",
                  scheduleConflict: conflict,
                  scheduleConflictSourceID:
                    res.returnMessage[key].schedule_conflict_source_id,
                  master_begindate: res.returnMessage[key].master_begindate,
                  master_finishdate: res.returnMessage[key].master_finishdate,
                });
              } else {
                this.dgCopyResults.push({
                  channel: key,
                  message:
                    "The profile and schedule was copied across the distribution group.",
                });
              }
            }
          }

          // Hided Mani July19 : CR18
          // for (let key in res.buildResults) {
          //   if (res.buildResults.hasOwnProperty(key)) {
          //     if(res.buildResults[key].error != undefined){
          //       this.buildResults.push({
          //         channel : key,
          //         message : res.buildResults[key].error
          //       });
          //     }
          //     else{
          //       this.buildResults.push({
          //         channel   : key,
          //         message : 'Build request created successfully!.'
          //       });
          //     }
          //   }
          // }

          // for (let key in res.hawkResults) {
          //   if (res.hawkResults.hasOwnProperty(key)) {
          //     if(res.hawkResults[key].error != undefined){
          //       this.hawkResults.push({
          //         channel : key,
          //         message : res.hawkResults[key].error
          //       });
          //     }
          //     else{
          //       this.hawkResults.push({
          //         channel   : key,
          //         message : 'Hawk request created successfully!.'
          //       });
          //     }
          //   }
          // }
          this.updatePlaylists(0);
          this.updateSchedules(this.checkedChanelId.id);
          console.log("this.dgCopyResults", this.dgCopyResults);
        }
      });
    }
    return false;
  }

  overwriteAllMasterSchedule() {
    for (let i = 0; i < this.dgCopyResults.length; i++) {
      const dgCopyResult = this.dgCopyResults[i];
      for (let j = 0; j < dgCopyResult.scheduleConflict.length; j++) {
        const scheduleConflict = dgCopyResult.scheduleConflict[j];
        let str =
          "scheduleConflictSourceID=" + dgCopyResult.scheduleConflictSourceID;
        str += "&schedule_id=" + scheduleConflict.schedule_id;
        this.isLoad = true;
        this.dgService.overwrite(str).subscribe((res: any) => {
          this.isLoad = false;
          if (res.error != undefined) {
            this.alert.alertOpen(res.error, 1, 15);
          } else {
            this.dgCopyResults[i].scheduleConflict[j].message =
              "Overwrite success!";
            this.alert.alertOpen(
              "The schedule overwrite process completed successfully!",
              0
            );
          }
        });
      }
    }
  }

  adjustAllMasterSchedule() {
    for (let i = 0; i < this.dgCopyResults.length; i++) {
      const dgCopyResult = this.dgCopyResults[i];
      for (let j = 0; j < dgCopyResult.scheduleConflict.length; j++) {
        const scheduleConflict = dgCopyResult.scheduleConflict[j];
        let str =
          "scheduleConflictSourceID=" + dgCopyResult.scheduleConflictSourceID;
        str += "&schedule_id=" + scheduleConflict.schedule_id;
        this.isLoad = true;
        this.dgService.adjust(str).subscribe((res: any) => {
          this.isLoad = false;
          if (res.error != undefined) {
            this.alert.alertOpen(res.error, 1, 15);
          } else {
            this.dgCopyResults[i].scheduleConflict[j].message =
              "Adjust success!";
            this.alert.alertOpen(
              "The schedule adjust process completed successfully!",
              0
            );
          }
        });
      }
    }
  }

  overwriteMasterSchedule(scheduleConflictSourceID, schedule_id, i, j) {
    let str = "scheduleConflictSourceID=" + scheduleConflictSourceID;
    str += "&schedule_id=" + schedule_id;
    this.dgService.overwrite(str).subscribe((res: any) => {
      if (res.error != undefined) {
        this.alert.alertOpen(res.error, 1, 15);
      } else {
        this.dgCopyResults[i].scheduleConflict[j].message =
          "Overwrite success!";
        // for (let key in res.buildResults) {
        //   if (res.buildResults.hasOwnProperty(key)) {
        //     if(res.buildResults[key].error != undefined){
        //       this.buildResults[i].message = res.buildResults[key].error;
        //     }
        //     else{
        //       this.buildResults[i].message = 'Build request created successfully!.';
        //     }
        //   }
        // }
        // for (let key in res.hawkResults) {
        //   if (res.hawkResults.hasOwnProperty(key)) {
        //     if(res.hawkResults[key].error != undefined){
        //       this.hawkResults[i].message = res.hawkResults[key].error;
        //     }
        //     else{
        //       this.hawkResults[i].message = 'Hawk request created successfully!.';
        //     }
        //   }
        // }
        this.alert.alertOpen(
          "The schedule overwrite process completed successfully!",
          0
        );
      }
    });
  }
  adjustMasterSchedule(scheduleConflictSourceID, schedule_id, i, j) {
    let str = "scheduleConflictSourceID=" + scheduleConflictSourceID;
    str += "&schedule_id=" + schedule_id;
    this.dgService.adjust(str).subscribe((res: any) => {
      if (res.error != undefined) {
        this.alert.alertOpen(res.error, 1, 15);
      } else {
        this.dgCopyResults[i].message =
          this.dgCopyResults[i].message + "<br/>" + res.result;
        this.dgCopyResults[i].scheduleConflict[j].message = "Adjust success!";
        // for (let key in res.buildResults) {
        //   if (res.buildResults.hasOwnProperty(key)) {
        //     if(res.buildResults[key].error != undefined){
        //       this.buildResults[i].message = res.buildResults[key].error;
        //     }
        //     else{
        //       this.buildResults[i].message = 'Build request created successfully!.';
        //     }
        //   }
        // }
        // for (let key in res.hawkResults) {
        //   if (res.hawkResults.hasOwnProperty(key)) {
        //     if(res.hawkResults[key].error != undefined){
        //       this.hawkResults[i].message = res.hawkResults[key].error;
        //     }
        //     else{
        //       this.hawkResults[i].message = 'Hawk request created successfully!.';
        //     }
        //   }
        // }
        this.alert.alertOpen(
          "The schedule adjust process completed successfully!",
          0
        );
      }
    });
  }
  unlockProfile(e) {
    if (this.unlockProfileID == 0 || this.unlockProfileID == null) {
      this.alert.alertOpen("Invalid profile ID selected!", 1);
      return false;
    }
    this.isLoad = true;
    this.dgService.unlockProfile(this.unlockProfileID).subscribe((res: any) => {
      this.isLoad = false;
      if (res.error != undefined) {
        this.alert.alertOpen(res.error, 1, 15);
      } else {
        this.popupClose(e);
        this.alert.alertOpen("Profile successfully unlocked", 0);

        this.updatePlaylists(0);
      }
    });
  }

  unlockSchedule(e) {
    if (this.unlockScheduleID == 0 || this.unlockScheduleID == null) {
      this.alert.alertOpen("Invalid Schedule ID selected!", 1);
      return false;
    }
    this.isLoad = true;
    this.dgService
      .unlockSchedule(this.unlockScheduleID)
      .subscribe((res: any) => {
        this.isLoad = false;
        if (res.error != undefined) {
          this.alert.alertOpen(res.error, 1, 15);
        } else {
          this.popupClose(e);
          this.alert.alertOpen("Schedule successfully unlocked", 0);
          this.updateSchedules(this.checkedChanelId.id);
        }
      });
  }

  viewMasterPlaylist(e) {
    if (this.dgMasterID == 0 || this.dgMasterID == null) {
      this.alert.alertOpen("Invalid master profile ID selected!", 1);
      return false;
    }
    this.isLoad = true;
    this.playlistServ
      .getPlaylistById(this.dgMasterID)
      .subscribe((playlist: any) => {
        this.isLoad = false;
        let navigationExtras: NavigationExtras = {
          queryParams: {
            playlistID: playlist.id,
            pcat: playlist.cp_category_id,
            uid: playlist.client_id,
          },
        };
        this.router.navigate(["manage-client-playlists"], navigationExtras);
      });
  }

  // viewMasterSchedule(e){
  //   let navigationExtras: NavigationExtras = {
  //     queryParams: {
  //       'scheduleID': id,
  //       'cid': this.checkedChanelId.id,
  //       'uid': this.checkedUserId.id
  //     }
  //   };
  //   this.router.navigate(['manage-schedule'], navigationExtras);
  // }

  syncToDg(master_profile_id) {
    this.dgCopyResults = [];
    this.buildResults = [];
    this.hawkResults = [];
    if (master_profile_id == null) {
      this.alert.alertOpen("Error! Invalid master profile ID", 1);
      return;
    }
    this.hasConflicts = false;
    this.isLoad = true;

    this.dgService.sync(master_profile_id).subscribe((res: any) => {
      this.isLoad = false;
      // this.popups.dgSyncOpened = true;
      if (res.error != undefined) {
        this.alert.alertOpen(res.error, 1, 15);
      } else {
        this.alert.alertOpen("Changes updated to the distribution group", 0);
        this.updateSchedules(this.checkedChanelId.id);
        this.updatePlaylists(0);
      }
      //10/10/2023
      //Hide Abhimanyu CR10.1
      // for (let key in res.duplicate_error) {
      //   if (res.duplicate_error.hasOwnProperty(key)) {
      //     if (res.duplicate_error[key].error != undefined) {
      //       this.dgCopyResults.push({
      //         channel: key,
      //         message: "<br/>ERROR: <br/>" + res.duplicate_error[key].error,
      //       });
      //     }
      //     if (res.duplicate_error[key].keepandOverwrite != undefined) {
      //       this.dgCopyResults.push({
      //         channel: key,
      //         name: res.duplicate_error[key].schedule_conflict.name,
      //         message: res.duplicate_error[key].keepandOverwrite,
      //         begin_date: res.duplicate_error[key].schedule_conflict.begin_date,
      //         finish_date:
      //           res.duplicate_error[key].schedule_conflict.finish_date,
      //         master_begindate: res.duplicate_error[key].master_begindate,
      //         master_finishdate: res.duplicate_error[key].master_finishdate,
      //         child_schedule_id:
      //           res.duplicate_error[key].schedule_conflict
      //             .schedule_to_keep_or_overwrite,
      //         master_schedule_id: res.duplicate_error[key].master_schedule,
      //         profile_to_rename:
      //           res.duplicate_error[key].schedule_conflict.profile_to_rename,
      //         keepOverwrite: true,
      //         keepMessage: "",
      //       });
      //     }
      //   }
      // }
      // for (let key in res.returnMessage) {
      //   if (res.returnMessage.hasOwnProperty(key)) {
      //     if (res.returnMessage[key].error != undefined) {
      //       this.dgCopyResults.push({
      //         channel: key,
      //         message:
      //           "Data synced to the channel. " +
      //           "<br/>ERROR: <br/>" +
      //           res.returnMessage[key].error,
      //       });
      //     } else if (
      //       res.returnMessage[key].schedule_conflict != undefined &&
      //       res.returnMessage[key].schedule_conflict
      //     ) {
      //       let conflict = [];
      //       for (let key1 in res.returnMessage[key].schedule_conflict) {
      //         if (
      //           res.returnMessage[key].schedule_conflict.hasOwnProperty(key1)
      //         ) {
      //           conflict.push({
      //             schedule_id: key1,
      //             schedule_details:
      //               res.returnMessage[key].schedule_conflict[key1],
      //             message: "",
      //           });
      //           this.hasConflicts = true;
      //         }
      //       }

      //       this.dgCopyResults.push({
      //         channel: key,
      //         message: "Data synced to the channel.",
      //         scheduleConflict: conflict,
      //         scheduleConflictSourceID:
      //           res.returnMessage[key].schedule_conflict_source_id,
      //         master_begindate: res.returnMessage[key].master_begindate,
      //         master_finishdate: res.returnMessage[key].master_finishdate,
      //       });
      //     } else {
      //       this.dgCopyResults.push({
      //         channel: key,
      //         message: "Changes updated to the distribution group.",
      //       });
      //     }
      //   }
      // }

      // Hided Mani July19 : CR18
      // for (let key in res.buildResults) {
      //   if (res.buildResults.hasOwnProperty(key)) {
      //     if(res.buildResults[key].error != undefined){
      //       this.buildResults.push({
      //         channel : key,
      //         message : res.buildResults[key].error
      //       });
      //     }
      //     else{
      //       this.buildResults.push({
      //         channel   : key,
      //         message : 'Build request created successfully!.'
      //       });
      //     }
      //   }
      // }

      // for (let key in res.hawkResults) {
      //   if (res.hawkResults.hasOwnProperty(key)) {
      //     if(res.hawkResults[key].error != undefined){
      //       this.hawkResults.push({
      //         channel : key,
      //         message : res.hawkResults[key].error
      //       });
      //     }
      //     else{
      //       this.hawkResults.push({
      //         channel   : key,
      //         message : 'Hawk request created successfully!.'
      //       });
      //     }
      //   }
      // }
    });
    return false;
  }

  masters = [];
  showMastersSec = false;
  showMasters() {
    this.showMastersSec = true;
    if (this.dgFormFields.dg_id == null) {
      this.showMastersSec = false;
      this.alert.alertOpen("Please select distribution group!", 1);
      return false;
    }
    this.masters = this.playlists.filter(
      (x) => x.dg_id == this.dgFormFields.dg_id && x.profile_type == 1
    );
    if (this.masters.length == 0) {
      this.showMastersSec = false;
      this.alert.alertOpen("No masters found!", 1);
    }
  }
  onChangeDG() {
    this.masters = [];
    this.showMastersSec = false;
  }

  dgChannels = [];
  onChangeDGChannels() {
    this.dgChannels = [];
    if (this.dgChannelsFormFields.dg_id == null) {
      this.alert.alertOpen("Please select distribution group!", 1);
      return false;
    }
    console.log("this.dgs", this.dgs);
    console.log(
      "this.dgChannelsFormFields.dg_id ",
      this.dgChannelsFormFields.dg_id
    );
    this.dgChannels = this.dgs
      .filter((x) => x.id == this.dgChannelsFormFields.dg_id)
      .map(function (obj) {
        return obj.dg_entities;
      })[0];

    let dgChannels = [];
    for (let key in this.dgChannels) {
      if (this.dgChannels.hasOwnProperty(key)) {
        dgChannels.push({
          item_id: this.dgChannels[key].channel.id,
          item_text: this.dgChannels[key].channel.name,
        });
      }
    }
    this.dgChannels = dgChannels;
    console.log("this.dgChannels", this.dgChannels);
    if (this.dgChannels.length == 0) {
      this.alert.alertOpen("No channels found!", 1);
    }
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  goToSite(e, client_id, site_id) {
    e.preventDefault();
    this.checkedUserId.id = client_id;
    this.checkedSiteId.id = site_id;
    if (this.checkedUserId.id) {
      let user = this.usersList.find((x) => x.id == this.checkedUserId.id);
      this.checkedUserId.name = user.first_name;
    }
    this.getSites(client_id, this.checkedUserId.name, 0);
    this.showChanels("", site_id, "", 0);
    this.searchPopupOpened = false;
    return;
  }
  goToChannel(e, client_id, site_id, channel_id) {
    console.log(e, client_id, site_id, channel_id);
    e.preventDefault();
    this.checkedUserId.id = client_id;
    this.checkedSiteId.id = site_id;
    this.checkedChanelId.id = channel_id;
    if (this.checkedUserId.id) {
      let user = this.usersList.find((x) => x.id == this.checkedUserId.id);
      this.checkedUserId.name = user.first_name;
    }
    this.getSites(client_id, this.checkedUserId.name, 0);
    this.showChanels("", site_id, "", 0);
    this.showPlaylists("", channel_id, "", 0);
    this.searchPopupOpened = false;
    return;
  }

  scrollToElementUserList(id) {
    // this.el.nativeElement.querySelector('.user-list').scrollIntoView();
    document.getElementById("user-list").scrollIntoView();
    // console.log('user-list-'+id);
    // var elem = document.getElementById('user-list'+id);
    // console.log('elem',elem);
    // console.log('typeof elem',typeof elem);
    // if(typeof elem !== null && elem !== undefined ) {
    //   elem.scrollIntoView();
    // }
  }

  keepschule_as_same(
    master_schedule_id,
    child_schedule_id,
    profile_to_rename,
    i
  ) {
    let str = "masterschedule_id=" + master_schedule_id;
    str += "&child_schedule_id=" + child_schedule_id;
    str += "&profile_to_rename=" + profile_to_rename;
    this.dgService.keep_as_same(str).subscribe((res: any) => {
      if (res.result != undefined) {
        this.alert.alertOpen(res.result, 0);
        this.dgCopyResults[i].keepMessage = res.result;
      }
    });
  }

  overwriteifsamenameprofile(
    master_schedule_id,
    child_schedule_id,
    profile_to_rename,
    i
  ) {
    let str = "masterschedule_id=" + master_schedule_id;
    str += "&child_schedule_id=" + child_schedule_id;
    str += "&profile_to_rename=" + profile_to_rename;
    this.dgService.overwritesamename(str).subscribe((res: any) => {
      if (res.result != undefined) {
        this.alert.alertOpen(res.result, 0);
        this.dgCopyResults[i].keepMessage = res.result;
      }
    });
  }

  removeMaster(e: any, id) {
    this.playlistServ.removeMaster(id).subscribe((res: any) => {
      if (res.success) {
        this.alert.alertOpen(res.success, 0);
      }
    });
    this.updatePlaylists(0);
    e.preventDefault();
  }

  setOrderTC(order: any) {
    if (this.orderBy == order) {
      if (this.order == "DESC") this.order = "ASC";
      else this.order = "DESC";
    } else {
      this.orderBy = order;
    }
      this.playlists.sort((a, b) => (a.order > b.order ? 1 : -1));
  }

 
  statusChangeCheck() {
    this.currentIndex++;
    if (this.currentIndex >= this.statusShowFilter.length) {
      this.currentIndex = 0; // Reset to the beginning of the array
    }
    this.playlistStatusheaderFilter = this.statusShowFilter[this.currentIndex];
    this.playlistServ
      .getPlaylistsByStatus(this.checkedChanelId.id, this.currentIndex)
      .subscribe((playlists: any) => {
        const playlistArray = Object.values(playlists);
        this.playlists = playlistArray;
      });
  }

 
//Batch select and delete 
  selectedPlaylists: any[] = [];

  isSelected(playlist: any): boolean {
    return this.selectedPlaylists.includes(playlist);
  }

  toggleSelection(playlist: any): void {
    if (this.isSelected(playlist)) {
      // Playlist is already selected, so deselect it and remove from the array
      const index = this.selectedPlaylists.indexOf(playlist);
      if (index !== -1) {
        this.selectedPlaylists.splice(index, 1);
      }
    } else {
      // Playlist is not selected, so select it and add to the array
      this.selectedPlaylists.push(playlist);
    }
  }

  

  batchSelectAndDelete() {
    var channel = [];
    if (this.selectedPlaylists.length > 0) {
      var text =
        "Do you want delete " + this.selectedPlaylists.length + " playlists?";
      this.confirmAll.confirmOpen(text);
      return false;
    } else {
      this.alert.alertOpen("Please select any one playlist", 1);
    }
  }



  selectAllPlaylists(checkboxValue: boolean) {
    if (checkboxValue) {
      // If the master checkbox is checked, select all playlists
      this.selectedPlaylists = this.playlists.map(playlist => playlist.id);
    } else {
      // If the master checkbox is unchecked, deselect all playlists
      this.selectedPlaylists = [];
    }
  }
  

  isAllConfirm(e) {
    if (e) {
      for (const list of this.selectedPlaylists) {
        console.log(list.id);
        this.playlistServ.deletePlaylist(list.id).subscribe((playlistDel) => {
          if(playlistDel['error']){
            this.alert.alertOpen(playlistDel['error'], 0);
          }
        });
      }
      this.alert.alertOpen("Playlist deleted", 0);
      this.updatePlaylists(0);
      this.selectedPlaylists=[];
    }
  }

  archivePlaylists(cid){
    this.playlistServ.getPlaylistsArchive(this.checkedChanelId.id).subscribe((playlists) => {
      this.archivePlaylist = playlists;
    });
  }

  tooltiptextForDot="";

  getBackgroundColorForFinishDate(finishDate: string): string {
    const finishDateValue = new Date(finishDate);
    const today = new Date();
  
    // Calculate the difference in milliseconds
    const timeDifference = finishDateValue.getTime() - today.getTime();
    const thirtyDaysInMilliseconds = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
  
    if (timeDifference > 0 && timeDifference <= thirtyDaysInMilliseconds) {
      const daysRemaining = Math.ceil(timeDifference / (24 * 60 * 60 * 1000)); // Calculate days remaining
      this.tooltiptextForDot = "Out of date within the next "+daysRemaining+" days";
      return '#FDDA0D'; // Yellow
    } else if (timeDifference <= 0) {
      this.tooltiptextForDot = "No longer in date";
      return '#C70039'; // Red
    }

  }


  playlistandsch=false;
  playlistandprofileStatement(e, form: NgForm){
    this.playlistUpdate(e,form);
    if(this.playlistFields.profileStatementID==null){
      this.profileStatementFields.name=this.playlistFields.name;
      this.profileUpdate(e,form,1); 
    } 
    setTimeout(() => {
      this.openSchedulePopup(e, 1);
    }, 3000);

  }

  playlistSchduleCreate(e:any){
   this.openPlaylistPopup(e,null);
    this.playlistandsch=true;
  }

  updateProfileStatementListName(id){
    this.profile_statementService.updatepsName(id,this.seletedProfileStatementName).subscribe((str:any)=>{
      if(str['error']){
        this.alert.alertOpen(str['error'], 0);
      }
    })
  }

  checkOnDemandSchedule(schedule: any): boolean {
   
    const beginDate = new Date(schedule.begin_date);
    const beginHours = String(beginDate.getHours()).padStart(2, '0');
    const beginMinutes = String(beginDate.getMinutes()).padStart(2, '0');
  
    const finishDate = new Date(schedule.finish_date);
    const finishHours = String(finishDate.getHours()).padStart(2, '0');
    const finishMinutes = String(finishDate.getMinutes()).padStart(2, '0');
  
    return beginHours === '00' &&
           beginMinutes === '00' &&
           finishHours === '00' &&
           finishMinutes === '00';
  }

    handleSave(event: any, updatePlaylist: any): void {
      if (!this.playlistandsch && !this.overlayplaylistansSch) {
        console.log("simple")
        this.playlistUpdate(event, updatePlaylist);
      } else if (this.playlistandsch) {
        console.log("intersub")
        this.playlistandprofileStatement(event, updatePlaylist);
      } else if (this.overlayplaylistansSch) {
        console.log("overlay")
        updatePlaylist.form.value.status=0 // SET Live playlist 
        this.OoverlayPlaylistandSch(event, updatePlaylist);
      }
    }

  openOverlaySchedulePopup=false;
  overlayplaylistansSch=false;
  overlayProfileandSCh(e:any){
    this.openPlaylistPopup(e,null);
    this.overlayplaylistansSch=true;
    this.caseOnlyifOverlayProfile=true;
  }

  OoverlayPlaylistandSch(e, form: NgForm){
    if(form.value.name){
      form.value.name=form.value.name + " INTERSUB";
    }else{
      this.alert.alertOpen("Playlist name is required", 1);
    }
    this.playlistUpdate(e,form);
    setTimeout(() => {
        this.openSchedulePopup(e, 2);
  }, 3000);
}

checkScheduleDate(){
    
  this.scheduleValidationoverlay.begin_time = true;
  this.scheduleValidationoverlay.finish_time = true;
  this.scheduleValidationoverlay.duration = true;
  if(this.schedule.begin_time.hours == ''){
    this.alert.alertOpen('Please select start time',1);
    this.scheduleValidationoverlay.begin_time = false;
    return false;
  }
  if(this.schedule.finish_time.hours == ''){
    this.alert.alertOpen('Please select finish time',1);
    this.scheduleValidationoverlay.finish_time = false;
    return false;
  }
  
  if(this.overlayschedule.onOverlay && this.overlayschedule.frequency_range.length > 0){
    for( var i = 0; i < this.overlayschedule.frequency_range.length; i++ ){
      
      if(this.overlayschedule.frequency_range[i].startTime == null || this.overlayschedule.frequency_range[i].endTime == null) {
        this.alert.alertOpen('Frequency range - time can not be empty!',1);
        return false; 
      }
      if(this.overlayschedule.frequency_range[i].frequency == null) {
        this.alert.alertOpen('Frequency range - frequency can not be empty!',1);
        return false; 
      }
    }    
  }
  
  if(this.overlayschedule.finish_date.year == undefined || this.overlayschedule.finish_date.year == '' || this.overlayschedule.finish_date.year == null){
    this.openConfirmPopup(null,0);
  }
  else{
    if(this.overlayschedule.finish_date.month == undefined || this.overlayschedule.finish_date.month == '' || this.overlayschedule.finish_date.month == null){
      this.alert.alertOpen('Please select outdate month!',1);
      return false; 
    }
    if(this.overlayschedule.finish_date.day == undefined || this.overlayschedule.finish_date.day == '' || this.overlayschedule.finish_date.day == null){
      this.alert.alertOpen('Please select outdate day!',1);
      return false; 
    }
    this.createScheduleoverlay();
  }
}
onOverlayClick(e){
  this.overlayschedule.onOverlay = e.target.unchecked;
}
createScheduleoverlay(){
  if( this.overlayschedule.playlistId != null && this.overlayschedule.name != '' ){
    var str = this.createScheduleStroverlay(this.overlayschedule);
    this.isLoad = true;
    if(this.overlayschedule.id != null){
      this.scheduleService.createSchedule(this.schedule.channelId,str).subscribe((sch:any)=>{
        if(sch.message==''){
          let message = "Overlay Schedule created"
          this.alert.alertOpen(message,0);
        }
        if(sch.error!=undefined){
          this.alert.alertOpen(sch.error,1);
        }
        this.isLoad = false;
      });
    }
  }
  else{
    this.alert.alertOpen( "Please select playlist name and schedule name" ,1);
  }
  this.updatePlaylists(2);
  this.updateSchedules(this.checkedChanelId.id);
  this.popups.openOverlaySchedulePopup=false;
  this.caseOnlyifOverlayProfile=false;
  this.overlayplaylistansSch=false;

}


createScheduleStroverlay(sch){
  var str = 'name=' + sch.name + '&cp_playlist_id=' + sch.playlistId 
  if(sch.begin_date.year == ""||sch.begin_date.year == null||sch.begin_date.year==undefined)
    sch.begin_date.year = '1899';
  if(sch.begin_date.month == ""||sch.begin_date.month == null||sch.begin_date.month==undefined)
    sch.begin_date.month = '01';
  if(sch.begin_date.day == ""||sch.begin_date.day == null||sch.begin_date.day==undefined)
    sch.begin_date.day = '01';
  str += '&begin_date=' + sch.begin_date.year+'-'+sch.begin_date.month+'-'+sch.begin_date.day;
  
  if(sch.finish_date.year == ""||sch.finish_date.year == null||sch.finish_date.year==undefined)
    str += '&finish_date=' + this.parseDate(sch.finish_date.year);
  else{
    
    str += '&finish_date=' + sch.finish_date.year+'-'+sch.finish_date.month+'-'+sch.finish_date.day; 
  }
    
  str += '&begin_time=' + sch.begin_time.hours+':'+sch.begin_time.minutes + '&finish_time=' + sch.finish_time.hours+':'+sch.finish_time.minutes
  if(sch.recurrence == '0'){
    str += '&recurrence_flag=' + sch.recurrence_fl;
    str += '&recurrence_every=1&recurrence_type=monthly';
  }
  else{
    str += '&recurrence_flag=' + sch.recurrence_fl;
    if(sch.recurrence.radio_value == '1'){
      str += '&recurrence_type=daily&recurrence_every=' + sch.recurrence.daily.days;
    }
    else if(sch.recurrence.radio_value == '2'){
      str += '&recurrence_type=weekly&recurrence_every=' + sch.recurrence.weekly.week;
      if(sch.recurrence.weekly.days != 'null')
        str += '&recurrence_dow='+sch.recurrence.weekly.days;
      
    }
    else if(sch.recurrence.radio_value == '3'){
      str += '&recurrence_type=monthly&recurrence_every=' + sch.recurrence.monthly.month;
      if(sch.recurrence.monthly.monthSelection == '1'){
        str += '&recurrence_days=';
        var daySet = false;
        for( var i = 0; i < sch.recurrence.monthly.days.length; i++ ){
          if(sch.recurrence.monthly.days[i].value){
            if(daySet)
              str += ',';
            str += sch.recurrence.monthly.days[i].num;
            daySet = true;
          }
        }
      }
      else if(sch.recurrence.monthly.monthSelection == '2'){
        str += '&recurrence_additional_rule_1='+ sch.recurrence.monthly.number + '&recurrence_additional_rule_2=' + sch.recurrence.monthly.type;
      }
    }
    else if(sch.recurrence.radio_value == '4'){
      str += '&recurrence_type=yearly&recurrence_every=' + sch.recurrence.yearly.year;
      str += '&month_s=';
      var monthSet = false;
      for( var i = 0; i < sch.recurrence.yearly.monthes.length; i++ ){
        if(sch.recurrence.yearly.monthes[i]){
          if(monthSet)
            str += ',';
          str += i+1;
          monthSet = true;
        }
      }
      if(sch.recurrence.yearly.monthSelection){
        str += '&recurrence_additional_rule_1='+ sch.recurrence.yearly.number + '&recurrence_additional_rule_2=' + sch.recurrence.yearly.type;
      }
    }
  }
  str += '&mastering=' + sch.mastering_fl;
  if(sch.mastering_fl == '1'){
    str += '&mastering_switch=' + sch.mastering.m_switch;
    if(sch.mastering.m_switch == '0'){
      str += '&mastering_days=' + sch.mastering.days + '&mastering_months=' + sch.mastering.months;
    }
    else{
      str += '&mastering_every_week_day=' + sch.mastering.every_week_day + '&mastering_recurrence_dow=' + sch.mastering.recurrence_dow + '&mastering_months=' + sch.mastering.months_2;
    }
  }
  str += '&rnd_order=' + sch.random + '&clash_distance=' + sch.clash_distance
  //+'&override_duration=' + sch.override_duration 
  + '&override_duration_unit=' + sch.override_duration.hours+sch.override_duration.minutes + '&background_color=' + sch.bgColor + '&text_color=' + sch.txtColor;

  if(sch.onOverlay)
   for( var i = 0; i < sch.frequency_range.length; i++ ){
    if(sch.frequency_range[i].id != null){
      str += '&frequency_range_id[' + i + ']=' + sch.frequency_range[i].id;
    }
    else{
      str += '&frequency_range_id[' + i + ']=undefined';
    }
  //   //if(sch.frequency_range[i].fp_id!=null){
  //     if(i>0){
  //       str+='&'
  //     }
   // str += '&startDate[' + i + ']=' + this.parseDate(sch.frequency_range[i].startDate.formatted) + '&endDate['+i+']=' + this.parseDate(sch.frequency_range[i].endDate.formatted);
   str += '&startDate[' + i + ']=' + sch.frequency_range[i].startDate.year+'-'+sch.frequency_range[i].startDate.month+'-'+sch.frequency_range[i].startDate.day + '&endDate['+i+']=' + sch.frequency_range[i].endDate.year+'-'+sch.frequency_range[i].endDate.month+'-'+sch.frequency_range[i].endDate.day;
    str += '&startTime['+i+']=' + sch.frequency_range[i].startTime.hours+':'+sch.frequency_range[i].startTime.minutes + '&endTime['+i+']=' + sch.frequency_range[i].endTime.hours+':'+sch.frequency_range[i].endTime.minutes
    str +='&frequency[' + i + ']=' + sch.frequency_range[i].frequency;
    //}
  }
  str += '&overlay=' + sch.onOverlay;
  return str;
}

  profileStatementUpdate(playlistid:any){
    this.profile_statementService
      .profileStatmentPlaylistUpdate(playlistid,this.playlistFields.profileStatementID)
      .subscribe((res: any) => {
        if(res['error']){
          this.alert.alertOpen(res['error'], 1);
        }
      });

  }

  setFrequencyChristmas(){
    this.overlayschedule.frequency_range = [];
    const christmasData = [
      {
        startDate: { year: 'YYYY', month: '12', day: '01' },
        endDate: { year: 'YYYY', month: '12', day: '10' },
        startTime: { hours: '07', minutes: '00' },
        endTime: { hours: '00', minutes: '00' },
        frequency: 4
      },
      {
        startDate: { year: 'YYYY', month: '12', day: '10' },
        endDate: { year: 'YYYY', month: '12', day: '17' },
        startTime: { hours: '07', minutes: '00' },
        endTime: { hours: '00', minutes: '00' },
        frequency: 2
      },
      {
        startDate: { year: 'YYYY', month: '12', day: '17' },
        endDate: { year: 'YYYY', month: '12', day: '26' },
        startTime: { hours: '07', minutes: '00' },
        endTime: { hours: '00', minutes: '00' },
        frequency: 1
      }
    ];

    this.overlayschedule.frequency_range = (this.overlayschedule.frequency_range as any[]).concat(christmasData);
  }
  }
  
 

