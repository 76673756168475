<div class="ph_block">
  <app-page-header [pageTitle]="'Create, edit and manage your playlists'"></app-page-header>
  <div class="top_box">
    <div class="search_box">
      <form method="get" #searchForm="ngForm">
        <input type="text" name="search" (blur)="checkSearch($event)" (focus)="focusInput()" value="{{ searchStr }}"
          placeholder="Track search" class="searchInput" [(ngModel)]="searchStr" />
        <!-- (keyup)="searchPress($event)"  -->
        <button type="submit" class="searchBtn" (click)="search($event)"></button>
        <a href="#" class="clear_search" (click)="clearSearch($event)"></a>
      </form>
    </div>
    <!-- <button (click)="searchUidPopUpOpen()">Search UID</button> -->
    <app-onboard-tracks *ngIf="
        current_user != null &&
        (current_user.type == 'Technician' || 
          current_user.type == 'Consultant' ||
          current_user.type == 'Administrator')
      "></app-onboard-tracks>
    <button (click)="openFilterPopup()" title="Filter Tracks">
      Smart filter
    </button>
    <button (click)="openBulkPopup()" *ngIf="
        current_user != null &&
        (current_user.type == 'Technician' ||
          current_user.type == 'Consultant' ||
          current_user.type == 'Administrator')
      " title="Bulk Updates on Tracks Details">
      Bulk updates
    </button>
    <app-import-fp *ngIf="
        current_user != null &&
        (current_user.type == 'Technician' ||
          current_user.type == 'Consultant' ||
          current_user.type == 'Administrator')
      "></app-import-fp>
    <button (click)="openMCPopup()" title="MC Tags">MC tags</button>
    <button *ngIf="current_user != null && [74,82,90,94,110,119,132,166].includes(current_user.id)"  title="Remove selected and Build & Hawk" (click)="openchannelPopup()">
      Remove selected and Build & Hawk
    </button>
    <!-- <button title="Remove selected and Auto Build&Hawk" (click)="openchannelPopup()">
      Remove selected and Auto Build&Hawk
    </button> -->
    <div class="tracks_count">
      Displaying {{ tracksCount.display }}/{{ tracksCount.total }} Tracks
    </div>
  </div>
  <div class="tracks_list_block table_wrap">
    <div class="table_titles">
      <div class="order" (click)="setOrder('title')"
        [ngClass]="{active: tracksOrder.orderBy=='title',asc:tracksOrder.order=='ASC'}">Title</div>
      <div class="order" (click)="setOrder('album')"
        [ngClass]="{active: tracksOrder.orderBy=='album',asc:tracksOrder.order=='ASC'}">Album</div>
      <div class="order" (click)="setOrder('artist')"
        [ngClass]="{active: tracksOrder.orderBy=='artist',asc:tracksOrder.order=='ASC'}">Album Artist</div>
      <div class="order" (click)="setOrder('catname')"
        [ngClass]="{active: tracksOrder.orderBy=='catname',asc:tracksOrder.order=='ASC'}">Category</div>
      <div class="order" (click)="setOrder('year')"
        [ngClass]="{active: tracksOrder.orderBy=='year',asc:tracksOrder.order=='ASC'}">Year</div>
      <div class="order" (click)="setOrder('length_time')"
        [ngClass]="{active: tracksOrder.orderBy=='length_time',asc:tracksOrder.order=='ASC'}">Length</div>
      <div class="order" (click)="setOrder('mc_tags')"
        [ngClass]="{active: tracksOrder.orderBy=='mc_tags',asc:tracksOrder.order=='ASC'}">MC-Tags</div>
      <div class="order" (click)="setOrder('genre')"
        [ngClass]="{active: tracksOrder.orderBy=='genre',asc:tracksOrder.order=='ASC'}">Project</div>
      <div class="order" (click)="setOrder('mood')"
        [ngClass]="{active: tracksOrder.orderBy=='mood',asc:tracksOrder.order=='ASC'}">Mood</div>
      <div class="order" (click)="setOrder('full_name_created')"
        [ngClass]="{active: tracksOrder.orderBy=='full_name_created',asc:tracksOrder.order=='ASC'}">Uploaded by</div>
      <div class="order" (click)="setOrder('full_name_edited')"
        [ngClass]="{active: tracksOrder.orderBy=='full_name_edited',asc:tracksOrder.order=='ASC'}">Last Modified by
      </div>
      <div class="order" (click)="setOrder('bpm')"
        [ngClass]="{active: tracksOrder.orderBy=='bpm',asc:tracksOrder.order=='ASC'}">BPM</div>
      <div class="order" (click)="setOrder('add_date')"
        [ngClass]="{active: tracksOrder.orderBy=='add_date',asc:tracksOrder.order=='ASC'}">Date added</div>
      <div>Actions</div>
    </div>
    <div class="table_content" *ngIf="tracks.length > 0" infinite-scroll [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="300" [scrollWindow]="false" (scrolled)="onScroll($event)">
      <div class="table_row" *ngFor="let t of tracks; let i = index" (click)="selectRow($event, t.id, i)"
        [ngClass]="{ selected: checkSelectedRow(selectedRows, t.id) != -1 , 'isDeleted': t.deleted_at != null, 'kv-tracks':(t.storage == 'no_storage' || t.storage == 'storage_kv')}">
        <div>{{ t.title }}</div>
        <div>{{ t.album }}</div>
        <div>{{ t.artist }}</div>
        <div>{{ t.category_name }}</div>
        <div>{{ t.year }}</div>
        <div>{{ t.length_time }}</div>
        <div>{{ t.mc_tags }}</div>
        <div>{{ t.genre }}</div>
        <div>{{ t.mood }}</div>
        <div>{{ t.full_name_created }}</div>
        <div>{{ t.full_name_edited }}</div>
        <div>{{ t.bpm }}</div>
        <div>{{ t.created_at }}</div>
        <div>
          <a href="#"
            [ngClass]="
              playerInfo.trackId == t.id && playerInfo.trackIndex == i && playerInfo.isPlaying == 'play'? 'track_pausa active': 'track_play active'"
            (click)="playTrack($event,t.id,
                t.file_name,
                i,
                t.album,
                t.artist,
                t.storage
              )
            " title="Play"></a>

          <a href="#" class="track_info" *ngIf="t.deleted_at == null " (click)="showInfo($event, t.id)" [ngClass]="{
              active:
                trackInfo != undefined &&
                trackInfo.data.id == t.id &&
                popupOpened
            }" title="Detail"></a>
          <input type="checkbox"  *ngIf="[74,82,90,94,110,119,132,166].includes(current_user.id) && t.deleted_at == null "  [value]="t.id"  [checked]="t.selected"
            (change)="checkboxselection(t.id, $event.target.checked)" />
          <span *ngIf="
              playerInfo.trackId == t.id &&
              playerInfo.trackIndex == i &&
              playerInfo.isPlaying == 'play'
            " class="volume"></span>
        </div>
      </div>
    </div>
    <div class="table_content nf" *ngIf="tracks.length <= 0 && !isLoad">
      <span>No results found</span>
    </div>
  </div>
</div>
<div class="popup edit_track_popup" [ngClass]="{ opened: popupOpened }" *ngIf="
    current_user != null &&
    (current_user.type == 'Consultant' || current_user.type == 'Administrator')
  " ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle>File Information</div>
  <div class="popup_form" *ngIf="trackInfo != undefined">
    <form #updateTrackForm="ngForm">
      <input type="hidden" name="id" value="{{ trackInfo.data.id }}" [(ngModel)]="trackInfo.data.id" />
      <div class="left_form">
        <div class="input_wrap">
          <label>Title</label>
          <input name="title" [disabled]="disabledInputs.title ? true : null" type="text"
            value="{{ trackInfo.data.title }}" [(ngModel)]="trackInfo.data.title" (focus)="focusInput()"
            (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>UID</label>
          <input name="uid" [readonly]="true" type="text" value="{{ trackInfo.data.uid }}" />
        </div>
        <div class="input_wrap">
          <label>Rating</label>
          <select name="rating" [(ngModel)]="trackInfo.data.rating">
            <option value="null" [selected]="trackInfo.data.rating == null">
              Select rating
            </option>
            <option value="1" [selected]="trackInfo.data.rating == '1'">
              1
            </option>
            <option value="2" [selected]="trackInfo.data.rating == '2'">
              2
            </option>
            <option value="3" [selected]="trackInfo.data.rating == '3'">
              3
            </option>
            <option value="4" [selected]="trackInfo.data.rating == '4'">
              4
            </option>
            <option value="5" [selected]="trackInfo.data.rating == '5'">
              5
            </option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Contributing Artists</label>

          <app-input-autocomplete [inputVal]="
              trackInfo.data.contribute_artist != null
                ? trackInfo.data.contribute_artist.name
                : ''
            " [inputName]="'contribute_artist'" [queryData]="'contribute_artist'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Album</label>
          <app-input-autocomplete [disabled]="disabledInputs.album ? true : null" [inputVal]="
              trackInfo.data.album != null ? trackInfo.data.album.name : ''
            " [inputName]="'album'" [queryData]="'album'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Project</label>
          <app-input-autocomplete [inputVal]="trackInfo.data.genre!=null ? trackInfo.data.genre.name : ''"
            [inputName]="'genre'" [queryData]="'genre'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Length</label>
          <input type="text" value="{{ trackInfo.data.length_time }}" disabled="disabled" (focus)="focusInput()"
            (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Start</label>
          <input name="start" type="text" value="{{ trackInfo.data.start }}" [(ngModel)]="trackInfo.data.start"
            (focus)="focusInput()" (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Highest Chart Pos</label>
          <input name="highestchartpos" type="text" value="{{ trackInfo.data.highestchartpos }}"
            [(ngModel)]="trackInfo.data.highestchartpos" (focus)="focusInput()" (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Composers</label>
          <app-input-autocomplete [inputVal]="
              trackInfo.data.composer != null
                ? trackInfo.data.composer.name
                : ''
            " [inputName]="'composer'" [queryData]="'composer'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Mood</label>
          <app-input-autocomplete [inputVal]="
              trackInfo.data.mood != null ? trackInfo.data.mood.name : ''
            " [inputName]="'mood'" [queryData]="'mood'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Publisher</label>
          <app-input-autocomplete [inputVal]="
              trackInfo.data.publisher != null
                ? trackInfo.data.publisher.name
                : ''
            " [inputName]="'publisher'" [queryData]="'publisher'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Uploaded by</label>
          <input type="text" value="{{ trackInfo.data.full_name_created }}" disabled="disabled" (focus)="focusInput()"
            (blur)="blurInput()" />
        </div>

        <div class="input_wrap" (click)="focusInput()">
          <label>Additional attributes</label>
          <ng-multiselect-dropdown [placeholder]="'MC Tags'" [settings]="dropdownSettings" [data]="mcTagsList"
            [(ngModel)]="selectedMCTags" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
            name="mc_tags" class="mc_tag">
          </ng-multiselect-dropdown>
        </div>
      </div>
      <div class="right_form">
       
        <div class="input_wrap">
          <label>Sub Title</label>
          <input name="sub_title" type="text" value="{{ trackInfo.data.sub_title }}"
            [(ngModel)]="trackInfo.data.sub_title" (focus)="focusInput()" (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>File name</label>
          <input name="file_name" [readonly]="true" type="text" value="{{ trackInfo.data.file_name }}" />
        </div>
        <div class="input_wrap">
          <label>Comments</label>
          <input name="comments" type="text" value="{{ trackInfo.data.comments }}" [(ngModel)]="trackInfo.data.comments"
            (focus)="focusInput()" (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Album Artist</label>
          <app-input-autocomplete [disabled]="disabledInputs.artist ? true : null" [inputVal]="
              trackInfo.data.artist != null ? trackInfo.data.artist.name : ''
            " [inputName]="'artist'" [queryData]="'artist'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Year</label>
          <input name="year" [disabled]="disabledInputs.year ? true : null" type="text"
            value="{{ trackInfo.data.year }}" [(ngModel)]="trackInfo.data.year" (focus)="focusInput()"
            (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Category</label>
          <app-input-autocomplete [inputVal]="
              trackInfo.data.track_category != null
                ? trackInfo.data.track_category.name
                : ''
            " [inputName]="'track_category'" [queryData]="'track_category'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>Bitrate</label>
          <input type="text" value="{{ trackInfo.data.bitrate_encoded }}" disabled="disabled" (focus)="focusInput()"
            (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Finish</label>
          <input name="finish" type="text" value="{{ trackInfo.data.finish }}" [(ngModel)]="trackInfo.data.finish"
            (focus)="focusInput()" (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Conductors</label>

          <app-input-autocomplete [inputVal]="
              trackInfo.data.conductor != null
                ? trackInfo.data.conductor.name
                : ''
            " [inputName]="'conductor'" [queryData]="'conductor'" (focus)="autoFocus($event)">
          </app-input-autocomplete>
        </div>
        <div class="input_wrap">
          <label>BPM</label>
          <input name="bpm" pattern="[0-9]*" type="text" value="{{ trackInfo.data.bpm }}"
            [(ngModel)]="trackInfo.data.bpm" (focus)="focusInput()" (blur)="blurInput()" />
        </div>


        <div class="input_wrap">
          <label>Copyright</label>
          <input name="copyright" type="text" value="{{ trackInfo.data.copyright }}"
            [(ngModel)]="trackInfo.data.copyright" (focus)="focusInput()" (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Last Modified by</label>
          <input type="text" value="{{ trackInfo.data.full_name_edited }}" disabled="disabled" />
        </div>
        <div class="input_wrap">
          <label>Encoded file name</label>
          <input type="text" value="{{ trackInfo.data.file_name_altered_id }}" disabled="disabled" />
        </div>
        <div class="input_wrap with_list">
          <label>Clients assigned track</label>
          <ul [ngClass]="{ opened: drops.clients }" (click)="openDrop(1)">
            <li *ngFor="let cas of trackInfo.cp_lists_with_this_track">
              {{ cas.name }}
            </li>
          </ul>
        </div>
        <div class="input_wrap with_list">
          <label>FPs assigned track</label>
          <ul [ngClass]="{ opened: drops.fp }" (click)="openDrop(2)">
            <li *ngFor="let fas of trackInfo.fp_lists_with_this_track">
              {{ fas.name }}
            </li>
          </ul>
        </div>
        <div class="input_wrap">
          <label>Wave form</label>
          <img src="{{ trackInfo.data.storage }}/waves/{{ trackInfo.data.id }}.png" alt="track wave"
            style="width: 350px" />
        </div>
      </div>
      <div class="right_main_form">
        <div class="input_wrap">
          <label>Explicit</label>
          <ui-switch 
          labelOn="Y" labelOff="N"
          size="small" 
          name="hidden"
          switchColor="#7a7a7a"
          defaultBoColor="#a8a8a8"
          [checked]="musicFileDetails.explicit == '1' " 
          (change)="updateExplicit($event,trackInfo.data.id)"></ui-switch>

      </div>
        <div class="input_wrap">
          <label>Hard-In-Point</label>
          <input type="text" [value]="musicFileDetails.hard_in_point"  [(ngModel)]="musicFileDetails.hard_in_point"
          name="hard_in_point"  (focus)="focusInput()" (blur)="blurInput()" />
        </div>
        <div class="input_wrap">
          <label>Hard-Out-Point</label>
          <input type="text" [value]="musicFileDetails.hard_out_point"  [(ngModel)]="musicFileDetails.hard_out_point"
          name="hard_out_point"  (focus)="focusInput()" (blur)="blurInput()" />
        </div>
       
         <div class="input_wrap">
          <label>Hidden</label>
          <ui-switch 
          labelOn="Y" labelOff="N"
          size="small" 
          name="hidden"
          switchColor="#7a7a7a"
          defaultBoColor="#a8a8a8"
          [checked]="hiddenset == '1' " 
          (change)="setHidden($event,trackInfo.data.id)"></ui-switch>
        </div>
        <div class="input_wrap">
          <label>Quality</label>
          <select style="width: 50%;" name="quality" [(ngModel)]="selectedqualityOption">
            <option *ngFor="let option of qualityoptions" [value]="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Clip-Type</label>
          <select style="width: 50%;" name="clipType" [(ngModel)]="selectedclipTypeOption">
            <option *ngFor="let option of cliptypeoptions" [value]="option.value">{{ option.label }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Genre-1</label>
          <select style="width: 50%;" name="genre1" [(ngModel)]="selectedmusicGenre1">
            <option *ngIf="selectedmusicGenre1 === -1" [value]="-1">Please choose an option</option>
            <option *ngFor="let option of trackInfo.genre_list;let i=index" [value]="option.kvs_id"> {{ option.genre_name }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Genre-2</label>
          <select style="width: 50%;" name="genre2" [(ngModel)]="selectedmusicGenre2">
            <option *ngIf="selectedmusicGenre2 === -1" [value]="-1">Please choose an option</option>
            <option *ngFor="let option of trackInfo.genre_list;let i=index" [value]="option.kvs_id"> {{ option.genre_name }}</option>
          </select>
        </div>
        <div class="input_wrap">
          <label>Genre-3</label>
          <select style="width: 50%;" name="genre3" [(ngModel)]="selectedmusicGenre3">
            <option *ngIf="selectedmusicGenre3 === -1" [value]="-1">Please choose an option</option>
            <option *ngFor="let option of trackInfo.genre_list;let i=index" [value]="option.kvs_id"> {{ option.genre_name }}</option>
          </select>
        </div>
      </div>
      <div class="popup_btns">
        <a href="#" class="btn prev" (click)="getPrevTrackInfo($event)">Prev</a>
        <a href="#" class="btn next" (click)="getNextTrackInfo($event)">Next</a>
        <button type="button" class="delete" (click)="openConfirmPopup(trackInfo.data.id, 0)"></button>
        <button type="button" class="cancel" (click)="popupClose($event)">
          Cancel
        </button>
        <button type="submit" (click)="formDataUpdate($event, updateTrackForm)">
          Update
        </button>
      </div>
    </form>
  </div>
</div>
<div class="popup smart_filter_popup" [ngClass]="{ opened: smartFilterPopupOpened }" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle2>Smart Filter</div>
  <div class="filter_inner">
    <div class="top_filter">
      Match
      <select [(ngModel)]="matchSelect">
        <option value="all">All</option>
        <option value="any">Any</option>
        <option value="none">None</option>
      </select>
      of the following rules:
      <span style="float: right" *ngIf="profileFilterCount > 1">
        <select [(ngModel)]="andOrSelect">
          <option value="and">AND</option>
          <option value="or">OR</option>
        </select>
      </span>
    </div>
    <div class="filter_line" *ngFor="let filter of smartFilters; let i = index">
      <div class="left_col">
        <select (change)="changeSmartSelect($event, i)" [disabled]="filter.isHidden"
          [(ngModel)]="smartFilters[i].categorySelect">
          <option value="null">Select...</option>
          <option *ngFor="let sf of defaultSmartFilters" value="{{ sf.field }}">
            {{ sf.title }}
          </option>
        </select>
      </div>
      <div class="middle_col">
        <select *ngIf="filter.compare != null" [(ngModel)]="smartFilters[i].compareSelect">
          <option value="null">Select...</option>
          <option *ngFor="let c of filter.compare" value="{{ c }}">
            {{ c }}
          </option>
        </select>
      </div>
      <div class="right_col" *ngIf="smartFilters[i].categorySelect == 'mc_tags'; else default">
        <select [(ngModel)]="smartFilters[i].field">
          <option value="null">Select...</option>
          <option *ngFor="let tag of mcTagsList" value="{{ tag.item_text }}">
            {{ tag.item_text }}
          </option>
        </select>
      </div>

      <ng-template #default>
        <div class="right_col" *ngIf="filter.type == 'str'">
          <input type="text" value="" [(ngModel)]="smartFilters[i].field" />
        </div>
        <div class="right_col" *ngIf="filter.type == 'date'">
          <my-date-picker name="date1" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy"
            [(ngModel)]="smartFilters[i].arrFields[0]"></my-date-picker>
          <my-date-picker name="date1" [options]="myDatePickerOptions" placeholder="dd/mm/yyyy"
            [(ngModel)]="smartFilters[i].arrFields[1]"></my-date-picker>
        </div>
        <div class="right_col" *ngIf="filter.type == 'int'">
          <input type="number" class="number" value="" [(ngModel)]="smartFilters[i].arrFields[0]" />
          <input type="number" class="number" value="" [(ngModel)]="smartFilters[i].arrFields[1]"
            *ngIf="smartFilters[i].compareSelect == 'between'" />
        </div>
      </ng-template>
      <div class="func_col">
        <a href="#" *ngIf="smartFilters.length > 1" class="btn_remove" (click)="removeFilterField($event, i)"></a>
        <a href="#" class="btn_add" (click)="addFilterField($event)"></a>
      </div>
    </div>
    <div class="popup_btns">
      <!--Matches: 890 tracks-->
      <button type="button" class="cancel" (click)="clearFilter($event)">
        Clear Filter
      </button>
      <button type="button" class="cancel" (click)="popupClose($event)">
        Cancel
      </button>
      <button type="submit" class="submit" (click)="smartSearch(true, false)">
        Search
      </button>
    </div>
  </div>
</div>
<div class="popup bulk_popup" [ngClass]="{ opened: popups.bulkPopupOpened }" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle3">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle3>Bulk Updates</div>
  <div class="popup_form">
    <form #bulkUpdateTrackForm="ngForm">
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[0].check" name="chb-0" />
        <label>Contributing Artists</label>
        <app-input-autocomplete [inputVal]="bulkInfo[0].value" [inputName]="'contribute_artist'"
          [queryData]="'contribute_artist'">
        </app-input-autocomplete>
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[1].check" name="chb-1" />
        <label>Album</label>
        <app-input-autocomplete [inputVal]="bulkInfo[1].value" [inputName]="'album'" [queryData]="'album'">
        </app-input-autocomplete>
      </div>
      <div class="input_wrap" *ngIf="loadContent">
        <input type="checkbox" [(ngModel)]="bulkInfo[16].check" name="chb-16" />
        <label>Additional attributes</label>
        <ng-multiselect-dropdown [placeholder]="'MC Tags'" [settings]="dropdownSettings" [data]="mcTagsList"
          [(ngModel)]="bulkInfo[16].value" (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
          name="mc_tags" class="mc_tag_bu">
        </ng-multiselect-dropdown>
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[2].check" name="chb-2" />
        <label>Project</label>
        <app-input-autocomplete [inputVal]="bulkInfo[2].value" [inputName]="'genre'" [queryData]="'genre'">
        </app-input-autocomplete>
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[3].check" name="chb-3" />
        <label>Composers</label>
        <app-input-autocomplete [inputVal]="bulkInfo[3].value" [inputName]="'composer'" [queryData]="'composer'">
        </app-input-autocomplete>
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[4].check" name="chb-4" />
        <label>Mood</label>
        <app-input-autocomplete [inputVal]="bulkInfo[4].value" [inputName]="'mood'" [queryData]="'mood'">
        </app-input-autocomplete>
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[5].check" name="chb-5" />
        <label>Publisher</label>
        <app-input-autocomplete [inputVal]="bulkInfo[5].value" [inputName]="'publisher'" [queryData]="'publisher'">
        </app-input-autocomplete>
      </div>

      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[6].check" name="chb-6" />
        <label>Comments</label>
        <input name="comments" type="text" value="{{ bulkInfo[6].value }}" [(ngModel)]="bulkInfo[6].value" />
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[7].check" name="chb-7" />
        <label>Album Artist</label>
        <app-input-autocomplete [inputVal]="bulkInfo[7].value" [inputName]="'artist'" [queryData]="'artist'">
        </app-input-autocomplete>
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[8].check" name="chb-8" />
        <label>Year</label>
        <input name="year" type="text" value="{{ bulkInfo[8].value }}" [(ngModel)]="bulkInfo[8].value" />
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[9].check" name="chb-9" />
        <label>Category</label>
        <app-input-autocomplete [inputVal]="bulkInfo[9].value" [inputName]="'track_category'"
          [queryData]="'track_category'">
        </app-input-autocomplete>
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[10].check" name="chb-10" />
        <label>Conductors</label>
        <app-input-autocomplete [inputVal]="bulkInfo[10].value" [inputName]="'conductor'" [queryData]="'conductor'">
        </app-input-autocomplete>
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[11].check" name="chb-11" />
        <label>BPM</label>
        <input name="bpm" type="text" (keypress)="keyPress($event)" value="{{ bulkInfo[11].value }}"
          [(ngModel)]="bulkInfo[11].value" />
      </div>
      <div class="input_wrap">
        <input type="checkbox" [(ngModel)]="bulkInfo[12].check" name="chb-12" />
        <label>Copyright</label>
        <input name="copyright" type="text" value="{{ bulkInfo[12].value }}" [(ngModel)]="bulkInfo[12].value" />
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="popupClose($event)">
          Cancel
        </button>
        <button type="submit" (click)="openConfirmPopup(bulkUpdateTrackForm, 1)">
          Update
        </button>
      </div>
    </form>
  </div>
</div>
<div class="popup track_info_popup" *ngIf="current_user != null && current_user.type == 'Technician'"
  [ngClass]="{ opened: popupOpened }" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'"
  [handle]="dragHandle4">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle4>File Information</div>
  <div class="popup_info" *ngIf="trackInfo != undefined">
    <img src="{{ trackInfo.data.storage }}/waves/{{ trackInfo.data.id }}.png" alt="" />
    <div class="left_info">
      <div class="info_wrap">Title: {{trackInfo.data.title}}</div>
      <div class="info_wrap">Rating: {{trackInfo.data.rating!=null ? trackInfo.data.rating : '0'}}</div>
      <div class="info_wrap">Contributing Artists: {{trackInfo.data.contribute_artist!=null ?
        trackInfo.data.contribute_artist.name : ''}}</div>
      <div class="info_wrap">Album: {{trackInfo.data.album!=null ? trackInfo.data.album.name : ''}}</div>
      <div class="info_wrap">Project: {{trackInfo.data.genre!=null ? trackInfo.data.genre.name : ''}}</div>
      <div class="info_wrap">Length: {{trackInfo.data.length_time}}</div>
      <div class="info_wrap">Start: {{trackInfo.data.start}}</div>
      <div class="info_wrap">Highestchartpos: {{trackInfo.data.highestchartpos}}</div>
      <div class="info_wrap">Composers: {{trackInfo.data.composer!=null ? trackInfo.data.composer.name : ''}}</div>
      <div class="info_wrap">Mood: {{trackInfo.data.mood!=null ? trackInfo.data.mood.name : ''}}</div>
      <div class="info_wrap">Publisher: {{trackInfo.data.publisher!=null ? trackInfo.data.publisher.name : ''}}</div>
      <div class="info_wrap">Additional attributes: <span *ngFor="let a of additional">{{a}} </span></div>
    </div>
    <div class="right_info">
      <div class="info_wrap">Sub Title: {{ trackInfo.data.sub_title }}</div>
      <div class="info_wrap">Comments: {{ trackInfo.data.comments }}</div>
      <div class="info_wrap">
        Album Artist:
        {{ trackInfo.data.artist != null ? trackInfo.data.artist.name : "" }}
      </div>
      <div class="info_wrap">Year: {{ trackInfo.data.year }}</div>
      <div class="info_wrap">
        Category:
        {{
        trackInfo.data.track_category != null
        ? trackInfo.data.track_category.name
        : ""
        }}
      </div>
      <div class="info_wrap">Bitrate: {{ trackInfo.data.bitrate_encoded }}</div>
      <div class="info_wrap">Finish: {{ trackInfo.data.finish }}</div>
      <div class="info_wrap">
        Conductors:
        {{
        trackInfo.data.conductor != null ? trackInfo.data.conductor.name : ""
        }}
      </div>
      <div class="info_wrap">BPM: {{ trackInfo.data.bpm }}</div>
      <div class="info_wrap">Copyright: {{ trackInfo.data.copyright }}</div>
      <div class="info_wrap" *ngIf="trackInfo.from_fp != null">
        Sourced: {{ trackInfo.from_fp.name }}
      </div>
    </div>
    <div class="popup_btns">
      <button type="button" class="cancel" (click)="popupClose($event)">
        Cancel
      </button>
    </div>
  </div>
</div>

<!-- MC tag list popup -->
<div class="popup edit_track_popup" [ngClass]="{ opened: mcPopupOpened }" *ngIf="
    current_user != null &&
    (current_user.type == 'Consultant' || current_user.type == 'Administrator')
  " ngDraggable [handle]="dragHandle5" style="left: 40%; top: 10%">
  <a href="#" class="popup_close" (click)="mcPopupClose($event)"></a>
  <div class="popup_title" #dragHandle5>MC tags</div>
  <div class="popup_info" *ngIf="mcTags != undefined">
    <div style="
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 5px 20px 15px;
      ">
      <div class="search_box" style="
          width: 70%;
          display: flex;
          align-items: center;
          border: 1px solid #eae8e8;
        ">
        <input type="text" name="search" value="{{ mcTagSearchStr }}" placeholder="MC-Tag search" class="searchInput"
          [(ngModel)]="mcTagSearchStr" style="width: 100%; height: 40px" (focus)="focusInput()"
          (keyup.enter)="mcTagSearch($event)" />
        <a style="left: auto; top: auto; position: relative" href="#" class="clear_search"
          (click)="clearMCTagSearch($event)"></a>
        <button type="submit" class="searchBtn" (click)="mcTagSearch($event)"></button>
      </div>
      <div style="text-align: right; margin: 5px 15px">
        <button (click)="createMCTag()" class="btn btn-sm" title="Create">
          Create new MC-Tag
        </button>
      </div>
    </div>
    <div class="tracks_count">
      Displaying {{ mcTagsCount.display }}/{{ mcTagsCount.total }} results
    </div>
    <div class="tracks_list_block table_wrap" style="min-height: 350px">
      <div class="table_titles">
        <div style="width: 50%; max-width: 50%" class="order" (click)="setOrderMCTags('name')" [ngClass]="{
            active: mcTagsOrder.orderBy == 'name',
            asc: mcTagsOrder.order == 'ASC'
          }">
          Name
        </div>
        <div style="width: 20%; max-width: 20%" class="order" (click)="setOrderMCTags('created_at')" [ngClass]="{
            active: mcTagsOrder.orderBy == 'created_at',
            asc: mcTagsOrder.order == 'ASC'
          }">
          Date Added
        </div>
        <div style="width: 20%; max-width: 20%" class="order" (click)="setOrderMCTags('updated_at')" [ngClass]="{
            active: mcTagsOrder.orderBy == 'updated_at',
            asc: mcTagsOrder.order == 'ASC'
          }">
          Date Updated
        </div>
        <div style="width: 10%; max-width: 10%">Actions</div>
      </div>
      <div class="table_content" *ngIf="mcTags.length > 0" style="height: 400px; margin-bottom: 40px">
        <div class="table_row" *ngFor="
            let tag of mcTags
              | paginate : { itemsPerPage: 100, currentPage: mcPage };
            index as i
          ">
          <div style="width: 50%; max-width: 50%">{{ tag.name }}</div>
          <div style="width: 20%; max-width: 20%">{{ tag.created_at }}</div>
          <div style="width: 20%; max-width: 20%">{{ tag.updated_at }}</div>
          <div style="width: 10%; max-width: 10%">
            <a class="edit_icon" title="Edit" href="#" (click)="editMCTag($event, tag.id)"></a>
          </div>
        </div>
        <div style="
            position: absolute;
            bottom: 0px;
            background: white;
            left: 0px;
            width: 100%;
            text-align: center;
            line-height: 12px;
            font-size: 12px;
          ">
          <pagination-controls (pageChange)="mcPage = $event"></pagination-controls>
        </div>
      </div>

      <div class="table_content nf" *ngIf="mcTags.length <= 0 && !isLoad">
        <span>No results found</span>
      </div>
    </div>
  </div>
</div>

<!-- MC tag add/edit popup -->
<div class="popup" [ngClass]="{ opened: mcFormPopupOpened }" ngDraggable ngResizable [rzHandles]="'n,e,s,w,se,sw,ne,nw'"
  [handle]="dragHandle6">
  <a href="#" class="popup_close" (click)="mcFormPopupClose($event)"></a>
  <div class="popup_title" #dragHandle6>Create/Edit MC-Tag</div>
  <div class="popup_form">
    <form #updateMCTag="ngForm">
      <div class="input_wrap">
        <label>MC tag name</label>
        <input autocomplete="off" type="text" maxlength="255" name="name" value="{{ mcTagForm.name }}"
          [(ngModel)]="mcTagForm.name" (focus)="focusInput()" (blur)="blurInput()" />
      </div>
      <div class="popup_btns">
        <button type="button" class="cancel" (click)="mcFormPopupClose($event)">
          Cancel
        </button>
        <button type="submit" (click)="mcTagUpdate($event, updateMCTag)">
          Save
        </button>
      </div>
    </form>
  </div>
</div>

<!--Channel popup -->
<div class="popup channelPopUp autoBuildPopup" [ngClass]="{ opened: channelPopUp }" ngDraggable ngResizable
  *ngIf="tracksAndChannels != null" [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle7">
  <a href="#" class="popup_close" (click)="channelPopUpClose($event)"></a>
  <div class="popup_title" #dragHandle7>Track removal - Build and hawk</div>
  <div style="margin: 15px 30px; display: flex; justify-content: flex-end; gap: 12px;">
    <button title="Build All" (click)="buildAll()">Build All</button>
    <button title="Hawk All" (click)="hawkAll()">Hawk All</button>
    <button title="export" (click)="Export()">Export</button>
  </div>
  <div class="table_titles">
    <div style="width: 10%; max-width: 10%" class="order">Track Name</div>
    <div style="width: 15%; max-width: 15%" class="order">Client</div>
    <div style="width: 15%; max-width: 15%" class="order">Site</div>
    <div style="width: 20%; max-width: 20%" class="order">Channel</div>
    <div style="width: 25%; max-width: 25%" class="order">Build status</div>
    <div style="width: 15%; max-width: 15%" class="order">Hawk status</div>
  </div>

  <div class="table_content selected_track_list" style="padding-bottom: 35px">
    <div class="table_row" style="border-bottom: 1px solid; padding:0px 5px" *ngFor="let tc of tracksAndChannels">
      <div style="width: 10%; max-width: 10%">{{ tc[0]?.track_title }}</div>
      <div style="width: 15%; max-width: 15%; display: inline-grid">
        <div *ngFor="let ch of tc" value="ch.client_id" class="track_sub_item">
          {{ ch.client_name }}
        </div>
      </div>
      <div style="width: 15%; max-width: 15%; display: inline-grid">
        <div *ngFor="let ch of tc" value="ch.site_id" class="track_sub_item">{{ ch.site_name }}</div>
      </div>
      <div style="width: 20%; max-width: 20%; display: inline-grid">
        <div *ngFor="let ch of tc" value="ch.channel_id" class="track_sub_item">
          {{ ch.channel_name }} {{ (ch.entity_id) }}
        </div>
      </div>
      <div style="width: 25%; max-width: 25%; display: inline-grid; white-space: nowrap; vertical-align: top;  position: relative;">
        <div *ngFor="let ch of tc; let i = index" class="order track_sub_item" style="padding: 0;">
          <div *ngIf="findProgress(channel_ids, ch) as dge" style="padding: 0; width: 100%;">
            <!-- <div *ngIf="dge.buildResponse === null">
              Failedonce
              <button (click)="refreshBuildData(ch,i)" title="Refresh build" style="position: absolute; top: 50%; transform: translateY(-50%); right: 12px;">
                <span class="refresh_icon"></span>
              </button>
            </div> -->
            <!-- <ng-container *ngIf="dge.buildResponse !== null"> -->
              <div *ngIf="dge.build && progressValue[ch.channel_id]" style="width: 90%; padding: 0;">
                <progress-bar [progress]="progressValue[ch.channel_id]" [color-degraded]="{
                  '0': '#f00e0e',
                  '25': '#f35b08',
                  '50': '#f2be0e',
                  '75': '#04db52'
                }">
                </progress-bar>
              </div>
              <div *ngIf="!dge.build && dge.buildResponse == 'build_not_created'" style="padding: 0;">
                <div style="white-space: wrap;width: 75%;max-height:50px; overflow: scroll;" innerHTML="{{dge.build_not_created_em}}"></div>
                <button (click)="refreshBuildData(ch,i)" title="Refresh build" style="position: absolute; top: 50%; transform: translateY(-50%); right: 30px;">
                  <span class="refresh_icon"></span>
                </button>
              </div>
              <div *ngIf="!dge.build && dge.buildResponse" style="padding: 0;">
                {{
                  dge.buildResponse.response &&
                  dge.buildResponse.response.statusMessage.returnvalue &&
                  (dge.buildResponse.response.statusMessage.returnvalue.NumberOfClips !=
                    ConvertToInt(dge.buildResponse.clip_count) ||
                    dge.buildResponse.response.statusMessage.returnvalue.NumberOfProfiles != dge.buildResponse.profile_count)
                  ? "Failed"
                  : dge.buildResponse.response &&
                    dge.buildResponse.response.statusCode == 200
                  ? "Success"
                  : dge.buildResponse.response == null
                  ? ""
                  : "Failed"
                }}
                <button *ngIf="(dge.buildResponse.response && dge.buildResponse.response.statusCode !== 200) || dge.buildResponse == null" (click)="refreshBuildData(ch,i)" title="Refresh build" style="position: absolute; top: 50%; transform: translateY(-50%); right: 30px;">
                  <span class="refresh_icon"></span>
                </button>
              </div>
            <!-- </ng-container> -->
          </div>
        </div>
      </div>
      <div style="width: 15%;max-width: 15%;display: inline-grid;white-space: nowrap;vertical-align: top;">
        <div *ngFor="let ch of tc" class="order track_sub_item">
          <div *ngIf="findProgress(channel_ids, ch) as dge" style="padding: 0">
            <div *ngIf="dge.hawk" style="padding: 0">Processing...</div>
            <div *ngIf="!dge.hawk && dge.hawkResponse" style="padding: 0">
              {{ dge.hawkResponse.hawk_status == 1 ? "Success"
              : dge.hawkResponse.hawk_status == 0 ? "In Progress"
              : dge.hawkResponse.hawk_status == -1 ? "Failed"
              : "" }}
            </div>
            <div *ngIf="!dge.hawk && dge.hawkResponse == 'hawk_not_created'" style="padding: 0">
              <div style="white-space: wrap;max-height:50px; overflow: scroll;" innerHTML="{{dge.hawk_not_created_em}}"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--search Uid PopUP-->
<div class="popup " [ngClass]="{ opened: searchBulkUidPopUpOpen }" ngDraggable ngResizable
  [rzHandles]="'n,e,s,w,se,sw,ne,nw'" [handle]="dragHandle2">
  <a href="#" class="popup_close" (click)="popupClose($event)"></a>
  <div class="popup_title" #dragHandle2>Search Track UID</div>
  <div class="filter_inner">
    <label for="searchUID">Enter UID</label>
    <textarea name="searchUID" id="searchUID"  (focus)="focusInput()" [(ngModel)]="searchUID"></textarea>
    <div class="popup_btns">
      <button type="button" class="clear_search" (click)="clearBoxUID()">Clear</button>
      <button type="button" class="cancel" (click)="popupClose($event)">Cancel</button>
      <button type="submit" class="submit" (click)="searchUIDFunction()">Search</button>
    </div>
  </div>
</div>

<!-- Player -->
<app-player-component [isInputFocused]="isFocused" [storage]="playerInfo.storage" [token]="playerInfo.token"
  [track]="playerInfo.trackId" [wave]="playerInfo.trackId" [album]="playerInfo.album" [artist]="playerInfo.artist"
  (outputPlaying)="checkPlaying($event)" (next)="nextTrack($event)" (prev)="prevTrack($event)"></app-player-component>
<app-alert></app-alert>
<app-confirm #confirm (isConfirm)="isConfirm($event)"></app-confirm>
<app-confirm #confirmAll (isConfirm)="isAllConfirm($event)"></app-confirm>
<app-confirm #finalConfirm (isConfirm)="isfinalConfirm($event)"></app-confirm>
<div class="fade" [ngClass]="{ opened: isLoad }"></div>
<img src="../../assets/img/loader.gif" alt="loader" class="loader" [ngClass]="{ active: isLoad }" />
