import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {Globals} from '../globals';

@Injectable()
export class CPPlaylistCategoriesService {

  httpOptions = {
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json'
    })
  };
  postOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  };
  deleteOptions={
    headers: new HttpHeaders({
      'Host': environment.dev_url_prefix + '/',
      'Authorization': 'Bearer ' + this.globals.access_token,
      'Accept': 'application/json',
      'Content-Type':'application/x-www-form-urlencoded'
    })
  }

  constructor(
    private http: HttpClient,
    private globals: Globals
  ) { }
  /**
   Get playlist sites/channels
   * @param pcid - category id
   * @returns {Observable<any>}
   */
  getPlaylistsCategories(pcid) {
    var str='/'+pcid;
    if(pcid == ''){
      str = '';
    }
    return this.http.get(environment.dev_url_prefix + '/api/V1/clients_categories/tree' + str, this.httpOptions).pipe(map(playCats=>{
      return playCats;
    }));
  }

  /**
   Get sites by client
   * @param uid - client id
   * @returns {Observable<any>}
   */
  getSitesByUser(uid) {
    return this.http.get(environment.dev_url_prefix + '/api/V1/clients_categories/client_tree/' + uid, this.httpOptions).pipe(map(sites=>{
      return sites;
    }));
  }

  /**
   Create site/channel
   * @param formData - post data
   * @param parentID - site id
   * @returns {Observable<any>}
   */
  createCategory(formData,parentID){
    var str='/'+parentID;
    if(parentID == ''){
      str = '';
    }
    return this.http.post(environment.dev_url_prefix + '/api/V1/clients_categories/create'+str,formData,this.postOptions).pipe(map(playlistsCats => {
      return playlistsCats;
    }));
  }

  /**
   Delete site/channel
   * @param cid - site/channel id
   * @returns {Observable<any>}
   */
  deleteCategory(cid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/clients_categories/delete/' + cid, this.deleteOptions).pipe(map(categoryDelete => {
      return categoryDelete;
    }));
  }

  /**
   Get site/channel information
   * @param pcid - site/channel id
   * @returns {Observable<any>}
   */
  getPlaylistCategoriesByID(pcid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/clients_categories/read/' + pcid, this.httpOptions).pipe(map(playCats=>{
      return playCats;
    }));
  }

  /**
   Update site/channel information
   * @param pcid - site/channel id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  updatePlaylistCategory(pcid,formData){
    return this.http.put(environment.dev_url_prefix + '/api/V1/clients_categories/update/' + pcid, formData,this.postOptions).pipe(map(playlistCats => {
      return playlistCats;
    }));
  }

  /**
   Get clients list
   * @returns {Observable<any>}
   */
  getClientsList(){
    return this.http.get(environment.dev_url_prefix + '/api/V1/clients', this.httpOptions).pipe(map(clients=>{
      return clients;
    }));
  }

  /**
   Create new client
   * @param formData - post data
   * @returns {Observable<any>}
   */
  createNewClient(formData){
    return this.http.post(environment.dev_url_prefix + '/api/V1/client',formData,this.postOptions).pipe(map(client => {
      return client;
    }));
  }

  /**
   Edit client information
   * @param cid - client id
   * @param formData - post data
   * @returns {Observable<any>}
   */
  editClient(cid,formData){
    return this.http.put(environment.dev_url_prefix + '/api/V1/client/' + cid,formData,this.postOptions).pipe(map(client => {
      return client;
    }));
  }

  /**
   Get client information
   * @param cid - client id
   * @returns {Observable<any>}
   */
  getClientById(cid){
    return this.http.get(environment.dev_url_prefix + '/api/V1/client/'+cid, this.httpOptions).pipe(map(clients=>{
      return clients;
    }));
  }

  /**
   Delete client
   * @param cid - client id
   * @returns {Observable<any>}
   */
  deleteClient(cid){
    return this.http.delete(environment.dev_url_prefix + '/api/V1/client/' + cid, this.deleteOptions).pipe(map(client => {
      return client;
    }));
  }

  getSiteSearch(searchText){
    return this.http.get(environment.dev_url_prefix + '/api/V1/entity_search/'+searchText, this.httpOptions).pipe(map(sites=>{
    
      return sites;
    }));
  }
}
