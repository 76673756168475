<div style="background-color: #fff;  height: 100vh;overflow: hidden;margin: -10px -20px 0px -65px !important;"   *ngIf="PlaylistDetails; else elseBlock">
  <div class="musicsec_parent_sec">
    <div class="musicsec_child_sec">
      <div class="music_page_subchild">
        <div class="musicpage_page_sec">
          <div class="musicpage_child_parent">
            <div class="grid-container">
              <div class="musicpage_subchild_parent">
                <div class="grid-container">
                  <div class="musicpage_subchild_div">
                    <div class="music_logo_section">
                      <img src="../../assets/img/MC-Logo.png" alt="MC-Logo">
                    </div>
                    <div class="music_main_header">
                      <div class="music_sub_contain">
                        <div class="music_sub_heading">
                          <h3>{{PlaylistDetails?.client?.name}} </h3>
                        </div>

                        <div class="dropdown_contain">
                          <div class="container">
                            <div class="select-dropdown select-dropdown--0">
                              <select class="dropdown" id="normal-select-1"
                                [(ngModel)]="PlaylistDetails.sample_channel_id" (change)="onSelectChange($event)"
                                style="display: none;">
                                <option *ngFor="let channel of PlaylistDetails.channels" [value]="channel.id">{{
                                  channel.name }}</option>
                              </select>
                              <button class="select-dropdown__button select-dropdown__button--0"
                                (click)="toggleDropdownchannel()" type="button">
                                <span class="select-dropdown select-dropdown--0">{{ selectedChannelName }}</span>
                                <i class="zmdi zmdi-chevron-down"></i>
                              </button>
                              <ul class="select-dropdown__list select-dropdown__list--0"
                                [ngClass]="{'active': isDropdownOpenchannel}" id="select-dropdown__list-0">
                                <li class="select-dropdown__list-item" *ngFor="let channel of PlaylistDetails.channels"
                                  (click)="selectChannel(channel)">
                                  {{ channel.name }}
                                </li>
                              </ul>
                            </div>

                            <div class="select-dropdown select-dropdown--1">
                              <select class="dropdown" id="normal-select-2" [(ngModel)]="PlaylistDetails.id"
                                (change)="onSelectChangeplaylist($event)" style="display: none;">
                                <option *ngFor="let list of playlists" [value]="list.id">{{ list.name }}</option>
                              </select>
                              <button class="select-dropdown__button select-dropdown__button--1"
                                (click)="toggleDropdownplaylist()" type="button">
                                <span class="select-dropdown select-dropdown--1">{{ selectedPlaylistName }}</span>
                                <i class="zmdi zmdi-chevron-down"></i>
                              </button>
                              <ul class="select-dropdown__list select-dropdown__list--1"
                                [ngClass]="{'active': isDropdownOpenplaylist}" id="select-dropdown__list-1">
                                <li class="select-dropdown__list-item" *ngFor="let list of playlists"
                                  (click)="selectPlaylist(list)">
                                  {{ list.name }}
                                </li>
                              </ul>
                            </div>

                          </div>
                        </div>




                      </div>
                    </div>
                    <div class="music_play_rowsection">
                      <div class="music_header_playpause">
                        <div class="music_sub_contain">
                          <div class="music_play_pausebutton">
                            <div class="music_button">
                              <button style=" background-color: #A18170;" (click)="startPlay()"><img
                                  [src]="playerInfo.isPlaying == 'play' ? '../../assets/img/pause_button_white.png' : '../../assets/img/play_button_white.png'"
                                  alt="pause" /></button>
                            </div>
                            <div class="music_playlist_name">
                              <h3>{{ selectedPlaylistName }}</h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="dynamic-content">
                    <div class="music_playlist_namelist">
                      <div class="music_sub_contain">
                        <div class="musicplay_list_namesec">
                          <ul>
                            <ng-container
                              *ngIf="PlaylistDetails.files && PlaylistDetails.files.length > 0; else noTracksFound">
                              <ng-container *ngFor="let t of PlaylistDetails.files; let i = index">
                                <a>
                                  <li [ngClass]="{highlight: playertrackDetails.id == t.musicfiles.id}">
                                    <div class="listname_playicon_sec">
                                      <div class="music_namelist_section">
                                        <p class="trackname">{{ t.musicfiles.title }}</p>
                                        <p class="artist">{{ t.musicfiles?.artist?.name || 'Unknown Artist' }}</p>
                                      </div>
                                      <div class="music_namelist_playpause">
                                        <button style="background-color: inherit;position: relative;left: 6px;"
                                          (click)="playTrack($event, t.musicfiles, t.musicfiles?.file_name, i, t.musicfiles.album?.name, t.musicfiles?.artist?.name, t.musicfiles?.storage)">
                                          <img [src]="playerInfo.trackId === t.musicfiles.id && playerInfo.trackIndex === i && playerInfo.isPlaying === 'play'
                                                         ? '../../assets/img/track-pause-button-gold.png'
                                                         : '../../assets/img/track-play-button-gold.png'" />
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                </a>
                              </ng-container>
                            </ng-container>
                            <ng-template #noTracksFound>
                              <a>
                                <li>No tracks found</li>
                              </a>
                            </ng-template>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="music_bottom_section">
                <div class="music_sub_contain">
                  <div class="new_music">
                    <h3>Now playing</h3>
                  </div>
                  <div class="play_box">
                    <h2 class="track_artist marquee">
                      <div><span class="name_list1">{{ playertrackDetails.trackName }}</span><span
                        class="name_list1" style="padding: 0 20px;"> | </span> <span class="name_list2"> {{
                        playertrackDetails.artistName || 'Unknown Artist' }}</span>
                      </div>
                    </h2>
                    <div class="play_control">
                      <button (click)="startPlay()"> <img style="    position: relative;right: 4px;"
                          [src]="playerInfo.isPlaying == 'play' ? '../../assets/img/track-pause-button-gold.png' : '../../assets/img/track-play-button-gold.png'" /></button>
                      <button (click)="nextTrack()"><img class="height36" src="../../assets/img/skip-track-gold.png"
                          alt="pause" /></button>

                    </div>
                  </div>
                  <div class="progressbar_wrapper">
                    <div class="progressbar" #progressbar (click)="setPosition($event)">
                      <div class="inner"
                        [ngStyle]="{ 'width': calculateWidth(), 'background-color': calculateColor() }"></div>
                    </div>
                    <div class="time">
                      <div class="track_time"><span class="remaining_time">{{convertTime(duration-playingSec)}}</span>
                      </div>
                      <div class="track_time"><span class="duration">{{convertTime(duration)}}</span></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


</div>

<ng-template #elseBlock>
  <div style="display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;">
  This link is no longer available
</div>
</ng-template>



