import { Component, OnInit,ElementRef,HostListener,ViewEncapsulation,ViewChild} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PlayerComponentComponent } from "../player-component/player-component.component";
import { McSamplegenerateService } from '../services/mc_samplegenerate.service';
import {Howl} from 'howler';
import {interval, Subscription} from 'rxjs';

@Component({
  selector: 'app-mc-samplesgenerate-client',
  templateUrl: './mc-samplesgenerate-client.component.html',
  styleUrls: ['./mc-samplesgenerate-client.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  providers: [McSamplegenerateService]
})
export class McSamplesgenerateClientComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private mcsampleservice: McSamplegenerateService,
    private elementRef: ElementRef,

  ) {
    this.windowHeight = window.innerHeight;
  }

  @ViewChild('progressbar', { static: true }) progressbarElement: ElementRef;
  windowHeight: number;
  musicSubHeading: HTMLElement;
  dynamicContent: HTMLElement;
  musicPlayList: HTMLElement;

  token: string;
  buttonTitles: { play: string; pause: string } = { play: 'Play', pause: 'Pause' }; // Titles for play and pause buttons
  /**
   Player information
   {
     isPlaying(string) - player status. List of possible values ['stop', 'play','pause'](default 'stop'),
     trackIndex(integer) - index of playling track in playlist(default null),
     trackId(integer) - playling track id(default null),
     filename(string) - file name of playing track(default null),
     artist(string) - playling track artist(default ''),
     album(string) - playling track album(default ''),
     storage(string) - storage of playling track(default ''),
     token(string) - access token for logged user(default '')
   }
   **/
  playerInfo = {
    isPlaying: "stop",
    trackIndex: null,
    trackId: null,
    filename: null,
    album: "",
    artist: "",
    storage: "",
    token: "",
  };

  playertrackDetails={
    id: '',
    trackName:'',
    artistName:'',
    isplaying:false,
  };
  trackDetails:any;


  PlaylistDetails: any = [];
  playlists: any;
  players: Howl;//Player component
  playingSec = 0;//Track playing in seconds
  duration = 0;//Track duration
  playerObj={
    artist: 'Artist',
    album: 'Album',
    wave: null,
    storage: ''
  };//Player information object
  isSpace = false;//Is key 'Space' pressed
  muted = false;
  isPause = false;//Track pausing
  private updateObservable: any;
  private updateSubscriber: Subscription;
  innerWidth = 0;//Width of wave
  public value: number;//Track current time
  public maxValue: number;//Track duration
  public isPlaying = false;
  trackDublicate = null;//Dublicate track id
  selectedPlaylistid=null;
  isDropdownOpenchannel = false;
  isDropdownOpenplaylist = false;
  selectedChannelName: string | null = null;
  selectedPlaylistName: string | null = null;



  ngOnInit(): void {
    this.setupUpdateObservable();
    this.musicSubHeading = document.querySelector('.music_main_header') as HTMLElement;
    this.dynamicContent = document.querySelector('.dynamic-content') as HTMLElement;
    this.musicPlayList = document.querySelector('.musicplay_list_namesec ul') as HTMLElement;


    this.setDynamicContentPaddingBottom();
    this.route.params.subscribe(params => {
      this.token = params['token'];
      if (this.token) {
        this.mcsampleservice.getdatabyToken(this.token).subscribe((data: any) => {
          this.PlaylistDetails = data;
          this.playlists = data.channelData;
          if (this.PlaylistDetails.files && this.PlaylistDetails.files.length > 0) {
            this.playertrackDetails.id = this.PlaylistDetails.files[0].musicfiles.id;
            this.playertrackDetails.trackName = this.PlaylistDetails.files[0].musicfiles.title;
            this.playertrackDetails.artistName = this.PlaylistDetails.files[0].musicfiles.artist.name;


          }
          this.updateSelectedChannelName();
          this.updateSelectedPlaylistName();
        });

      }
    });
  }


    @HostListener('window:resize', ['$event'])
    onResize(event: Event) {
      this.windowHeight = window.innerHeight;
      this.setDynamicContentPaddingBottom();
    }

  private setupUpdateObservable() {
    this.value = 0;
    this.maxValue = 0;
    this.updateObservable = interval(500);
  }

  getPlaylistByChannelID(channelId: any): void {
    this.mcsampleservice.getPlaylistBYChannel(channelId).subscribe((data: any) => {
      this.playlists = data;
      if (this.playlists && this.playlists.length > 0) {
        this.PlaylistDetails.id = this.playlists[0].id;
        this.selectedPlaylistName = this.playlists[0].name;
        this.getPlaylistTracksByid(this.playlists[0].id);
      } else {
        this.PlaylistDetails.id = '';
      }
    });
  }

  getPlaylistTracksByid(playlistId: string): void {
    this.selectedPlaylistid = playlistId;
    this.mcsampleservice.gettracksbyPlaylistID(this.selectedPlaylistid).subscribe((playliststracks: any) => {
      if (playliststracks) {
        this.PlaylistDetails = playliststracks;
      } else {
        this.PlaylistDetails = [];
      }
    });
  }



  playTrack(e, file, file_name, index, album, artist, storage) {
    e.preventDefault();

    if (
      this.playerInfo.isPlaying == "play" &&
      this.playerInfo.trackId == file.id &&
      this.playerInfo.trackIndex == index
    ) {
        // If the track is already playing, pause it
        this.players.pause();
        this.playerInfo.isPlaying = "pause"; // Update the state to "pause"
        this.playertrackDetails.isplaying = false;
    } else {
        // If a different track is clicked or the track is not playing, start it
        this.playerInfo.filename = file_name;
        this.playerInfo.trackId = file.id;
        this.playerInfo.trackIndex = index;
        this.playerInfo.album = album;
        this.playerInfo.artist = artist;
        this.playerInfo.storage = storage;
        this.playerInfo.token = '';
        this.playertrackDetails.id = file.id;
        this.playertrackDetails.trackName = file.title;
        this.playertrackDetails.artistName = artist;
        this.trackDetails = file;

        // Reset previous player state before starting the new track
        if (this.players) {
            this.players.stop();
        }

        this.playerInfo.isPlaying = "play"; // Set the state to "play"
        this.start(this.playerInfo.trackId, true);
    }
}


  public start( tid, start ){
      if (tid == this.playerInfo.trackId && !start) {
        this.players.pause();
        this.playerInfo.isPlaying = "pause";
        this.playertrackDetails.isplaying = false;
    } else {
        this.isPlaying = false;
        this.checkPlaying('stop');

        setTimeout(() => this.startPlay(), 500);
    }
  }

  startPlay(){
    if(this.playerInfo.trackId == null && this.PlaylistDetails.files){
      this.playerInfo.trackId=this.PlaylistDetails.files[0].musicfiles.id
    }
    var decodedStr=btoa('id='+this.playerInfo.trackId);
    if(this.playerInfo.trackId != null){
      if(this.isPlaying){
        this.isPlaying = false;
        this.checkPlaying("pause");
        this.players.pause();

        if(this.isSpace&&this.trackDublicate != this.playerInfo.trackId){
          this.startPlay();
        }
      }
      else{
        if(this.trackDublicate != this.playerInfo.trackId){
          this.trackDublicate = this.playerInfo.trackId;
          if(this.players != undefined){
            this.players.stop();
            if (this.players.state() != 'unloaded') this.players.unload()
          }
          this.players = new Howl({
            src: ['/tracks/stream-client-end/' + decodedStr],
            html5: true
          });


          this.players.on('play', () => this.onPlay());
          this.players.on('pause', () => this.onPause());
          this.players.on('stop', () => this.onStop());
          this.players.on('end', () => this.onEnd());
          this.playerObj = {
            artist: this.playerInfo.artist,
            album: this.playerInfo.album,
            wave: this.playerInfo.trackId,
            storage: this.playerInfo.storage
          };
          this.isSpace = false;
        }

        this.players.play();

      }
    }
    return false;
  }

  onPlay(){
    this.isPause = false;
    this.duration = this.players.duration();
    this.subscribe();
    this.isPlaying = true;
    this.checkPlaying("play");

  }
  checkPlaying(e) {
    this.playerInfo.isPlaying = e;
  }
    /**
   Pause track
   */
   onPause(){

    this.isPause = true;
    this.unsubscribe();
  }

  /**
   Stop track
   */
  onStop(){

    this.isPause = false;
    this.innerWidth = 0;
    this.playingSec = 0;
    this.isPlaying = false;
    this.checkPlaying("stop");
    this.unsubscribe();
  }

  /**
   End of track
   */
  onEnd(){
    this.isPause = false;
    this.innerWidth = 0;
    this.playingSec = 0;
    this.unsubscribe();
    this.nextTrack();
  }



    /**
   Update track and wave information
   */
   private subscribe() {
    this.updateSubscriber = this.updateObservable.subscribe(() => {
      this.value = this.getCurrentDuration();
      this.maxValue = this.getMaxDuration();
      var percent = this.value/this.maxValue;
      this.innerWidth = 350 * percent;
      this.playingSec = this.players.seek();
    });
  }
  private getCurrentDuration(): number {
    return Math.round(<number>this.players.seek());
  }
  private getMaxDuration(): number {
    return Math.round(<number>this.players.duration());
  }

  nextTrack() {
    if (this.PlaylistDetails && this.PlaylistDetails.files && this.PlaylistDetails.files.length > 0) {
      const currentIndex = this.playerInfo.trackIndex;
      const nextIndex = (currentIndex + 1) % this.PlaylistDetails.files.length;
      const nextTrack = this.PlaylistDetails.files[nextIndex].musicfiles;
      this.playerInfo.filename = nextTrack.file_name;
      this.playerInfo.trackId = nextTrack.id;
      this.playerInfo.trackIndex = nextIndex;
      this.playerInfo.album = nextTrack.album.name;
      this.playerInfo.artist = nextTrack.artist.name;
      this.playerInfo.storage = nextTrack.storage;
      this.playerInfo.token = '';
      this.playertrackDetails.id = nextTrack.id;
      this.playertrackDetails.trackName = nextTrack.title;
      this.playertrackDetails.artistName=nextTrack.artist.name;
      this.trackDetails = nextTrack;

      // Start playing the next track
      this.start(this.playerInfo.trackId, true);
      console.log(this.playerInfo);
    }

  }

    /**
   Stop timer
   */
   private unsubscribe() {
    this.updateSubscriber.unsubscribe();
  }

  setPosition(e){
    if (this.players != undefined) {
      const progressbarOffsetLeft = this.progressbarElement.nativeElement.offsetLeft;
      this.innerWidth = e.layerX - progressbarOffsetLeft;
      this.players.seek((this.innerWidth / 350) * this.maxValue);
    }
  }

  calculateWidth(): string {
    if (this.maxValue > 0) {
      const widthPercentage = (this.value / this.maxValue) * 100;
      return `${widthPercentage}%`;
    } else {
      return '0%'; // Default to 0% width if maxValue is not yet initialized or is 0
    }
  }

  calculateColor(): string {
    if (this.maxValue > 0) {
      const progressPercentage = this.value / this.maxValue;
      return progressPercentage >= 0.001 ? 'grey' : '#fff';
    } else {
      return '#fff'; // Default to white if maxValue is not yet initialized or is 0
    }
  }

  convertTime(time){
    var str = '';
    if(time != 'NaN'){
      var t = new Date(time * 1000);
      // var h = t.getUTCHours();
      var m = t.getUTCMinutes();
      var s = t.getUTCSeconds()

      // if(h < 10){
      //   str = '0' + h.toString();
      // }
      // else{
      //   str = h.toString();
      // }
      if(m < 10){
        str += '0' + m.toString();
      }
      else{
        str += m.toString();
      }
      if(s < 10){
        str += ':0' + s.toString();
      }
      else{
        str += ':' + s.toString();
      }
    }
    else{
      str = '00:00';
    }
    return str;
  }



  setDynamicContentPaddingBottom() {
    if (!this.musicSubHeading || !this.musicPlayList) {
      return;
    }

    const musicSubHeadingHeight = this.musicSubHeading.clientHeight;
    const windowWidth = window.innerWidth;

    if (this.windowHeight <= 730) {
      if (windowWidth < 600) {
        this.musicPlayList.style.height = '35vh';
      } else {
        this.musicPlayList.style.height = '30vh';
      }

      if (musicSubHeadingHeight > 150) {
        if (windowWidth < 600) {
          this.musicPlayList.style.height = '33vh';
        } else {
          this.musicPlayList.style.height = '28vh';
        }
      }

    } else if (this.windowHeight > 730 && this.windowHeight <= 945) {
      if (windowWidth < 600) {
        this.musicPlayList.style.height = '40vh';
      } else {
        this.musicPlayList.style.height = '43vh';

      }

      if (musicSubHeadingHeight > 185) {
        if (windowWidth < 600) {
          this.musicPlayList.style.height = '38vh';
        } else {
          this.musicPlayList.style.height = '40vh';
        }
      }

    } else if (this.windowHeight > 945 && this.windowHeight < 993) {
      if (windowWidth < 600) {
        this.musicPlayList.style.height = '44vh';
      } else {
        this.musicPlayList.style.height = '45vh';
      }

      if (musicSubHeadingHeight > 200) {
        if (windowWidth < 600) {
          this.musicPlayList.style.height = '42vh';
        } else {
          this.musicPlayList.style.height = '41vh';
        }
      }

    } else if (this.windowHeight >= 993) {
      if (windowWidth < 600) {
        this.musicPlayList.style.height = '50vh';
      } else {
        this.musicPlayList.style.height = '45vh';
      }

      if (musicSubHeadingHeight > 200) {
        if (windowWidth < 600) {
          this.musicPlayList.style.height = '46vh';
        } else {
          this.musicPlayList.style.height = '42vh';
        }
      }

    } else {
      this.musicPlayList.style.height = ''; // Reset to default height

    }
  }



   toggleDropdownplaylist() {
    this.isDropdownOpenplaylist = !this.isDropdownOpenplaylist;
    this.isDropdownOpenchannel=false;
  }
  toggleDropdownchannel() {
    this.isDropdownOpenchannel = !this.isDropdownOpenchannel;
    this.isDropdownOpenplaylist=false;
  }
  selectChannel(channel: { id: number, name: string }) {
    this.PlaylistDetails.sample_channel_id = channel.id;
    this.updateSelectedChannelName();
    this.isDropdownOpenchannel = false; // Optionally close the dropdown
    this.getPlaylistByChannelID(channel.id);
  }

  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedId = +selectElement.value;
    const selectedChannel = this.PlaylistDetails.channels.find(channel => channel.id === selectedId);
    if (selectedChannel) {
      this.selectChannel(selectedChannel);
    }
  }

  updateSelectedChannelName() {
    const selectedChannel = this.PlaylistDetails.channels.find(channel => channel.id === this.PlaylistDetails.sample_channel_id);
    this.selectedChannelName = selectedChannel ? selectedChannel.name : null;
  }

  updateSelectedPlaylistName() {
    const selectedPlaylist = this.playlists.find(list => list.id === this.PlaylistDetails.id);
    this.selectedPlaylistName = selectedPlaylist ? selectedPlaylist.name : null;
  }
  onSelectChangeplaylist(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedId = +selectElement.value;
    const selectedPlaylist = this.playlists.find(list => list.id === selectedId);
    if (selectedPlaylist) {
      this.selectPlaylist(selectedPlaylist);
    }
  }
  selectPlaylist(playlist: { id: number, name: string }) {
    console.log(playlist);
    this.selectedPlaylistid =playlist.id
    this.selectedPlaylistName = playlist.name;
    this.isDropdownOpenplaylist = false;

    this.getPlaylistTracksByid(this.selectedPlaylistid);
  }


}
